import React from "react";

export default function CourseFeatures4Weeks({ coursefeatures }) {
  return (
    <div className="mt-4 mb-4 bg-light border p-2 rounded" id="coursefeatures">
      <h3 className="text-warning"><i className="fa fa-diamond" /> Course Features:</h3>
      <div className="row d-flex justify-content-between p-0 m-0">
        <div className="col-md-4 col-xs-12 border rounded bg-dark shadow text-white p-2 m-lg-3 mt-3">
          <h4 className="text-center text-info">
            <i className="fa fa-desktop" /> Lecture Videos
          </h4>
          <div>
            <p className="text-center">{coursefeatures.videos.count} Videos of {coursefeatures.videos.duration} hours content</p>
          </div>
        </div>
        <div className="col-md-3 col-xs-12 border rounded bg-dark shadow text-white p-2 m-lg-3 mt-3">
          <h4 className="text-center text-info">
            <i className="fa fa-book" /> Quiz Assessments
          </h4>
          <div>
            <p className="text-center">{coursefeatures.quizzes} Quizzes are available</p>
          </div>
        </div>
        <div className="col-md-4 col-xs-12 border rounded bg-dark shadow text-white p-2 m-lg-3 mt-3">
          <h4 className="text-center text-info">
            <i className="fa fa-tasks" /> Tasks
          </h4>
          <div>
            <p className="text-center">{coursefeatures.tasks} Practical tasks are available</p>
          </div>
        </div>
      </div>

      <div className="row d-flex justify-content-between p-0 m-0">
        <div className="col-md-4 col-xs-12 border rounded bg-dark shadow text-white p-2 m-lg-3 mt-3">
          <h4 className="text-center text-info">
            <i className="fa fa-wifi" /> Lifetime Access
          </h4>
          <div>
            <p className="text-center">
              You will get lifetime access to presentations, quizzes, Lecture Videos, project code and practical tasks.
            </p>
          </div>
        </div>
        <div className="col-md-3 col-xs-12 border rounded bg-dark shadow text-white p-2 m-lg-3 mt-3">
          <h4 className="text-center text-info">
            <i className="fa fa-certificate" /> Certification
          </h4>
          <div>
            <p className="text-center">
              VM Online Training will certify you as a {coursefeatures.certificateRole} after successful completion of the course.
            </p>
          </div>
        </div>
        <div className="col-md-4 col-xs-12 border rounded bg-dark shadow text-white p-2 m-lg-3 mt-3">
          <h4 className="text-center text-info">
            <i className="fa fa-comments-o" /> Community
          </h4>
          <div>
            <p className="text-center">
              If you have any doubts while learning you can join in our official whatsapp group and can clear your
              doubts.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
