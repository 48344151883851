import React, { useState } from "react";
import Festival from "../../common/Festival";
import Footer from "../../common/Footer";
import CoursesList from "./CoursesList";
import moment from "moment";

export default function Courses() {
  let [monthEnd] = useState(moment(new Date()).format("MMMM"))
  let [monthEndDate] = useState(moment(new Date()).endOf('month').format("Do"))
  let offer = () => (
    <p
      className="text-capitalize font-weight-bold text-primary"
      style={{ fontFamily: "Century Gothic", fontWeight: "bold" }}>
      <i className="fa fa-check-circle text-warning" />
      <b className="text-warning"> 25% Discount Offer</b> For Any Course <b className="text-warning">(HTML/JavaScript/CSS/REACTJS/NODEJS/REACT NATIVE/ANGULAR)</b> Till <b className="animcolor2">{monthEnd} {monthEndDate.slice(0, 2)}<sup className="text-lowercase">{monthEndDate.slice(2, 4)}</sup></b>
      {/* <b className="text-warning"> Minimum <b className="text-info">30% Discount</b> On <b className="text-info">Any Combo Course</b> Till</b> <b className="text-info">31st July 2024.</b> */}
      {/*<i className="fa fa-check" />*/}
      {/*<b className="text-warning"> 75% Discount</b> For Any Course Till 16th August 2022 11:59 PM.*/}
    </p >
  )
  return (
    <div className="container-fluid text-center custom-bg2">
      <Festival />
      <CoursesList title="Full Courses Discount" offer={offer()} />
      <Footer />
    </div>
  );
}
