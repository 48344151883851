import moment from 'moment'
import React, { useState, useRef } from 'react';
import { AuthUserId } from '../../../../utilities';
import SubReplyComponent from './SubReplyComponent'

export default function ReplyComponent({ comment, replyMessage, addReply, reply, setReply, subReply, setSubReply, addLike, addLikeReply, deleteComment, deleteCommentReply }) {
  const [showReply, setShowReply] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const subReplyRef = useRef();
  const show = () => {
    setShowReply(!showReply);
    //setTimeout(() => {
    //  if (showReply === true) {
    //    subReplyRef.current.focus();
    //  }
    //}, 1000);
  }
  return (
    <div>
      <button className='btn btn-outline-primary me-2' onClick={() => addLike(comment._id, "likes")}><i className='fa fa-thumbs-up' /> {comment.likes}</button>
      {/*<button className='btn btn-outline-primary me-2' onClick={() => addLike(comment._id, "dislikes")}><i className='fa fa-thumbs-down' /> {comment.dislikes}</button>*/}
      <button className='btn btn-outline-dark me-2' onClick={show}><i className='fa fa-reply' /> Reply</button>
      {comment.userId === AuthUserId && <button className='btn btn-outline-danger me-2' onClick={() => deleteComment(comment._id)}><i className='fa fa-trash' /> Delete</button>}

      {/* Reply Editor */}
      {showReply && <div className='mt-2 mb-2'>
        {/* custom text editor */}
        <form onSubmit={(e) => addReply(e, comment._id, reply)}>
          <input type="text" placeholder='Add a reply...' className='form-control' value={reply} onChange={(e) => setReply(e.target.value)} ref={subReplyRef} />
          {replyMessage && <div className='alert alert-success'>{replyMessage}</div>}
        </form>
      </div>}
      {/* Reply */}
      {comment.replies.length > 0 && <div>
        <p className='cursor-pointer font-weight-bold' onClick={() => setShowReplies(!showReplies)}>{showReplies ? <i className='fa fa-angle-double-up' /> : <i className='fa fa-angle-double-down font-weight-bold' />}  {comment.replies.length} Replies </p>
      </div>}

      <div className='mt-2 mb-2'>
        {showReplies && comment.replies.length > 0 && comment.replies.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1).map((singlereply, index2) => (
          <div className='d-flex' key={index2}>
            <img src={"https://vmtraining.netlify.app/logo192.png"} alt="instructor" className='instructor2 me-2 ' />
            <div className='w-100'>
              <div className='d-flex align-items-center'>
                <span>{singlereply.name} </span>
                <small className='m-2' title={moment(singlereply.updatedAt).format("DD/MM/YYYY hh:mm:ss A")}>{singlereply.createdAt <= singlereply.updatedAt ? moment(singlereply.createdAt).fromNow() : moment(singlereply.updatedAt).fromNow()}</small>
                {singlereply.createdAt < singlereply.updatedAt ? <small>(edited)</small> : null}
              </div>
              <p>{singlereply.comment}</p>
              <SubReplyComponent
                singlereply={singlereply}
                comment={comment}
                addReply={addReply}
                subReply={subReply}
                setSubReply={setSubReply}
                addLike={addLikeReply}
                deleteComment={deleteComment}
                deleteCommentReply={deleteCommentReply}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
