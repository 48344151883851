import React from 'react'
import { GoodMorningWish } from './VMVideoPlayer/Courses/CourseWish'

const linkStyle = {
  fontSize: 25,
  borderBottomLeftRadius: 100,
  borderBottomRightRadius: 100
};

const InstallApp = React.memo(() => {
  return (
    <a href='https://play.google.com/store/apps/details?id=com.vmtrainingapp'
    target="_blank"
    rel="noreferrer"
    style={linkStyle}
    className="text-decoration-none text-center custom-bg text-white align-items-center d-flex justify-content-center flex-wrap p-3 shadowAnim">
    <span className='text-uppercase'>
       <i className='fa fa-mobile' />
       <GoodMorningWish /> Install VM Online Training Android App 🧑‍💻
    </span>
 </a>
  );
});

export default InstallApp;
