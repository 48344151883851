import React, { useState } from "react";
import { Redirect } from "react-router";
import { useParams } from "react-router-dom";
import PleaseWait from "../../common/PleaseWait";
import { api, baseURL, Validator } from "../../utilities";
export default function ResetPassword() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const { token } = useParams();
  const [, forceUpdate] = useState(false);
  const validator = Validator();
  const submitLogin = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setLoading(true);
      let user = api(baseURL + "/resetPassword", "PUT", { "content-type": "application/json" }, { password, token });
      user
        .then((data) => {
          if (data.success) {
            setMessage("Your password changed successfully!");
            setLoggedIn(true);
            setLoading(false);
          } else {
            setMessage(data.message);
            setLoading(false);
            setLoggedIn(false);
          }
        })
        .catch((error) => {
          setMessage("Something went wrong!");
          setLoading(false);
        });
    } else {
      validator.current.showMessages();
      forceUpdate(true);
    }
  };
  return (
    <div className="container-fluid text-center custom-bg2 vh-100">
      <div className="row justify-content-center align-items-center m-0 p-0 pt-4">
        <div className="col-md-5 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
          <form onSubmit={submitLogin}>
            <i className="fa fa-lock" style={{ fontSize: 200, color: "orange" }} />
            <h1 className="mb-4">Reset Password</h1>
            <input
              type="password"
              placeholder="New Password"
              className="form-control"
              value={password}
              maxLength={16}
              minLength={8}
              //   required
              onChange={(e) => setPassword(e.target.value)}
            />
            {validator.current.message("Password ", password, "required|password")}
            <br />
            <br />
            <input
              type="password"
              placeholder="Confirm password"
              className="form-control"
              value={confirmpassword}
              maxLength={16}
              minLength={8}
              //   required
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {validator.current.message("Confirm Password ", confirmpassword, "required|password")}
            {password.length > 0 && confirmpassword.length > 0 && password !== confirmpassword ? (
              <span className="error-message">Passwords are not matching</span>
            ) : (
              ""
            )}
            {<span>{message}</span>}
            <br />
            {!loading && <input type="submit" value="Reset" className="btn btn-primary w-50" />}
            {loading && <PleaseWait content={"Please wait..."} />}
            <br />
            <br />
          </form>
        </div>
      </div>
      {loggedIn && <Redirect to="/login" />}
    </div>
  );
}
