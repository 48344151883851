import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { allCoursesStored, LoadingIndicator } from '../../utilities';
import CoursesListMore from '../courses/CoursesListMore';
import InstallApp from '../InstallApp';
import { addToStorage } from '../../utilities/localStorage';
import { cartItems } from '../../redux/constants/courseConstants';

export default function CartItems() {
  const history = useHistory();
  const [allCourses, setAllCourses] = useState(allCoursesStored ? allCoursesStored : []);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  var totalAmount = 0;
  let originalTotal = 0;
  let totalDiscount = 0;
  let selectedCoursesList = localStorage.getItem("cartItems") != null ? JSON.parse(localStorage.getItem("cartItems")) : [];
  const [selectedCourses, setCourses] = useState(selectedCoursesList)

  function handleDeleteItem(_id) {
    //setLoading(true);
    let removeItems = selectedCourses.filter(item => item._id !== _id);
    addToStorage(cartItems,JSON.stringify(removeItems));
    setCourses(removeItems);
    alert("Course Removed Successfully!");
    //setTimeout(() => {
    //  setLoading(false);
    //}, 1000);
  }

  const proceedToCheckout = () => {
    history.push("/purchase/cart", { selectedCourses });
  }

  const fetchCourses = () => {
    //setLoading(true);
    //api(baseURL + "/getAllCoursesPublic/", "GET", {}, {})
    //  .then((data) => {
    //    if (data.success) {
    //      setLoading(false);
    //      setAllCourses(data.data)
    //let updatedResult = data.data.filter(course => course.courseType !== "inactive").map(course => {
    //  let result = allCoursesStored.filter(item => item.id === course._id);
    //  return { ...course, title: course.name, image1: course.courseLogo, image2: result.length > 0 ? result[0].image2 : "", isNew: course.isNewCourse, courselink: result.length > 0 ? result[0].courselink : "", demolink: course.demo }
    //})
    let premium = allCourses.filter(course => course.courseType === "Premium");
    let existingIds = localStorage.getItem("cartItems") != null ? JSON.parse(localStorage.getItem("cartItems")) : [];
    if (existingIds !== null) {
      existingIds = existingIds.map(course => course._id);
      let resultt = [];
      premium.forEach(course => {
        if (existingIds.filter(item => course.courseCombo.includes(item)).length === 0 && existingIds.includes(course._id)) {
          resultt.push(course)
        }
      })
      setCourses(resultt);
    }
    setError("")
    //} else {
    //  setError("")
    //  setLoading(false);
    //}
    //}).catch(error => {
    //  setError(error.toString())
    //})
  }
  useEffect(() => {
    fetchCourses();
  }, [])

  return (
    <>
      <InstallApp />

      <div className="container padding-bottom-3x mb-1 mt-4">
        <div className='text-center pb-2'>
          <a href='https://play.google.com/store/apps/details?id=com.vmtrainingapp' target="_blank">
            <button className="btn btn-primary">GET 50% DISCOUNT IN APP FOR PURCHASING ALL COURSES AT ONCE!</button>
          </a>
        </div>
        {selectedCourses.length > 0 && <div className='d-flex justify-content-between align-items-center'>
          <h1 className='text-white mt-4'><i className='fa fa-shopping-cart' /> Your Cart</h1>
          <h1 className='text-white mt-4'>({selectedCourses.length} {selectedCourses.length > 1 ? "Courses" : "Course"})</h1>
        </div>}
        {error.length > 0 ? <h3 className="text-danger text-center">{error}</h3> : null}
        {loading ? <div className="vh-100 text-center"><LoadingIndicator size={"5"} /></div> :
          <div className="card">
            {selectedCourses.length === 0 && <h1 className='text-center text-warning'><i className='fa fa-shopping-cart' /><br /> Your Cart Is Empty!<br />
              <Link to="/allcourses" className='btn btn-primary'>View All Courses</Link></h1>}
            {selectedCourses.length > 0 && <table className="table table-hover">
              <thead>
                <tr>
                  <th className="col-3">Course</th>
                  {/*<th className="col-2">Original Price</th>*/}
                  <th className="col-2">Price</th>
                </tr>
              </thead>
              <tbody>
                {selectedCourses.map((item, index) => {
                  let finalDiscount = 0;
                  if (selectedCourses.length >= 8) {
                    finalDiscount = 35;
                  } else if (selectedCourses.length > 1 && selectedCourses.reduce((prev, current) => Number(prev) + Number(current.price), 0)) {
                    finalDiscount = 30;
                  } else {
                    finalDiscount = Number(item.discount);
                  }
                  let finalval = parseInt(item.price) - ((parseInt(item.price) / 100) * parseInt(finalDiscount));
                  totalAmount += Number(finalval);
                  originalTotal += parseInt(item.price);
                  totalDiscount += parseInt(item.discount);
                  return (
                    <tr key={index}>
                      <th>{index + 1}) {item.title}</th>
                      {/*<td>{(selectedCourses.length === 13) && (item.title === "JS and MERN Course" || item.title === "JS and React Course" || item.title === "JS and Node Course" || item.title === "MERN Stack Course" || item.title === "Web Development Full Course") ? <del>Rs.{item.price}/-</del> : <span>Rs.{item.price}/-</span>}</td>*/}
                      <td className='d-flex justify-content-between align-items-center'>
                        <span>Rs.{finalval.toLocaleString('en-IN')}/-</span>
                        <button className="btn btn-danger" aria-label="Delete" onClick={() => handleDeleteItem(item._id)}>
                          <i className="fa fa-trash-o" aria-hidden="true"></i>
                        </button></td>
                    </tr>)

                })}

              </tbody>
              <tfoot>
                <tr>
                  <th className='text-success'>{100 - (Number(totalAmount) / selectedCourses.reduce((prev, current) => Number(prev) + Number(current.price), 0)) * 100}% Discount Applied (You can save Rs.{(selectedCourses.reduce((prev, current) => Number(prev) + Number(current.price), 0) - Number(totalAmount)).toLocaleString('en-IN')}/-)</th>
                  <th><b className='text-success h4'> Rs.{totalAmount.toLocaleString('en-IN')}/- <sub className='text-danger'>(<del>Rs.{originalTotal.toLocaleString('en-IN')}/-</del>)</sub> </b></th>
                </tr>
              </tfoot>
              {/*<tfoot>
              <tr>
                <th></th>
                <th>{selectedCourses.length === 13 ? <b>Rs.18,200/-</b> : <b>Rs.{originalTotal}/-</b>}</th>
                <th>{selectedCourses.length === 13 ? <b className='text-success'>Final Price = Rs.12,740/- (30%)</b> : <b className='text-success h4'>Final Price = Rs.{totalAmount}/- </b>}</th>
              </tr>
            </tfoot>*/}
            </table>}
            {selectedCourses.length > 0 && <button className='btn btn-primary' onClick={() => proceedToCheckout()}>Proceed To Buy (Rs.{totalAmount.toLocaleString('en-IN')})</button>}
          </div>}
      </div>
      {!loading && selectedCourses.length < 8 && selectedCourses.length < allCourses.length && <div className='bg-dark mt-2 mb-2 rounded text-center'>
        {selectedCourses.length > 0 ? <h3 className='text-center text-white bg-dark container pb-2 pt-2'>+ Add More Courses To Get More Discount</h3> :
          <h1 className="mb-4 text-white">
            <b className="text-warning">VM</b> Training Full Courses
          </h1>}
        <CoursesListMore selectedCourses={selectedCourses} onRefreshCart={() => fetchCourses()} />
      </div>}
    </>
  );
}