import React, { useState, useEffect } from "react";
import InstallApp from "../components/InstallApp";
import Footer from "./Footer";

export default function ContactUS() {
  const [show, setShow] = useState(false);
  useEffect(
    () => {
      let mobile = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase());
      if (mobile) {
        setShow(true);
      } else {
        setShow(false);
      }
    },
    [show],
  );
  return (
    <div className="container-fluid text-center custom-bg2 vh-100">
      <InstallApp />
      <h1 className="mb-4 pt-4 text-white">
        <i className="fa fa-whatsapp text-success" /> Contact Us
      </h1>
      <div className="row justify-content-center align-items-center m-0 p-0">
        <div className="col-md-3 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
          <img src="images/myprofile.jpg" className="w-50 tech-team" style={{ borderRadius: 100 }} />
          <br /><br />
          <h6><b>Phone:</b> +91 8328664336</h6>
          <h6><b>Email:</b> mogilivenkatesh3@gmail.com</h6>
          <h6><b>Address:</b> 1-121, Chandrampalem, Uppragudem, East Godavari District, Andhra Pradesh - 533450.</h6>
          {show ? (
            <a
              className="nav-link text-success"
              href="https://api.whatsapp.com/send?phone=+918328664336&amp;text=Hello Venkatesh! I'm interested to enroll/purchase your courses. I have few queries."
              target="_blank">
              <i className="fa fa-whatsapp text-success" /> Send Message
            </a>
          ) : (
            <a
              className="nav-link text-success"
              href="https://web.whatsapp.com/send?phone=+918328664336&amp;text=Hello Venkatesh! I'm interested to enroll/purchase your courses. I have few queries."
              target="_blank">
              <i className="fa fa-send text-success" /> Send Message
            </a>
          )}
          <br />
          (No Calls, Only WhatsApp)
        </div>
      </div>

<Footer/>
    </div>
  );
}
