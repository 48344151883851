import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import Footer from "../../common/Footer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link,useHistory } from "react-router-dom";
import { allAvailableCourses, filteredDate } from "../../utilities/constants";
import OtherCourses from "./OtherCourses";
import InstallApp from "../InstallApp";
import { openPlayer } from "../VMVideoPlayer/useCourses";
const allcourses = [
  { ...allAvailableCourses.mernstack },
  { ...allAvailableCourses.reactredux },
  { ...allAvailableCourses.nodejs },
]
export default function Projects() {
  const [show, setShow] = useState(false);
  const whatsappLinkRef = useRef();
  const whatsappLinkRef2 = useRef();
  const history = useHistory();
  const [remainingTime, setRemaining] = useState("");
  const [days, setDays] = useState("");
  const [amount, setAmount] = useState("750");
  const [original, setOriginal] = useState("1500");
  useEffect(
    () => {
      window.scrollTo(0, 0);
      let mobile = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase());
      if (mobile) {
        setShow(true);
      } else {
        setShow(false);
      }
    },
    [show],
  );
  useEffect(
    () => {
      var countDownDate = new Date(filteredDate).getTime();
      // Update the count down every 1 second
      var x = setInterval(() => {
        // Get todays date and time
        var now = new Date().getTime();

        // Find the distance between now an the count down date
        var distance = countDownDate - now;
        if (moment(countDownDate).diff(now, "hours") >= 24) {
          let days = moment(countDownDate).diff(now, "days");
          setDays(days);
        }
        // Time calculations for days, hours, minutes and seconds
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        let hh = hours < 10 ? "0" + hours + "H " : hours + "H ";
        let mm = minutes < 10 ? "0" + minutes + "M " : minutes + "M ";
        let ss = seconds < 10 ? "0" + seconds + "S " : seconds + "S ";
        let remaining = hh + " " + mm + " " + ss;
        if (distance < 0) {
          clearInterval(x);
          remaining = "EXPIRED";
        }
        setRemaining(remaining);
      }, 1000);
    },
    [remainingTime],
  );
  return (
    <div className="custom-bg2">
      {remainingTime !== "EXPIRED" && (
        <p
          style={{ fontSize: 25 }}
          className="text-center custom-bg text-white align-items-center d-flex justify-content-center flex-wrap">
          COURSE PRICE: &nbsp;<b style={{ color: "rgb(231 241 51)" }}>Rs.{parseInt(amount).toLocaleString('en-IN')}/- </b>{" "}
          <sub>
            <del>Rs.{parseInt(original).toLocaleString('en-IN')}/-</del>
          </sub>{" "}
          &nbsp;(50% DISCOUNT ENDS IN &nbsp;
          <b style={{ fontSize: 25, color: "rgb(231 241 51)" }}>
            {days ? days + (days > 1 ? " Days" : " Day") + ", " : ""}
            {remainingTime})
          </b>
        </p>
      )}
      {remainingTime === "EXPIRED" && (
        <p
          style={{ fontSize: 25 }}
          className="text-center bg-secondary text-white align-items-center d-flex justify-content-center flex-wrap">
          REGISTRATIONS ARE CLOSED. PLEASE CONTACT ADMIN FOR UPCOMING BATCH/COURSE DETAILS.
        </p>
      )}
      <InstallApp />
      <div className="container mt-4">
        <h1 className="text-center text-white rounded">
          FULL <b className="text-warning">STACK</b> PROJECTS
        </h1>
        <div className="row justify-content-between m-0 p-0 bg-light border rounded">
          <div
            className="col-md-6 col-xs-12 rounded bg-warning text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <img
              src="/images/react/mernstack-application.png"
              alt="mern stack application"
              style={{ maxWidth: "100%" }}
            />
            <br />
            <br />
            <br />
            <div className="mt-2 pt-2">
              <Link
                to="/purchase/MERN Stack Project"
                style={{ cursor: "pointer" }}
                className="nav-link text-center font-weight-bold shadow bg-white m-3 mt-4 rounded text-primary p-3">
                <i className="fa fa-shopping-cart" /> BUY NOW (Rs.{parseInt(amount).toLocaleString('en-IN')}){" "}
                <sub>
                  (
                  <del>{parseInt(original).toLocaleString('en-IN')}</del>)
                </sub>
              </Link>
            </div>
          </div>
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>
                <span className="text-warning">FULL</span> Stack Project Features
              </b>
            </h4>
            <div>
              <ul>
                <li>Registration</li>
                <li>Login</li>
                <li>View Profile</li>
                <li>Edit Profile</li>
                <li>Delete Profile</li>
                <li>Logout</li>
                <li>Change Password</li>
                <li>Forget Password</li>
                <li>Verify Email</li>
                <li>Reset Password</li>
                <li>Role Management</li>
                <li>JWT Authentication and Secure Authentication</li>
                <li>Email Service and Templates</li>
                <li>CORS Prevention, CSRF Attack Prevention, SQL Injection Prevention</li>
                <br />
              </ul>
              <div
                onClick={() => openPlayer({ _id: "610381b6158ea7000881d69b" }, history)}
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3 cursor-pointer">
                <i className="fa fa-youtube-play text-danger" /> Start Free Demo
              </div>
            </div>
          </div>
          <br />
        </div>
        <div className="mt-4 mb-4 bg-light border p-2 rounded">
          <h3 className="text-warning"><i className="fa fa-diamond" /> Course Features:</h3>
          <div className="row d-flex justify-content-between p-0 m-0">
            <div className="col-md-4 col-xs-12 border rounded bg-dark shadow text-white p-2 m-lg-3 mt-3">
              <h4 className="text-center text-info">
                <i className="fa fa-desktop" /> Lecture Videos
              </h4>
              <div>
                <p className="text-center">Total 3 Videos. 7+ hours content.</p>
              </div>
            </div>
            <div className="col-md-3 col-xs-12 border rounded bg-dark shadow text-white p-2 m-lg-3 mt-3">
              <h4 className="text-center text-info">
                <i className="fa fa-file-pdf-o" /> Presentation
              </h4>
              <div>
                <p className="text-center">3 Presentations available.</p>
              </div>
            </div>
            <div className="col-md-4 col-xs-12 border rounded bg-dark shadow text-white p-2 m-lg-3 mt-3">
              <h4 className="text-center text-info">
                <i className="fa fa-code" /> Source Code
              </h4>
              <div>
                <p className="text-center">4 Source Code Repositories available.</p>
              </div>
            </div>
          </div>
        </div>
        <OtherCourses courses={allcourses} />

        {/* <div className="row d-flex justify-content-between p-0 m-0 border rounded">
          <div className="mt-4">
            <h3 className="text-info">Purchasing Process:</h3>
            <p>
              👉
              <Link to="/purchase/mernstackprojectproject" className="text-success" id="link1">
                <i className="fa fa-whatsapp text-success" /> <b>Click here</b>
              </Link>{" "}
              to purchase now.
              <br />
            </p>
          </div>
        </div> */}
        <div className="container mt-4 mb-4 d-flex justify-content-between flex-wrap border p-2 bg-light rounded">
          <div>
            <h3 className="text-warning"><i className="fa fa-desktop" /> Hardware Requirements:</h3>
            <ul>
              <li>Memory – Minimum 2 GB or 4 GB RAM</li>
              <li>Processor – Intel Core i3 CPU @2.00 GHz or later</li>
              <li>Storage – 20 GB HDD/SDD or above</li>
            </ul>
            <h3 className="text-warning"><i className="fa fa-windows" /> Software Requirements:</h3>
            <ul>
              <li>Operating System – Any OS (Windows 7 or later/Linux/Mac)</li>
              <li>NodeJS Latest Version </li>
              <li>Visual Studio Code (for web app development) </li>
              <li>Chrome/Mozilla Firefox (latest version)</li>
              <li>XAMPP</li>
            </ul>
          </div>
          <div className="col-md-6 col-xs-12">
            <img src="/images/react/mernstack-application.png" alt="certificate" className="w-100" />
          </div>
        </div>
        <div className="d-flex flex-row">
          {show ? (
            <button className="text-center btn btn-primary w-100">
              <Link to="/purchase/MERN Stack Project" className="text-decoration-none text-white">
                <i className="fa fa-shopping-cart" /> BUY NOW (Rs.{amount}/-){" "}
                <sub>
                  <del>{original}</del>
                </sub>
              </Link>
            </button>
          ) : (
            <button className="text-center btn btn-primary w-100">
              <Link to="/purchase/MERN Stack Project" className="text-decoration-none text-white">
                <i className="fa fa-shopping-cart" /> BUY NOW (Rs.{parseInt(amount).toLocaleString('en-IN')}/-){" "}
                <sub>
                  <del>{parseInt(original).toLocaleString('en-IN')}</del>
                </sub>
              </Link>
            </button>
          )}
        </div>
        <br />
        <br />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
