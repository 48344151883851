export function timeFrameCal(time) {
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  let totalseconds = 0;
  if (time.split(":").length === 3) {
    //hours+minutes+seconds
    hours = parseInt(time.split(":")[0]) * 60 * 60;
    minutes = parseInt(time.split(":")[1]) * 60;
    seconds = parseInt(time.split(":")[2]);
    totalseconds = hours + minutes + seconds;
  } else if (time.split(":").length === 2) {
    //minutes+seconds
    minutes = parseInt(time.split(":")[0]) * 60;
    seconds = parseInt(time.split(":")[1]);
    totalseconds = minutes + seconds;
  } else if (time.split(":").length === 1) {
    //seconds
    seconds = parseInt(time.split(":")[0]);
    totalseconds = seconds;
  }
  return totalseconds > 0 ? totalseconds : 1;
}
