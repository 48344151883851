import React, { useState } from 'react'
import Player from './Player'
import VideosList from './VideosList'
import VideoDetails from './VideoDetails'
import { useSelector } from 'react-redux';

export default function VMVideoPlayer(props) {
  //const { id, course } = useParams();
  const { course, id } = props.location.state || {};
  const { playerMode, isEnrolled } = useSelector(store => store.course);
  const [timeframe, setTimeFrame] = useState(0);
  return (
    <div className='container-fluid shadow rounded bg-white enrollOption'>
      <div className='row'>
        <Player timeframe={timeframe} setTimeFrame={setTimeFrame} size={playerMode === "learning" ? "8" : "6"} id={id !== "/undefined" ? id : ""} course={course} />
        <VideosList location={props.location} isEnrolled={isEnrolled} playerMode={playerMode} size={playerMode === "learning" ? "4" : "6"} id={id !== "/undefined" ? id : ""} course={course} />
      </div>
      <VideoDetails location={props.location} setTimeFrame={setTimeFrame} />
    </div>
  )
}
