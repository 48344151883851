import React from 'react'
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className="text-center custom-bg3 container rounded pb-5 text-white">
      <h1 className="text-center mt-5 pt-5">Page Not Found!</h1>
      <Link to="/" className="btn btn-primary">
        <i className="fa fa-arrow-left" /> Go To Homepage
      </Link>
    </div>
  )
}
