import { regularBatchText } from "../../utilities";

export default function SelectBatch({ selectedBatch, setSelectedBatch }) {
  return (
    <div>
      <select className='form-select p-3' value={selectedBatch} onChange={(e) => setSelectedBatch(e.target.value)}>
          <option value={regularBatchText}>😎{regularBatchText}</option>
          <option value="Weekend Batch -- 9 AM - 12 PM (S,S)">📅Weekend Batch -- 9 AM - 12 PM (S,S)</option>
          {/* <option value="Evening Batch -- 9 PM - 10 PM (M,W,F)" disabled>🌙Evening Batch -- 8 PM - 9 PM (M,W,F) - Not Available</option> */}
      </select>
    </div>
  )
}
