import React, { useState } from 'react'
import { addToStorage } from '../../utilities/localStorage'
import { quizDemoPassword, testEmail } from '../../redux/constants/courseConstants'

export default function UserCredentials() {
  const [email, setEmail] = useState(localStorage.getItem("testEmail") != null ? localStorage.getItem("testEmail") : "")
  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState("")
  const handleSubmit = (e) => {
    e.preventDefault()
    if (password === quizDemoPassword) {
      addToStorage(testEmail,email)
      window.location.reload();
      setErrors("");
    } else {
      setErrors("Invalid Credentials");
    }
  }
  return (
    <div className="text-center container ">
      <div className="d-flex justify-content-center align-items-center">
        <div className={` bg-white rounded d-flex justify-content-center flex-column container align-items-center h250 col-md-8 border p-lg-5 m-lg-3 rounded} `}>
          <form onSubmit={handleSubmit} className="col-md-6">
            <h1>Login</h1>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Enter your email address" required />
            <br />
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Enter your password" required />
            <br />
            <span className="text-danger"> {errors ? errors : ""}</span>
            <button
              className="cursor-pointer btn btn-primary col-md-12"
              type="submit"
              disabled={!email}>
              Continue
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
