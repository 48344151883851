import React, { useEffect, useState } from 'react'
import { Link } from'react-router-dom'
import { regularBatchText } from '../../utilities';

export default function AlreadyFilled() {
  const maximum=10;
  const initial=40;
  const [progress,setProgress] = useState(initial);

  useEffect(()=>{
    const interval =setInterval(() => {
      setProgress((progress)=>progress===maximum?initial:progress+initial);
    }, 2000);
    return () => clearInterval(interval);
  },[])
  return (
    <>
      <Link to={"/enroll/react-2024?batch="+regularBatchText} className="nav-link">
      <div className="custom-bg text-white p-2 rounded bg-dark">
        {/* <p className="text-center m-0 font-weight-bold"> ️‍🔥VM Surprise Offer! ️‍🔥</p> */}
        <p className="text-center m-0 font-weight-bold"> ️‍🔥Limited Seats Only!... Hurry Up & Enroll Now! ️‍🔥</p>
      </div>
      <progress value={progress} max="100" className="progress-bar progress-bar-course bg-warning m-0" style={{ height: 5, width: "100%" }}></progress>
      </Link>
    </>
  )
}
