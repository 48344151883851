import React from 'react';
import { LoadingIndicator } from "../../common/Loader";

export default function LoadingQuiz() {
  return (
    <div className="text-center container">
      <div className="d-flex justify-content-center align-items-center">
        <div className={`bg-white d-flex justify-content-center container align-items-center h250 col-md-8 border p-lg-5 m-lg-3 rounded} text-white `}>
          <h2 className="text-dark">Welcome To VM Online Test Platform<br />
            <LoadingIndicator size={"2"} color={"primary"} />
          </h2>
        </div>
      </div>
    </div>
  )
}
