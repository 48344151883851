import React, { useState, useEffect } from 'react'
import { useCourseLinks } from '../../useCourses';
import "./style.css"

export default function CodePlayer(props) {
  let [platformSelected, setplatformSelected] = useState("");
  const { coursename } = useCourseLinks(props);
  //html/css/js - web-platform
  //react - react
  //nodejs - express-simple
  //angular - angular
  //bootstrap5 - bootstrap-5
  //react native - different player.
  useEffect(() => {
    switch (coursename) {
      case 'HTML':
      case 'CSS':
      case 'JavaScript':
      case 'JavaScript Smart Course': {
        setplatformSelected('web-platform');
        break;
      }
      case 'React with Redux':
      case 'MERN Stack Project': {
        setplatformSelected('react');
        break;
      }
      case 'NodeJS': {
        setplatformSelected('express-simple');
        break;
      }
      case 'Angular': {
        setplatformSelected('angular');
        break;
      }
      case 'Bootstrap 5': {
        setplatformSelected('bootstrap-5');
        break;
      }
      default: {
        setplatformSelected('web-platform')
      }
    }
  }, [coursename])
  return (
    <div className='video-items2'>
      {coursename === "React Native" ?
        <div data-snack-id="@venkateshmogili/sample-reactnative-project" data-snack-platform="web" data-snack-preview="true" data-snack-theme="dark" style={{ overflow: "hidden", background: "#212121", border: "1px solid black", borderRadius: "4px", height: "505px", width: "100%" }}></div> :
        <iframe src={`https://stackblitz.com/edit/${platformSelected}?embed=1`} className='video-item3'></iframe>}
    </div>
  )
}
