import React, { useState } from "react";
import { useEffect } from "react";
import PurchasePopup from "../../common/PurchasePopup";
import VMModal from "../../common/VMModal";
import { LoadingIndicator } from "../../utilities";
import { api } from "../../utilities/api";
import { baseURL } from "../../utilities/baseurl";
import { allCoursesStored } from "../../utilities/constants";
import PurchaseOption2 from "./PurchaseOption2";
import SingleCourse from "./SingleCourse";
import CourseWish from "../VMVideoPlayer/Courses/CourseWish";
import { Link } from "react-router-dom";
import useCourses from "../VMVideoPlayer/useCourses";
import { addToStorage } from "../../utilities/localStorage";
import { allCoursesLabel, cartItems, coursesSync } from "../../redux/constants/courseConstants";

export default function CoursesList({ title, offer }) {
  let cartCourses = localStorage.getItem("cartItems") != null ? JSON.parse(localStorage.getItem("cartItems")) : [];
  const [courses, setCourses] = useState(cartCourses);
  const [allCourses, setAllCourses] = useState(allCoursesStored ? allCoursesStored : []);
  const [searchKey, setSearchKey] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("price");
  const [showPayment, setShowPayment] = useState(false);
  const [to, setTo] = useState("");
  const {courses:enrolledCourses}=useCourses();
  const addToCart = (course) => {
    let cart = localStorage.getItem("cartItems") != null ? JSON.parse(localStorage.getItem("cartItems")) : [];
    if (cart.length > 0) {
      let updatedCart = [];
      if (course.courseCombo.length > 0) {
        let result = [];
        course.courseCombo.forEach(courseId => {
          let filteredCourse = allCourses.filter(course => course._id === courseId)[0];
          result.push(filteredCourse)
        })
        updatedCart = [...cart, ...result, course];
      } else {
        updatedCart = [...cart, course]
      }
      if (cart.find(item => item.title === course.title)) {
        alert("You have already added to cart!")
      } else {
        addToStorage(cartItems,JSON.stringify(updatedCart));
        setCourses(updatedCart)
        alert(course.title + " Course Added Successfully!");
      }
    } else {
      let updatedCart = [];
      if (course.courseCombo.length > 0) {
        let result = [];
        course.courseCombo.forEach(courseId => {
          let filteredCourse = allCourses.filter(course => course._id === courseId)[0];
          result.push(filteredCourse)
        })
        updatedCart = [...result, course];
      } else {
        updatedCart = [course]
      }
      addToStorage(cartItems,JSON.stringify(updatedCart));
      setCourses(updatedCart)
      alert(course.title + " Course Added Successfully!");
    }
  }
  const removeFromCart = (course) => {
    let cart = localStorage.getItem("cartItems") != null ? JSON.parse(localStorage.getItem("cartItems")) : [];
    if (cart.length > 0) {
      let updatedCart = cart.filter(item => item.title !== course.title);
      addToStorage(cartItems,JSON.stringify(updatedCart));
      setCourses(updatedCart)
      alert(course.title + " Course Removed Successfully!");
    }
  }
  const areEqual = (obj1, obj2) => {
    let json1 = JSON.stringify(obj1);
    let json2 = JSON.stringify(obj2);

    return json1 === json2;
  }
  const fetchCourses = () => {
    let localData = localStorage.getItem("allcourses") !== null ? JSON.parse(localStorage.getItem("allcourses")) : [];
    if (localData.length === 0) {
      addToStorage(coursesSync,JSON.stringify(false));
    }
    setLoading(true);
    api(baseURL + "/getAllCoursesPublic/", "GET", {}, {})
      .then((data) => {
        if (data.success) {
          setLoading(false);
          let updatedResult = data.data.filter(course => course.courseType !== "inactive").map(course => {
            let result = allCoursesStored.filter(item => item.id === course._id);
            return { ...course, title: course.name, image1: course.courseLogo, image2: result.length > 0 ? result[0].image2 : "", isNew: course.isNewCourse, courselink: result.length > 0 ? result[0].courselink : "", demolink: course.demo }
          })
          //let premium = updatedResult.filter(course => course.courseType === "Premium");
          //let free = updatedResult.filter(course => course.courseType === "Free")
          //let result = [...premium, ...free]
          setAllCourses(updatedResult);
          addToStorage(allCoursesLabel,JSON.stringify(updatedResult));
          setError("")
          if (localData.length > 0) {
            let findEquality = areEqual(localData, updatedResult)
          } else {
            addToStorage(coursesSync,JSON.stringify(false));
          }
        } else {
          setError("")
          setAllCourses([]);
          addToStorage(allCoursesLabel,JSON.stringify(false));
          addToStorage(allCoursesLabel,JSON.stringify([]));
          setLoading(false);
        }
      }).catch(error => {
        setError(error.toString())
      })
  }
  useEffect(() => {
    //fetchCourses();
  }, [enrolledCourses])
  const sortOptions = [
    {
      key: "Price", value: "price"
    },
    {
      key: "Discount", value: "discount"
    },
    {
      key: "Newest", value: "createdAt"
    },
    //{
    //  key: "Latest", value: "isNewCourse"
    //},
  ]
  return (
    <div className="rounded text-white">
      <h1 className="mb-2">
        <b className="text-warning">VM</b> Training {title}
      </h1>
      <div className='d-flex align-items-center flex-wrap justify-content-center'>
        <i className='fa fa-laptop display-3 animcolor' />
        <Link to="/purchase/allcourses" className='text-decoration-none text-warning'>Purchase All Courses for <h4 className='animcolor'>60% Discount!</h4></Link>
      </div>
      {error.length > 0 ? <h3 className="text-danger">{error}</h3> : null}
      {loading ? <div className="vh-100"><LoadingIndicator size={"5"} /></div> :
        <div>
          <div className="container">
            {offer}
          </div>
          {/*
          <a href='https://play.google.com/store/apps/details?id=com.vmtrainingapp' target="_blank">
            <button className="btn btn-primary">GET THE ANDROID APP &gt;&gt;</button>
          </a>*/}
          <div className="container mb-1 mt-0">
            <div className="row align-items-center m-1">
              <div className="col-lg-8">
                <input type="text" placeholder="Search Courses" className="form-control" value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
              </div>
              <div className="col-lg-4 d-flex align-items-center bg-dark rounded">
                <p className="m-2"><i className="fa fa-sliders" /> </p>
                <select className="form-control" value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                  {sortOptions.map((choice, index) => (
                    <option value={choice.value} key={index}>{choice.key}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row p-0 m-0 justify-content-center align-items-center">
            {allCourses.length > 0 && allCourses.filter(course => course.title.toLowerCase().indexOf(searchKey.toLowerCase()) > -1)
              //.sort((a, b) => a[sortBy] > b[sortBy] ? -1 : 1)
              .map((course, index) => (
                <SingleCourse
                  key={index}
                  {...course}
                  selectedCourse={course}
                  removeFromCart={removeFromCart}
                  addToCart={addToCart}
                  courses={courses}
                  setTo={setTo}
                  setShowPayment={setShowPayment}
                  enrolledIds={enrolledCourses.map(course=>course._id)}
                  enrolledCourses={enrolledCourses}
                  comboCourses={allCourses.filter(course=>course.courseCombo.length>0)}
                />
              ))}
            {showPayment && <VMModal proceed={false} size="lg" title="Payment and Confirm Order" component={<PurchasePopup course={to.split("/purchase/")[1]} onClose={setShowPayment} />} onClose={setShowPayment} />}
            {/*<div className="col-md-3 col-xs-12 m-3 border rounded shadow bg-dark text-white">

              <img src="/images/reactandrnfull.png" className="course-image w-100" alt="course img" />
              <h5 className="pt-2 pb-2">ReactJS and React Native Course</h5>

              <div className="d-flex flex-wrap flex-row justify-content-between pb-2">
                <button className="text-center btn btn-outline-primary">
                  <Link to="/purchase/reactandreactnative" className="text-decoration-none text-white">
                    <i className="fa fa-shopping-cart" /> BUY NOW
                  </Link>
                </button>
                <a
                  href="https://youtu.be/sWzNphyuRsk"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link text-center font-weight-bold shadow bg-white rounded">
                  <i className="fa fa-youtube-play text-danger" /> DEMO
                </a>
              </div>


              <Link to="/reactandreactnative">
                <button className="text-center btn btn-primary w-100 mb-2"><i className="fa fa-book"/> View Course Details</button>
              </Link>
            </div>*/}
            {/*<hr className="bg-success p-2 m-2" />
          <h1 className="mb-4">
            <b className="text-warning">VM</b> Training Free Courses
          </h1>
          <hr className="bg-success p-2 m-2" />*/}


            <div className="container">
              <div className="container">
                <PurchaseOption2
                  to={`/purchase/allcourses`}
                  enrollto={`/enroll/allcourses`}
                  isEnroll={false}
                  isPurchase={true}
                  amount={9999}
                  original={25900}
                  enrollamount={40000}
                  enrolloriginal={50000}
                  discount={61.4}
                />
              </div>
            </div>
            {/*<hr className="bg-success p-2 m-2" />
            <h1 className="mb-4">
              <b className="text-warning">VM</b> Training Upcoming Courses
            </h1>

            <hr className="bg-success p-2 m-2" />
            <div className="col-md-3 col-xs-12 m-3 border rounded shadow bg-dark text-white">
              <div className="flip-box">
                <div className="flip-box-inner">
                  <div className="flip-box-front">
                    <img src="/images/angularfull.png" className="course-image w-100 mt-4" alt="Course img" />
                  </div>
                  <div className="flip-box-back bg-danger mt-4 rounded">
                    <h2 className="mt-4">Coming Soon!</h2>
                  </div>
                </div>
              </div>
              <br />
              <h5 className="pt-2 pb-2">Angular2+ Complete Course</h5>
              <button className="text-center btn btn-primary w-100 mb-2" disabled>
                Coming Soon!
              </button>
              <button className="text-center btn btn-primary w-100 mb-2" disabled>
                <i className="fa fa-book" /> View Course Details
              </button>
            </div>*/}
          </div>
        </div>
      }
    </div>
  );
}
