import React, { useState } from "react";
import { useEffect } from "react";
import { LoadingIndicator } from "../../utilities";
import { api } from "../../utilities/api";
import { baseURL } from "../../utilities/baseurl";
import { allCoursesStored } from "../../utilities/constants";
import PurchaseOption2 from "./PurchaseOption2";
import SingleCourse from "./SingleCourse";
import { addToStorage } from "../../utilities/localStorage";
import { cartItems } from "../../redux/constants/courseConstants";

export default function CoursesListMore({ title, offer, selectedCourses, onRefreshCart }) {
  let cartCourses = localStorage.getItem("cartItems") != null ? JSON.parse(localStorage.getItem("cartItems")) : [];
  const [courses, setCourses] = useState(cartCourses);
  const [allCourses, setAllCourses] = useState(allCoursesStored ? allCoursesStored : []);
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const addToCart = (course) => {
    let cart = localStorage.getItem("cartItems") != null ? JSON.parse(localStorage.getItem("cartItems")) : [];
    if (cart.length > 0) {
      let updatedCart = [];
      if (course.courseCombo.length > 0) {
        let result = [];
        course.courseCombo.forEach(courseId => {
          let filteredCourse = allCourses.filter(course => course._id === courseId)[0];
          result.push(filteredCourse)
        })
        updatedCart = [...cart, ...result, course];
      } else {
        updatedCart = [...cart, course]
      }
      if (cart.find(item => item.title === course.title)) {
        alert("You have already added to cart!")
      } else {
        addToStorage(cartItems,JSON.stringify(updatedCart));
        setCourses(updatedCart);
        onRefreshCart();
        alert(course.title + " Course Added Successfully!");
      }
    } else {
      let updatedCart = [];
      if (course.courseCombo.length > 0) {
        let result = [];
        course.courseCombo.forEach(courseId => {
          let filteredCourse = allCourses.filter(course => course._id === courseId)[0];
          result.push(filteredCourse)
        })
        updatedCart = [...result, course];
      } else {
        updatedCart = [course]
      }
      addToStorage(cartItems,JSON.stringify(updatedCart));
      setCourses(updatedCart)
      onRefreshCart();
      alert(course.title + " Course Added Successfully!");
    }
  }
  const removeFromCart = (course) => {
    let cart = localStorage.getItem("cartItems") != null ? JSON.parse(localStorage.getItem("cartItems")) : [];
    if (cart.length > 0) {
      let updatedCart = cart.filter(item => item.title !== course.title);
      addToStorage(cartItems,JSON.stringify(updatedCart));
      setCourses(updatedCart)
      alert(course.title + " Course Removed Successfully!");
    }
  }
  const fetchCourses = () => {
    setLoading(true);
    api(baseURL + "/getAllCoursesPublic/", "GET", {}, {})
      .then((data) => {
        if (data.success) {
          setLoading(false);
          let updatedResult = data.data.filter(course => course.courseType !== "inactive").map(course => {
            let result = allCoursesStored.filter(item => item.id === course._id);
            return { ...course, title: course.name, image1: course.courseLogo, image2: result.length > 0 ? result[0].image2 : "", isNew: course.isNewCourse, courselink: result.length > 0 ? result[0].courselink : "", demolink: course.demo }
          })
          let premium = updatedResult.filter(course => course.courseType === "Premium");
          //let free = updatedResult.filter(course => course.courseType === "Free")
          //let result = [...premium, ...free]
          setAllCourses(premium);
          setError("")
        } else {
          setError("")
          setAllCourses([])
          setLoading(false);
        }
      }).catch(error => {
        setError(error.toString())
      })
  }
  useEffect(() => {
    //fetchCourses();
  }, [])
  return (
    <div className="rounded text-white">
      {error.length > 0 ? <h3 className="text-danger text-center">{error}</h3> : null}
      {loading ? <div className="vh-100"><LoadingIndicator size={"5"} /></div> :
        <div>
          {offer}
          <div className="container mb-4 mt-0">
            <input type="text" placeholder="Search Courses" className="form-control" value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
          </div>
          <div className="row p-0 m-0 justify-content-center align-items-center">
            {allCourses.length > 0 && allCourses.filter(course => course.courseType !== "Free").filter(course => selectedCourses.map(item => item._id).filter(item => course.courseCombo.includes(item)).length === 0 && !selectedCourses.map(item => item._id).includes(course._id))
              .filter(course => course.title.toLowerCase().indexOf(searchKey.toLowerCase()) > -1).map((course, index) => (
                <SingleCourse
                  key={index}
                  {...course}
                  selectedCourse={course}
                  removeFromCart={removeFromCart}
                  addToCart={addToCart}
                  courses={courses}
                />
              ))}
            <div className="container">
              <div className="container">
                <PurchaseOption2
                  to={`/purchase/allcourses`}
                  enrollto={`/enroll/allcourses`}
                  isEnroll={false}
                  isPurchase={true}
                  amount={9999}
                  original={25900}
                  enrollamount={40000}
                  enrolloriginal={50000}
                  discount={60}
                />
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
