import React, { useState } from "react";
import { Link, useHistory,useLocation } from "react-router-dom";
import PleaseWait from "../../common/PleaseWait";
import { api, AuthUserId, baseURL, Validator } from "../../utilities";
import { GoogleLogin } from "react-google-login";
import InstallApp from "../InstallApp";
import { useEffect } from "react";
import moment from "moment";
import { addToStorage } from "../../utilities/localStorage";
import { AuthUser } from "../../redux/constants/courseConstants";

const saveUserSession=(data)=>{
  addToStorage(AuthUser.token, data.token);
  addToStorage(AuthUser.username, data.data.name);
  addToStorage(AuthUser.email, data.data.email);
  addToStorage(AuthUser.id, data.data.id);
}

// const testUsers = ["ramanirayudu1318@gmail.com","testuser1@gmail.com","testuser2@gmail.com"];
const testUsers = [];

export const internUsers=["v.sunithasankar@gmail.com","rohinipolina0518@gmail.com"];

export const recordedUsers=["httpchary@gmail.com","parvinshaik9080@gmail.com","skthalll2000@gmail.com"];

export const regularBatchUsers=["nunna.akhil.6@gmail.com","gnagaraju9928@gmail.com","v.sunithasankar@gmail.com","rohinipolina0518@gmail.com","sudanagunta.riteesh@gmail.com","kumarbollaboina@gmail.com",
 ...testUsers,
 ...recordedUsers
];

export const eveningBatchUsers=["balakrishna.nudurumati@gmail.com","nagarjuna.yadika@gmail.com","kranthikumar492@gmail.com","boga.nithin@gmail.com",
  ...testUsers
];

export default function Login() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [, forceUpdate] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const backlog = queryParams.get('backlog');
  const validator = Validator();
  const history = useHistory();
  const clientId = "59189352007-6b69hlcdl8egpk7us5v2cmhganvqunru.apps.googleusercontent.com";

  const deviceInfo = () => {
    let { appCodeName, appName,
      appVersion,
      platform,
      userAgent } = window.navigator;
    let OSName = "Unknown";
    let DeviceType = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1) OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
    //device type
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
      DeviceType = "tablet";
    } else if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        userAgent
      )
    ) {
      DeviceType = "mobile";
    } else {
      DeviceType = "desktop";
    }
    return {
      DeviceType, OSName, appCodeName, appName,
      appVersion,
      platform,
      userAgent
    }
  }
  const onLoginSuccess = (res) => {
    let { appCodeName,
      appName,
      appVersion,
      platform,
      userAgent,
      OSName,
      DeviceType, } = deviceInfo();
    fetch("https://api64.ipify.org/?format=json")
      .then(res => res.json())
      .then(response => {
        let browserInfo = {
          appCodeName,
          appName,
          appVersion,
          platform,
          userAgent,
          OSName,
          DeviceType,
          ipAddress: response ? response.ip : "N/A",
          lastLoginTime: moment(new Date()).format("YYYY-MM-DD hh:mm:ss A")
        }
        let user = api(baseURL + "/logingoogle", "POST", { "content-type": "application/json" }, {
          email: res.profileObj.email, devices: {
            browserInfo,
            ipAddress: response ? response.ip : "N/A", OSName, DeviceType, lastLoginTime: browserInfo.lastLoginTime
          }
        });
        user
          .then((data) => {
            if (data.success) {
              setMessage("You have successfully logged in!");
              saveUserSession(data);
              setTimeout(() => {
                setLoggedIn(true);
                setLoading(false);
                history.push("/vm/vmcourses");
              }, 1000);
            } else {
              setMessage(data.message ? data.message : "Wrong Username/Password");
              setLoading(false);
            }
          }).catch((error) => {
            setMessage("Network Error!");
            setLoading(false);
          });
      });
  };
  const onFailureSuccess = (res) => {
    setMessage("Wrong Username/Password");
  };
  const submitLogin = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setLoading(true);
      let { appCodeName,
        appName,
        appVersion,
        platform,
        userAgent,
        OSName,
        DeviceType, } = deviceInfo();
      //fetch("https://api64.ipify.org/?format=json")
      //  .then(res => res.json())
      //  .then(response => {
      let response = { ip: "N/A" };
      let browserInfo = {
        appCodeName,
        appName,
        appVersion,
        platform,
        userAgent,
        OSName,
        DeviceType,
        ipAddress: response ? response.ip : "N/A",
        lastLoginTime: moment(new Date()).format("YYYY-MM-DD hh:mm:ss A")
      }
      //if (response) {
      let user = api(baseURL + "/login", "POST", { "content-type": "application/json" }, { email: username, password, devices: { browserInfo, ipAddress: response ? response.ip : "N/A", OSName, DeviceType, lastLoginTime: browserInfo.lastLoginTime } });
      user
        .then((data) => {
          if (data.success) {
            //setMessage("You have successfully logged in!");
            saveUserSession(data);
            setTimeout(() => {
              setLoggedIn(true);
              setLoading(false);

              let commonLink="/vm/vmcourses";
              if(recordedUsers.includes(data.data.email)){
                commonLink = "/vmlive/react-2024/recorded";
              } else if(regularBatchUsers.includes(data.data.email)){
                commonLink = "/vmlive/react-2024-regular";
              } else if(eveningBatchUsers.includes(data.data.email)){
                commonLink = "/vmlive/react-2024-evening";
              }

              history.push(backlog?backlog:commonLink);
              window.location.reload();
            }, 3000);
          } else {
            setMessage(data.message ? data.message : "Wrong Username/Password");
            setLoading(false);
          }
        })
        .catch((error) => {
          setMessage("Network Error!");
          setLoading(false);
        });
      //}
      //})
    } else {
      validator.current.showMessages();
      forceUpdate(true);
    }
  };
  useEffect(() => {
    if (AuthUserId !== null) {
      history.push("/vm/vmcourses");
    }
  }, [])
  return (
    <div className="container-fluid text-center vh-100 custom-bg2">
      <InstallApp />
      {!loggedIn && (
        <div className="row justify-content-center align-items-center m-0 p-0 pt-4">
          <div className="col-md-5 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
            <form onSubmit={submitLogin}>
              <i className="fa fa-user" style={{ fontSize: 200, color: "orange" }} />
              <h1 className="mb-4">Login To VM Account</h1>
              <input
                type="email"
                placeholder="Username"
                className="form-control"
                value={username}
                // required
                onChange={(e) => setUsername(e.target.value)}
                onFocus={() => setMessage("")}
              />
              {validator.current.message("Username ", username, "required|email")}
              <br />
              <input
                type="password"
                placeholder="Password"
                className="form-control"
                value={password}
                maxLength={16}
                minLength={8}
                // required
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setMessage("")}
              />
              {validator.current.message("Password", password, "required|password")}
              <span className="error-message">{message}</span>
              <br />
              <div className="d-flex justify-content-center align-items-center">
                <div className="w-50 m-1">
                  {!loading && <input type="submit" value="Login" className="btn btn-primary w-100 p-2" />}
                </div>
                <div className="google-btn w-50 m-1">
                  {!loading && <GoogleLogin
                    className="font-weight-bold w-100  rounded"
                    clientId={clientId}
                    buttonText="Google"
                    onSuccess={onLoginSuccess}
                    onFailure={onFailureSuccess}
                    cookiePolicy={"single_host_origin"}
                  />}
                </div>
              </div>
              {loading && <div className="w-100 col-lg-12"><PleaseWait content={"Logging in..."} /></div>}
            </form>
            <br />
            <Link to="/forgotpassword">
              <button className="btn btn-outline-danger w-100">Forgot Password?</button>
            </Link>
          </div>
        </div>
      )}
      {/*{loggedIn && <Redirect to="/vm/vmcourses" />}*/}
    </div>
  );
}
