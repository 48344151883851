export const quizConfig={
  clappings:["clapping-1.gif", "clapping-2.gif", "clapping-3.gif", "clapping-4.gif","clapping-5.gif","clapping-6.jpg","clapping-7.jpg","clapping-8.jpg","clapping-9.jpg"],
  wrongs:["wrong-1.jpg", "wrong-2.webp", "wrong-3.jpg", "wrong-4.jpg"],
  appreciations:["You nailed it!","You rocked it!","You are a genius!","You are a legend!","You are a master!","You did it!","Superrrrr!","Very Nice!","Good Job!","Great Job!","Awesome!","Wonderful!","Amazing!"],
  adminEmail:'mogiliv3@gmail.com'
}

export const randomItem=(items=[])=>{
  return items[Math.floor(Math.random() * items.length)];
}

const offsetAnimation = 3;//as css animation takes 3 seconds.
export const quizDuration=30+offsetAnimation;
// export const quizRefreshInterval=1*60*1000;//1 minute
export const quizRefreshInterval=5000;//5 seconds
export const quizCloseTime=30*1000;//30 seconds
export const pollCloseTime=5*60*1000;//5 minutes

export const resetInteractionTime = 5*60*1000; //5 minutes

export const levelThresholds=[10000,30000,50000];
export const levels =[1,2,3];

export const sessionSchedules = [
  // week-1
  {
    schedule:"2024/11/04 17:00:00",
    date: "Nov 4 Monday",
    time: "5 - 6 PM",
    title: "Day-1: Intro to React, JSX and React Elements",
    topics: ["Prerequisites", "Introduction to React", "Applications of React", "Software Requirement", "Project Setup", "Hello World!", "JSX and It’s usage", "React Elements", "Static Movie Application", "Exercise Task"],
  },
  {
    schedule:"2024/11/05 17:00:00",
    date: "Nov 5 Tuesday",
    time: "5 - 5:30 PM",
    title: "Q&A: For Day-1 Topics & Exercise Task Review",
    topics: ["Doubts Clarification", "Code Review and Feedback for Exercise Task"],
  },
  {
    schedule:"2024/11/06 17:00:00",
    date: "Nov 6 Wednesday",
    time: "5 - 6 PM",
    title: "Day-2: Components, Rendering, Lists and Props",
    topics: ["Functional Components", "Conditional Rendering", "Ternary Rendering", "Displaying Lists", "Props", "Practical - Days List", "Practical - Product List", "Exercise Task"],
  },
  {
    schedule:"2024/11/07 17:00:00",
    date: "Nov 7 Thursday",
    time: "5 - 5:30 PM",
    title: "Q&A: For Day-2 Topics & Exercise Task Review",
    topics: ["Doubts Clarification", "Code Review and Feedback for Exercise Task"],
  },
  {
    schedule:"2024/11/08 17:00:00",
    date: "Nov 8 Friday",
    time: "5 - 6 PM",
    title: "Day-3: State, Lifecycle Methods, Login and Registration Forms",
    topics: ["State", "Lifecycle Methods", "Login Form", "Registration Form", "Exercise Task"],
  },
  {
    schedule:"2024/11/09 17:00:00",
    date: "Nov 9 Saturday",
    time: "5 - 5:30 PM",
    title: "Q&A: For Day-3 Topics & Exercise Task Review",
    topics: ["Doubts Clarification", "Code Review and Feedback for Exercise Task"],
  },
  // week-2
  {
    schedule:"2024/11/11 17:00:00",
    date: "Nov 11 Monday",
    time: "5 - 6 PM",
    title: "Day-4: Events, Forms, Styles, React Bootstrap",
    topics: ["Events Handling", "Forms", "Styles", "External Styles - React Bootstrap", "Exercise Task"],
  },
  {
    schedule:"2024/11/12 17:00:00",
    date: "Nov 12 Tuesday",
    time: "5 - 5:30 PM",
    title: "Q&A: For Day-4 Topics & Exercise Task Review",
    topics: ["Doubts Clarification", "Code Review and Feedback for Exercise Task"],
  },
  {
    schedule:"2024/11/13 17:00:00",
    date: "Nov 13 Wednesday",
    time: "5 - 6 PM",
    title: "Day-5: Routing V6.0, Dynamic Music Application",
    topics: ["Routing", "Routers", "Route Matchers", "Route Navigation", "Practical - Dynamic Music Application", "Exercise Task"],
  },
  {
    schedule:"2024/11/14 17:00:00",
    date: "Nov 14 Thursday",
    time: "5 - 5:30 PM",
    title: "Q&A: For Day-5 Topics & Exercise Task Review",
    topics: ["Doubts Clarification", "Code Review and Feedback for Exercise Task"],
  },
  {
    schedule:"2024/11/15 17:00:00",
    date: "Nov 15 Friday",
    time: "5 - 6 PM",
    title: "Day-6: API Integration, Hooks, Dynamic Blog Application",
    topics: ["API Integration", "Introduction to Hooks", "useState", "useEffect", "useRef", "useContext", "Custome Hooks", "Practical - Dynamic Blog Application", "Exercise Task"],
  },
  {
    schedule:"2024/11/16 17:00:00",
    date: "Nov 16 Saturday",
    time: "5 - 5:30 PM",
    title: "Q&A: For Day-6 Topics & Exercise Task Review",
    topics: ["Doubts Clarification", "Code Review and Feedback for Exercise Task"],
  },
  // week-3
  {
    schedule:"2024/11/18 17:00:00",
    date: "Nov 18 Monday",
    time: "5 - 6 PM",
    title: "Day-7: Deployment, Portfolio Application",
    topics: ["How to build project?", "Deploy to Netlify", "Deploy to Vercel", "Portfolio Application","Navbar", "Profile", "About", "Skills", "Work", "Resume","Exercise Task"],
  },
  {
    schedule:"2024/11/19 17:00:00",
    date: "Nov 19 Tuesday",
    time: "5 - 5:30 PM",
    title: "Q&A: For Day-7 Topics & Mock Interview",
    topics: ["Doubts Clarification", "Mock Interview"],
  },
  {
    schedule:"2024/11/20 17:00:00",
    date: "Nov 20 Wednesday",
    time: "5 - 6 PM",
    title: "Day-8: Spotify 2.0 Music Application",
    topics: ["Credits", "Theme Changer", "Player Progressbar", "Player Controls", "Menu", "Exercise Task"],
  },
  {
    schedule:"2024/11/21 17:00:00",
    date: "Nov 21 Thursday",
    time: "5 - 5:30 PM",
    title: "Q&A: For Day-8 Topics & Mock Interview",
    topics: ["Doubts Clarification", "Mock Interview"],
  },
  {
    schedule:"2024/11/22 17:00:00",
    date: "Nov 22 Friday",
    time: "5 - 6 PM",
    title: "Day-9: E-Commerce Application",
    topics: ["Navbar", "Product List", "Product Details", "Add To Cart", "Checkout", "Payment", "Authentication", "Exercise Task"],
  },
  {
    schedule:"2024/11/23 17:00:00",
    date: "Nov 23 Saturday",
    time: "5 - 5:30 PM",
    title: "Q&A: For Day-9 Topics & Mock Interview",
    topics: ["Doubts Clarification", "Mock Interview","Best Practices"],
  },
  // {
  //   schedule:"2024/11/24 17:00:00",
  //   date: "Nov 24 Sunday",
  //   time: "5 - 6 PM",
  //   title: "Day-10: Redux Toolkit Introduction and E-Commerce Application (Optional)",
  //   topics: ["Redux Toolkit Introduction", "Redux Core Concepts", "Redux Toolkit Practical", "E-Commerce App Project", "Navbar", "Product List", "Product Details", "Add To Cart", "Checkout", "Payment", "Authentication", "Exercise Task", "What's next plan?"],
  // }
];


export const sessionSchedulesEvening = [
  // week-1
  {
    schedule:"2024/11/04 21:00:00",
    date: "Nov 4 Monday",
    time: "9 - 10 PM",
    title: "Day-1: Intro to React, JSX and React Elements",
    topics: ["Prerequisites", "Introduction to React", "Applications of React", "Software Requirement", "Project Setup", "Hello World!", "JSX and It’s usage", "React Elements", "Static Movie Application", "Exercise Task"],
  },
  {
    schedule:"2024/11/05 21:00:00",
    date: "Nov 5 Tuesday",
    time: "9 - 9:30 PM",
    title: "Q&A: For Day-1 Topics & Exercise Task Review",
    topics: ["Doubts Clarification", "Code Review and Feedback for Exercise Task"],
  },
  {
    schedule:"2024/11/06 21:00:00",
    date: "Nov 6 Wednesday",
    time: "9 - 10 PM",
    title: "Day-2: Components, Rendering, Lists and Props",
    topics: ["Functional Components", "Conditional Rendering", "Ternary Rendering", "Displaying Lists", "Props", "Practical - Days List", "Practical - Product List", "Exercise Task"],
  },
  {
    schedule:"2024/11/07 21:00:00",
    date: "Nov 7 Thursday",
    time: "9 - 9:30 PM",
    title: "Q&A: For Day-2 Topics & Exercise Task Review",
    topics: ["Doubts Clarification", "Code Review and Feedback for Exercise Task"],
  },
  {
    schedule:"2024/11/08 21:00:00",
    date: "Nov 8 Friday",
    time: "9 - 10 PM",
    title: "Day-3: State, Lifecycle Methods, Login and Registration Forms",
    topics: ["State", "Lifecycle Methods", "Login Form", "Registration Form", "Exercise Task"],
  },
  {
    schedule:"2024/11/09 21:00:00",
    date: "Nov 9 Saturday",
    time: "9 - 9:30 PM",
    title: "Q&A: For Day-3 Topics & Exercise Task Review",
    topics: ["Doubts Clarification", "Code Review and Feedback for Exercise Task"],
  },
  // week-2
  {
    schedule:"2024/11/11 21:00:00",
    date: "Nov 11 Monday",
    time: "9 - 10 PM",
    title: "Day-4: Events, Forms, Styles, React Bootstrap",
    topics: ["Events Handling", "Forms", "Styles", "External Styles - React Bootstrap", "Exercise Task"],
  },
  {
    schedule:"2024/11/12 21:00:00",
    date: "Nov 12 Tuesday",
    time: "9 - 9:30 PM",
    title: "Q&A: For Day-4 Topics & Exercise Task Review",
    topics: ["Doubts Clarification", "Code Review and Feedback for Exercise Task"],
  },
  {
    schedule:"2024/11/13 21:00:00",
    date: "Nov 13 Wednesday",
    time: "9 - 10 PM",
    title: "Day-5: Routing V6.0, Dynamic Music Application",
    topics: ["Routing", "Routers", "Route Matchers", "Route Navigation", "Practical - Dynamic Music Application", "Exercise Task"],
  },
  {
    schedule:"2024/11/14 21:00:00",
    date: "Nov 14 Thursday",
    time: "9 - 9:30 PM",
    title: "Q&A: For Day-5 Topics & Exercise Task Review",
    topics: ["Doubts Clarification", "Code Review and Feedback for Exercise Task"],
  },
  {
    schedule:"2024/11/15 21:00:00",
    date: "Nov 15 Friday",
    time: "9 - 10 PM",
    title: "Day-6: API Integration, Hooks, Dynamic Blog Application",
    topics: ["API Integration", "Introduction to Hooks", "useState", "useEffect", "useRef", "useContext", "Custome Hooks", "Practical - Dynamic Blog Application", "Exercise Task"],
  },
  {
    schedule:"2024/11/16 21:00:00",
    date: "Nov 16 Saturday",
    time: "9 - 9:30 PM",
    title: "Q&A: For Day-6 Topics & Exercise Task Review",
    topics: ["Doubts Clarification", "Code Review and Feedback for Exercise Task"],
  },
  // week-3
  {
    schedule:"2024/11/18 21:00:00",
    date: "Nov 18 Monday",
    time: "9 - 10 PM",
    title: "Day-7: Deployment, Portfolio Application",
    topics: ["How to build project?", "Deploy to Netlify", "Deploy to Vercel", "Portfolio Application","Navbar", "Profile", "About", "Skills", "Work", "Resume","Exercise Task"],
  },
  {
    schedule:"2024/11/19 21:00:00",
    date: "Nov 19 Tuesday",
    time: "9 - 9:30 PM",
    title: "Q&A: For Day-7 Topics & Mock Interview",
    topics: ["Doubts Clarification", "Mock Interview"],
  },
  {
    schedule:"2024/11/20 21:00:00",
    date: "Nov 20 Wednesday",
    time: "9 - 10 PM",
    title: "Day-8: Spotify 2.0 Music Application",
    topics: ["Credits", "Theme Changer", "Player Progressbar", "Player Controls", "Menu", "Exercise Task"],
  },
  {
    schedule:"2024/11/21 21:00:00",
    date: "Nov 21 Thursday",
    time: "9 - 9:30 PM",
    title: "Q&A: For Day-8 Topics & Mock Interview",
    topics: ["Doubts Clarification", "Mock Interview"],
  },
  {
    schedule:"2024/11/22 21:00:00",
    date: "Nov 22 Friday",
    time: "9 - 10 PM",
    title: "Day-9: E-Commerce Application",
    topics: ["Navbar", "Product List", "Product Details", "Add To Cart", "Checkout", "Payment", "Authentication", "Exercise Task"],
  },
  {
    schedule:"2024/11/23 21:00:00",
    date: "Nov 23 Saturday",
    time: "9 - 9:30 PM",
    title: "Q&A: For Day-9 Topics & Mock Interview",
    topics: ["Doubts Clarification", "Mock Interview","Best Practices"],
  },
  // {
  //   schedule:"2024/11/24 21:00:00",
  //   date: "Nov 24 Sunday",
  //   time: "9 - 10 PM",
  //   title: "Day-10: Redux Toolkit Introduction and E-Commerce Application (Optional)",
  //   topics: ["Redux Toolkit Introduction", "Redux Core Concepts", "Redux Toolkit Practical", "E-Commerce App Project", "Navbar", "Product List", "Product Details", "Add To Cart", "Checkout", "Payment", "Authentication", "Exercise Task", "What's next plan?"],
  // }
];