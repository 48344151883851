//import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom';
import { useCourseLinks } from '../../useCourses';
import { useDispatch } from 'react-redux';
import CodePlayer from '../CodePlayer';
import { setLectureDetails, setPlayerMode } from '../../../../redux/reducers/courseSlice';
import "./style.css"
import { useState } from 'react';
import EnrollOptions from './EnrollOptions';
import LearningOptions from './LearningOptions';
import CourseLoader from '../../Courses/CourseLoader';
import LecturesList from './LecturesList';
import { AuthUserId } from '../../../../utilities';
import { addToStorage } from '../../../../utilities/localStorage';
import { CourseModes } from '../../../../redux/constants/courseConstants';

const newUsers=["65e41974fa3038000987afbc","65f308823f96f10008e901f4","65fc02f8dfcfad0008acb888"];
export const isNewUser=newUsers.includes(AuthUserId);
const accessLimit=9;
const limitedCourse = ["React with Redux"];

export default function VideosList({ id, course, size, playerMode, isEnrolled, location }) {
  const history = useHistory();
  const { links, coursename, courselink, courseLogo, totalCourseTime, loading } = useCourseLinks(location);
  const [showSearch, setShowSearch] = useState(false);
  const [expandList, setExpandList] = useState(true);
  const [searchText, setSearchText] = useState("");
  const maxLimit=isNewUser && limitedCourse.includes(coursename)?accessLimit:links.length;
  const dispatch = useDispatch();
  const playVideo = (video) => {
    if (isEnrolled) {
      dispatch(setLectureDetails(video))
      history.push("/vm/vmvideoplayer", { course, id: video.url.split("https://youtu.be/")[1] });
    } else {
      window.alert("Please enroll to access the full content!");
      history.push("/purchase/" + courselink);
    }
  }
  const turnOnPlayerMode = () => {
    let playerModeSelected = playerMode === CourseModes.learning ? CourseModes.practice : CourseModes.learning;
    addToStorage(CourseModes.playerMode,playerModeSelected);
    dispatch(setPlayerMode(playerModeSelected))
  }
  const filterLinks = (links) => {
    let result = searchText.length > 0 ? links.slice(0,maxLimit).filter((item) => item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 || item.description.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 || item.duration.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) : links;
    return result;
  }
  return (
    <div className={`col-md-${size} p-0 col-xs-12 col-sm-12 bg-dark text-white infoicon2`}>
      {/* code player */}
      {playerMode === "learning" ?
        <div>
          <div className='d-flex align-items-center bg-primary text-white'>
            <div className='d-flex col-md-10'>
              <div className='col-lg-3 col-md-3 col-xs-3 col-sm-3 m-2 p-1 shadow card'>
                <img src={courseLogo} alt="course icon" className='img-fluid rounded' />
              </div>
              <div className='p-2'>
                <span>Course</span>
                <h6 className='m-0'><b>{coursename === "projects" ? "MERN Stack Application " : coursename} Complete Course</b></h6>
                <div className='d-flex justify-content-between'>
                  <small className='font-weight-bold'>{totalCourseTime}</small>
                  <i className={`d-block d-lg-none fa fa-angle-double-${expandList ? 'up' : 'down'}`} onClick={() => setExpandList(!expandList)} />
                </div>
              </div>
            </div>
            {isEnrolled && <div className='text-center col-md-2' onClick={() => setShowSearch(!showSearch)}>
              <i className='fa fa-search bg-white text-dark p-2 rounded cursor-pointer shadow' />
            </div>}
          </div>
          {loading ? <div className='rounded'>
            <CourseLoader height={50} width={480} length={1} />
            <CourseLoader height={50} width={480} length={1} />
            <CourseLoader height={50} width={480} length={1} />
            <CourseLoader height={50} width={480} length={1} />
            <CourseLoader height={50} width={480} length={1} />
          </div> :
            <div className='video-items vh-100'>
              {showSearch && <div className='d-flex align-items-center bg-white sticky-top'>
                <input type="text" placeholder='Search...' value={searchText} onChange={(e) => setSearchText(e.target.value)} className='form-control rounded-0 sticky-top z-11' /><i className='fa fa-times text-danger m-2 cursor-pointer' onClick={() => setShowSearch(!showSearch)} /></div>}
              <LecturesList
              expandList={expandList}
              links={links}
              filterLinks={filterLinks}
              isEnrolled={isEnrolled}
              courseLogo={courseLogo}
              searchText={searchText}
              id={id}
              playVideo={playVideo}
              maxLimit={maxLimit}
              />
            </div>}
        </div> : <CodePlayer />}
      {!isEnrolled ? <div>
        <EnrollOptions location={location} />
        <LearningOptions disabled={true} playerMode={playerMode} turnOnPlayerMode={turnOnPlayerMode} /></div> :
        <LearningOptions disabled={false} playerMode={playerMode} turnOnPlayerMode={turnOnPlayerMode} />}
    </div>
  )
}
