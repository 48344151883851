import React from 'react';
export default function Countdown({ start, count, loading }) {
  return (
    <div className="d-flex justify-content-center container align-items-center ">
      {start && (
        <div className={`bg-white d-flex justify-content-center container align-items-center h250 col-md-8 border p-4 m-lg-3 rounded`}>
          <h1 className="text-center">Your test will start in <br />
            <b className="text-primary">{count}</b><br /> seconds...</h1>
        </div>
      )}
    </div>
  )
}
