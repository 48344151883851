import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function CourseWish() {
  return (
    <div className='d-flex justify-content-between flex-wrap align-items-center flex-wrap bg-dark text-white rounded'>
      <div className='d-flex align-items-center flex-wrap'>
        <img src="https://vmtraining.netlify.app/images/logo.png" alt="user logo" className='img-circle' />
        <GoodMorningWish />
      </div>

      <div className='d-flex align-items-center flex-wrap'>
        <i className='fa fa-laptop display-3 animcolor' />
        <Link to="/purchase/allcourses" className='text-decoration-none text-warning'>Purchase All Courses for <h4 className='animcolor'>60% Discount!</h4></Link>
      </div>
    </div>
  )
}
export function GoodMorningWish() {
  const [wish, setWish] = useState("");
  useEffect(() => {
    const hours = new Date().getHours();
    if (hours < 12 && hours > 6) {
      setWish(`Morning`);
    } else {
      if (hours > 12 && hours < 18) {
        setWish(`Afternoon`);
      } else {
        setWish(`Evening`);
      }
    }
  }, []);
  const brilliantWords = ["Genius", "Brilliant", "VM Star"];
  const randomWord = brilliantWords[Math.floor(Math.random() * brilliantWords.length)];
  return (<span className='text-white m-2 p-0'>Good {wish}{(localStorage.getItem("username") !== null ? ", " + localStorage.getItem("username") : " " + randomWord)}!</span>
  )
}