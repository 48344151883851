import React from 'react'
import { useParams } from 'react-router'
import OnlineTest from './OnlineTest'

export default function OnlineTestContainer() {
  const { quizName, quizType } = useParams();
  return (
    <div>
      <OnlineTest quizName={quizName} quizType={quizType} />
    </div>
  )
}
