import React, { useState } from "react";
import { useEffect } from "react";
import PurchasePopup from "../../common/PurchasePopup";
import VMModal from "../../common/VMModal";
import { allCoursesStored } from "../../utilities/constants";
import "./style.css";

const allCourses = allCoursesStored.filter(course => course.courseCombo.length === 0 && course.courseType !== "Free").map((course) => ({ label: course.title, value: course._id })).sort((a, b) => a.label > b.label ? 1 : -1)
export default function Pricing() {
  const [showModal, setShowModal] = useState(null)
  const [selectedCourses, setSelectedCourses] = useState([])
  const [showPayment, setShowPayment] = useState(false);
  const pricingPlans = [
    {
      title: "Mini (Quarterly)",
      price: "1,999",
      tag: "Quarter",
      features: [`Any <span>2</span> Courses + <span>1</span> Free Course`, "<span>1</span> Device", "Video Lectures, Quiz Assessments, Tasks", "Presentations, Source Code, Certification", "<span>24/7</span> Community Support",]
    },
    {
      title: "Premium (Annually)",
      price: "3,999",
      tag: "Year",
      features: ["Any <span>5</span> Courses", "<span>1</span> Device", "Video Lectures, Quiz Assessments, Tasks", "Presentations, Source Code, Certification", "<span>24/7</span> Community Support",]
    },
    {
      title: "Pro (Two Years)",
      price: "5,999",
      tag: "Two Years",
      features: ["All <span>9 (Current)</span> Courses", "<span>2</span> Devices", "Video Lectures, Quiz Assessments, Tasks", "Presentations, Source Code, Certification", "<span>24/7</span> Community Support",]
    }
  ];
  const handleSubmit = () => {
    console.log("selected", selectedCourses);
    if (selectedCourses.length > 0) {
      setShowModal(null);
      setShowPayment(true);
    } else {
      alert("Please choose courses to proceed!")
    }
  }
  const handleSubmit2 = () => {
    console.log("confirmed");
    setShowPayment(false);
  }
  return (
    <div>
      <div id="generic_price_table" className="pt-3">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* <!--PRICE HEADING START--> */}
                <div className="price-heading text-warning clearfix">
                  <h1><b>VM</b> Subscription Plans</h1>
                </div>
                {/* <!--//PRICE HEADING END--> */}
              </div>
            </div>
          </div>
          <div className="container">
            {/* <!--BLOCK ROW START--> */}
            <div className="row">
              {pricingPlans.length > 0 && pricingPlans.map((plan, index) => (
                <div className="col-md-4" key={index}>
                  {/* <!--PRICE CONTENT START--> */}
                  <div className={`generic_content clearfix ${index === 1 ? 'active' : ''}`}>
                    {/* <!--HEAD PRICE DETAIL START--> */}
                    <div className="generic_head_price clearfix">
                      {/* <!--HEAD CONTENT START--> */}
                      <div className="generic_head_content clearfix">
                        {/* HEAD START */}
                        <div className="head_bg"></div>
                        <div className="head">
                          <span>{plan.title}</span>
                        </div>
                        {/* <!--//HEAD END--> */}
                      </div>
                      {/* <!--//HEAD CONTENT END-->
                            
                            <!--PRICE START--> */}
                      <div className="generic_price_tag clearfix">
                        <span className="price">
                          <span className="sign">₹</span>
                          <span className="currency">{plan.price}</span>
                          <span className="cent">.99</span>
                          <span className="month">/{plan.tag}</span>
                        </span>
                      </div>
                      {/* <!--//PRICE END--> */}
                    </div>
                    {/* <!--//HEAD PRICE DETAIL END-->
                        
                        <!--FEATURE LIST START--> */}
                    <div className="generic_feature_list">
                      {/*<button className="btn btn-success mb-2" onClick={() => setAnnuallyModal(true)}>Know More</button>*/}
                      <ul>
                        {plan.features.map((feature, indexx) => (
                          <li key={indexx} dangerouslySetInnerHTML={{ __html: feature }} />
                        ))}
                      </ul>
                    </div>
                    {/* <!--//FEATURE LIST END-->
                        
                        <!--BUTTON START--> */}
                    <div className="generic_price_btn  clearfix">
                      <a className="cursor-pointer" href={null} onClick={() => {
                        setSelectedCourses([]);
                        setShowModal(plan);
                      }
                      }>
                        Enroll
                      </a>
                    </div>
                    {/* <!--//BUTTON END--> */}
                  </div>
                  {/* <!--//PRICE CONTENT END--> */}
                </div>
              ))}
            </div>
            {/* <!--//BLOCK ROW END--> */}
          </div>
        </section>
        <br />
      </div>
      {showModal && <VMModal proceed={true} size="md" title={showModal.title} component={<KnowMoreDetails details={showModal} setSelectedCourses={setSelectedCourses} selectedCourses={selectedCourses} setShowModal={setShowModal} />} onClose={setShowModal} handleSubmit={handleSubmit} />}

      {showPayment && <VMModal proceed={false} size="lg" title="Payment and Enrollment" component={<ProceedPayment />} onClose={setShowPayment} handleSubmit={handleSubmit2} />}
    </div>
  );
}

function ProceedPayment() {
  return (
    <PurchasePopup course="allcourses" />
  )
}
function KnowMoreDetails({ details, selectedCourses, setSelectedCourses, setShowModal }) {
  return (
    <div>
      {/*<h5>You have selected: <b className="text-success">{details.title} Plan</b></h5>*/}
      {details.tag === "Quarter" && <GetFreeCourse selectedCourses={selectedCourses} setSelectedCourses={setSelectedCourses} setShowModal={setShowModal} />}
      {details.tag === "Year" && <SelectCourses selectedCourses={selectedCourses} setSelectedCourses={setSelectedCourses} setShowModal={setShowModal} />}
      {details.tag === "Two Years" && <SelectAllCourses selectedCourses={selectedCourses} setSelectedCourses={setSelectedCourses} setShowModal={setShowModal} />}
    </div>
  )
}


function GetFreeCourse({ selectedCourses, setSelectedCourses, setShowModal }) {
  let courseLimit = 3;
  return (
    <div>
      <h6>Choose Any <b>2</b> Courses:</h6>
      <ol>
        {allCourses.length > 0 && allCourses.map((course, index) => (
          <li><input type="checkbox" value={course.value} disabled={selectedCourses.length === courseLimit && !selectedCourses.includes(course.value)} checked={selectedCourses.includes(course.value)} onChange={() => setSelectedCourses((previousSelected) => {
            let previousData = [...previousSelected];
            if (selectedCourses.length === courseLimit && !selectedCourses.includes(course.value)) {
              return previousData;
            } else {
              if (!previousData.includes(course.value)) {
                previousData.push(course.value)
              } else {
                let findIndex = previousData.findIndex(item => item === course.value);
                previousData.splice(findIndex, 1);
              }
            }
            return previousData;
          })} id={course.value} /> <label htmlFor={course.value}>{course.label}</label></li>
        ))}
      </ol>
      {selectedCourses.length === courseLimit - 1 && <small className="text-success font-weight-bold">Choose Your Free Course!</small>}
      {selectedCourses.length < courseLimit - 1 && <small className="text-warning">Choose Minimum <b>2</b> Courses <b className="text-success">To Get 1 Free Course</b></small>}
      {selectedCourses.length === courseLimit && <small>Do you want to buy more courses? <b className="cursor-pointer text-success" onClick={() => {
        setShowModal(false);
        setSelectedCourses([]);
      }}>Change Plan</b></small>}
    </div>
  )
}

function SelectCourses({ selectedCourses, setSelectedCourses, setShowModal }) {
  let courseLimit = 5;
  return (
    <div>
      <h6>Choose Any <b>5</b> Courses:</h6>
      <ol>
        {allCourses.length > 0 && allCourses.map((course, index) => (
          <li><input type="checkbox" value={course.value} disabled={selectedCourses.length === courseLimit && !selectedCourses.includes(course.value)} checked={selectedCourses.includes(course.value)} onChange={() => setSelectedCourses((previousSelected) => {
            let previousData = [...previousSelected];
            if (selectedCourses.length === courseLimit && !selectedCourses.includes(course.value)) {
              return previousData;
            } else {
              if (!previousData.includes(course.value)) {
                previousData.push(course.value)
              } else {
                let findIndex = previousData.findIndex(item => item === course.value);
                previousData.splice(findIndex, 1);
              }
            }
            return previousData;
          })} id={course.value} /> <label htmlFor={course.value}>{course.label}</label></li>
        ))}
      </ol>
      {selectedCourses.length < courseLimit && <small className="text-warning">Choose Minimum <b>5</b> Courses</small>}
      {selectedCourses.length === courseLimit && <small>Do you want to buy more courses? <b className="cursor-pointer text-success" onClick={() => {
        setShowModal(false);
        setSelectedCourses([]);
      }}>Change Plan</b></small>}
    </div>
  )
}

function SelectAllCourses({ selectedCourses, setSelectedCourses, setShowModal }) {
  useEffect(() => {
    let allCourseIds = allCourses.map(course => course.value);
    setSelectedCourses(allCourseIds)
  }, [])
  return (
    <div>
      <h6 className="text-capitalize">All 9 (Current) courses will be added to your account:</h6>
      <ol>
        {allCourses.length > 0 && allCourses.map((course, index) => (
          <li key={index}><input type="checkbox" value={course.value} checked={true} id={course.value} /> <label htmlFor={course.value}>{course.label}</label></li>
        ))}
      </ol>
    </div>
  )
}