import React from "react";
import { Link } from "react-router-dom"

export default function TestResult({ score, questions, quizName }) {
  return (
    <div className="text-center">
      <h3 className={`${score >= Math.floor(questions.length / 2) ? 'text-success' : 'text-danger'}`}>Your Score is {score}/{questions.length}</h3>
      {(score >= Math.floor(questions.length / 2)) ? (
        <p className="text-center h3 text-success">
          <i className="fa fa-check fs-100" />
          <br />
          You Are Qualified<br />
          <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=+918328664336&text=Hello VM Training Team, I have qualified ${quizName}. How to proceed further?`}><button className="btn btn-primary">Contact Admin To Buy The Course.</button></a>
        </p>
      ) : (
        <div className="bg-white m-lg-2 p-lg-3 text-dark rounded">
          <p className="text-center h3 text-warning">
            Sorry, You Are Not Qualified<br />
            <i className="fa fa-times fs-100" />
          </p>
          <div className="bg-dark rounded p-3 text-white">
            Check out our courses to upgrade your skills!<br />
            <Link to="/html5"><button className="mbw-100 btn btn-primary">HTML 5</button></Link> &nbsp;
            <Link to="/javascript"><button className="mbw-100 btn btn-primary">JavaScript</button></Link> &nbsp;
            <Link to="/reactredux"><button className="mbw-100 btn btn-primary">React</button></Link> &nbsp;
            <Link to="/nodejs"><button className="mbw-100 btn btn-primary">NodeJS</button></Link>&nbsp;
            <Link to="/mernstack"><button className="mbw-100 btn btn-primary">MERN Stack</button></Link>
          </div>
        </div>
      )}
    </div>
  )
}
