import React from 'react'
import { Link } from 'react-router-dom'

export default function OtherCourses({ courses }) {
  return (
    <div className="mt-4 mb-4 bg-light border p-2 rounded">
      <h3 className="text-warning"><i className="fa fa-laptop" /> Related Courses:</h3>

      <div className="row justify-content-center align-items-center text-center">
        {courses.map(({ isNew = true, courselogo, title, demolink, courselink, price, discount }, index) => (
          <div className="col-md-3 col-xs-12 m-3 border rounded shadow bg-dark text-white " key={index}>
            {isNew && <img src="/images/new.gif" className="new-image2" alt="new pic" />}
            <Link to={`/${courselink}`}><img src={`/images/${courselogo}.png`} className="course-image w-100" alt="Course pic" /></Link>
            {/*<h5 className='pb-2 pt-2'>{title}</h5>*/}
            <div className="d-flex flex-wrap flex-row justify-content-between mb-2 mt-2">
              <a
                href={`${demolink}`}
                target="_blank"
                rel="noreferrer"
                className="text-center btn btn-outline-primary text-white">
                <i className="fa fa-youtube-play text-danger" /> DEMO
              </a>
              <button className="text-center btn btn-outline-primary">
                <Link to={`/purchase/${courselink}`} className="text-decoration-none text-white">
                  <i className="fa fa-rupee" /> {(parseInt(price) - ((parseInt(price) / 100) * discount)).toLocaleString('en-IN')}
                </Link>
              </button>
              <button className="text-center btn btn-outline-primary">
                <Link to={`/purchase/${courselink}`} className="text-decoration-none text-white">
                  BUY NOW
                </Link>
              </button>
            </div>

            <Link to={`/${courselink}`}>
              <button className="text-center btn btn-primary w-100 mb-2"><i className="fa fa-book" /> View Course Details</button>
            </Link>
          </div>
        ))}
        <div className="col-md-2">
          <Link to="/courses"><button className="btn btn-primary" style={{ borderRadius: 100 }}>More Courses <i className="fa fa-angle-right" /></button></Link>
        </div>
      </div>
    </div>
  )
}
