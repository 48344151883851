import React, { lazy } from "react";
import SideMenu from './SideMenu';
import { Switch } from "react-router-dom";
import PublicRoute from "../../common/PublicRoute";
const Leaderboard = lazy(() => import("./Leaderboard"));
const TalentDashboard = lazy(() => import("./TalentDashboard"));
const Tasks = lazy(() => import("./Tasks"));
const ViewTaskPage = lazy(() => import("./ViewTaskPage"));

export default function TalentRoutes() {
  return (
    <div className="container-fluid bg-white">
      <div className="row">
        <SideMenu />
        <div className="col-sm p-3 min-vh-100">
          <Switch>
            <PublicRoute
              path="/talent"
              component={TalentDashboard}
              exact
            />
            <PublicRoute
              path="/talent/leaderboard"
              component={Leaderboard}
              exact
            />
            <PublicRoute
              path="/talent/tasks/:id"
              component={ViewTaskPage}
              exact
            />
            <PublicRoute
              path="/talent/tasks"
              component={Tasks}
              exact
            />
          </Switch>
        </div>
      </div>
    </div>
  )
}
