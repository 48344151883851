import React, { useEffect, useState } from 'react'
import { api, AuthEmail, AuthToken, baseURL, LoadingIndicator } from '../../utilities'
import {useHistory,useLocation} from 'react-router-dom'
import OtpInput from 'react-otp-input';
import "../../App.css";

export default function ActivateCTVSignIn() {
  const isLoggedIn = AuthToken !== null;
  const history=useHistory();
  const [tvCode, setTVCode] = useState('');
  const [loading,setLoading]=useState(false);
  const [error,setError]=useState('');
  const [successMsg,setSuccessMsg]=useState('');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tvcodeParam = queryParams.get('tvcode');

  const handleSignIn=()=>{
    // Step-1: CTV - sign in page is loaded, generate random tv code. - Ex: ABCDE
    // Step-2: VM Training - Enter the tv code in /activate page. - Store in database in email user and verify as false - saveTVCode api - verify false - after 5 seconds check with verify is true or not.
    // Step-3: CTV - call the get verification api for every 1 second to compare the tvcode with database tv code and verify field is true or not.
    // 1.If verify field is true - login successful.
    // 2.Once login successful in ctv app - reset verify field to false and tv code to empty in database.

    if(tvCode.length===5){
      setLoading(true);
      setError("")
      setSuccessMsg("");
    let user = api(baseURL + "/saveTVCode", "POST", { "content-type": "application/json",Authorization: AuthToken }, { email:AuthEmail,tvCode });
    user
      .then((data) => {
        if (data.success) {
            setLoading(false);
            setSuccessMsg("Please Check TV");
            setTimeout(() => {
              setSuccessMsg("");
              setTVCode("");
            }, 3000);
        } else {
          setError("Invalid TV Code. Try Again");
          setLoading(false);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          setError("Network Error!")
        setLoading(false);
        },3000);
      });
    } else{
      setError("Enter Valid TV Code");
    }
  }

  useEffect(()=>{
    if(!isLoggedIn){
      history.replace("/login?backlog=/activate");
    }
  },[isLoggedIn])

  useEffect(()=>{
    if(tvcodeParam){
    setTVCode(tvcodeParam.toUpperCase());
    }
  },[tvcodeParam])

  return (
    <div className='text-center mt-5 pt-5 custom-bg p-2 container rounded text-white'>
      <h1>Enter 5 Digit TV Code</h1>
      <OtpInput
      value={tvCode}
      onChange={(text)=>{
        if(error){
        setError("");
        }
        setTVCode(text.toUpperCase());
      }}
      numInputs={5}
      renderSeparator={<span></span>}
      shouldAutoFocus
      containerStyle="p-2 m-2 justify-content-center"
      inputStyle={{width:60,height:60,fontSize:32,margin:10,padding:5,borderRadius:5,border:"2px solid lightgray"}}
      renderInput={(props) => <input {...props} />}
    />
    {successMsg && <p className='text-white'>{successMsg}</p>}
    {error && <p className='text-warning'>{error}</p>}

    <button className='btn btn-dark' disabled={loading} onClick={handleSignIn}> {loading?<>Confirming...<LoadingIndicator/> </>:<><i className='fa fa-check-circle-o'/> Confirm</>}</button>
    </div>
  )
}
