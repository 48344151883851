import React from "react";
import InstallApp from "../components/InstallApp";
import Footer from "./Footer";

export default function TermsAndConditions() {
  return (
    <div className="container-fluid custom-bg2">
      <InstallApp />
      <h1 className="mb-4 pt-4 text-white text-center">Terms and Conditions</h1>
      <p className="text-white p-5 text-left">
        <br />
        We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this <a href="/privacy-policy" className="text-decoration-none">Privacy Policy</a>, the terms used in this <a href="/privacy-policy" className="text-decoration-none">Privacy Policy</a> have the same meanings as in our Terms and Conditions.<br /><br />
</p>

<Footer/>
    </div>
  );
}
