import React, { useState, useEffect } from "react";
import moment from "moment";
import { api, baseURL } from "../../utilities";
import Test from "./Test";
import QuizIntro from './QuizIntro';
import Countdown from "./Countdown";
import LoadingQuiz from "./LoadingQuiz";
import UserCredentials from "./UserCredentials";
import { addToStorage } from "../../utilities/localStorage";
import { startedTime } from "../../redux/constants/courseConstants";
export default function OnlineTest({ quizName, quizType }) {
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(false);
  const [error, setError] = useState("");
  const [remainingTime, setRemaining] = useState("");
  const [count, setCount] = useState(null);
  const [questions, setQuestions] = useState([])
  const [startDate, setstartDate] = useState(localStorage.getItem(startedTime) != null ? localStorage.getItem(startedTime) : null)

  //check whether the existing user has already written test or not.
  useEffect(() => {
    setLoading(true);
    let testEmail = localStorage.getItem("testEmail") != null ? localStorage.getItem("testEmail") : null;
    if (testEmail != null) {
      api(baseURL + '/checkTestUser', 'POST', { "content-type": "application/json" }, {
        quizName,
        quizType,
        testEmail
      })
        .then(response => {
          if (response.success) {
            api(baseURL + '/getAllQuestions', 'POST', { "content-type": "application/json" }, {
              quizName,
              quizType
            })
              .then(response => {
                if (response.success) {
                  setQuestions(response.data);
                  setLoading(false);
                } else {
                  setLoading(false);
                  setError("No Test Available!");
                }
              })
              .catch(error => {
                setError("Network Error!");
                setLoading(false);
              })
            setLoading(false);
          } else {
            setLoading(false);
            setError("Test Already Submitted!");
          }
        })
        .catch(error => {
          setError("Network Error!");
          setLoading(false);
        })
    } else {
      setLoading(false);
      setError("No Email");
    }
  }, [])

  //start the test.
  console.log("count",count);
  useEffect(() => {
    let xInterval = setInterval(() => {
      if (count === 0) {
        clearInterval(xInterval);
      } else if (count > 0) {
        setCount(() => count - 1);
      }
      if (count === 1) {
        const countDownDate = new Date(moment(new Date()).add(10, 'minutes').add(1, 'second')).getTime();
        addToStorage(startedTime,countDownDate);
        setstartDate(localStorage.getItem(startedTime));
      }
    }, 1000)
    return () => {
      clearInterval(xInterval);
    }
  }, [count])

  //Start the timer
  useEffect(
    () => {
      if (startDate != null) {
        //put the deadline here
        let countDownDate = localStorage.getItem(startedTime) != null ? localStorage.getItem(startedTime) : new Date();
        // Update the count down every 1 second
        var x = setInterval(() => {
          // Get todays date and time
          var now = new Date().getTime();

          // Find the distance between now an the count down date
          var distance = countDownDate - now;

          // Time calculations for days, hours, minutes and seconds
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);
          let mm = minutes < 10 ? "0" + minutes + "M " : minutes + "M ";
          let ss = seconds < 10 ? "0" + seconds + "S " : seconds + "S ";
          let remaining = mm + " " + ss;
          //let remaining = mm + " " + ss;
          if (distance < 0) {
            clearInterval(x);
            remaining = "EXPIRED";
          }
          setRemaining(remaining);
        }, 1000);

        return ()=>{
          clearInterval(x);
        }
      }
    },
    [startDate],
  );
  const startTest = () => {
    setStart(true);
    setCount(5);
  }
  return (
    <div className="custom-bg2">
      <h1 className="text-center text-white mt-4">
        <i className="fa fa-laptop" /><b className="text-warning"> VM</b> ONLINE TEST <br />
        <br />
        <i className="h5">Username:  <b className="text-warning">Your Email Address</b>, Password: <b className="text-warning">TestUser@123</b></i>
      </h1>
      {loading && <LoadingQuiz />}
      {error === "No Email" && <UserCredentials />}
      {error !== "No Email" && !loading && !start && <QuizIntro start={start} loading={loading} startTest={startTest} error={error} quizName={quizName} questions={questions} />}
      {count > 0 && <Countdown start={start} count={count} loading={loading} />}
      {count === 0 && <Test start={start} loading={loading} remainingTime={remainingTime} questions={questions} quizName={quizName} quizType={quizType} />}
    </div>
  );
}
