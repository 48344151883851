import { logoutUser } from './validations'
// api call for any kind of methods.
export const api = (url, method = "GET", headers = {}, body = {}) => {
  return new Promise((resolve, reject) => {
    if (method === "POST" || method === "PUT") {
      fetch(url, {
        method,
        headers,
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Unauthorized") {
            logoutUser();
            //localStorage.clear();
            //window.location.href = "/login"
          } else {
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      fetch(url, {
        method,
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Unauthorized") {
            logoutUser();
            //localStorage.clear();
            //window.location.href = "/login"
          } else {
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
};
