import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import useCourses, { filterCourseByCategory, openPlayer } from "../components/VMVideoPlayer/useCourses";
import { api } from "../utilities/api";
import { baseURL } from "../utilities/baseurl";
import { AuthEmail, AuthToken, logoutUser } from "../utilities/validations";
import useNetworkStatus from '../hooks/useNetworkStatus'
import { eveningBatchUsers, internUsers, recordedUsers, regularBatchUsers } from "../components/auth/Login";

export default function NavbarVM() {
  const [feedback, setFeedback] = useState(false);
  const [, setLoading] = useState(false);
  const { courses, loading } = useCourses();
  const { isOnline, networkMessage } = useNetworkStatus();
  const history = useHistory();
  useEffect(() => {
    if (AuthToken != null) {
      api(
        baseURL + "/getfeedbacks",
        "POST",
        { "content-type": "application/json", Authorization: AuthToken },
        { email: AuthEmail },
      )
        .then((data) => {
          if (data.success) {
            setFeedback(true);
          } else {
            setFeedback(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [])


  const isLiveUser = (recordedUsers.includes(AuthEmail) || regularBatchUsers.includes(AuthEmail) || eveningBatchUsers.includes(AuthEmail));
  const liveDashboardLink=recordedUsers.includes(AuthEmail)?'/vmlive/react-2024/recorded':(regularBatchUsers.includes(AuthEmail)?'/vmlive/react-2024-regular':(eveningBatchUsers.includes(AuthEmail)?'/vmlive/react-2024-evening':'/vm/vmcourses'));
  const isInternUser = internUsers.includes(AuthEmail);
  return (
    <nav className="navbar navbar-expand-lg custom-bg2 navbar-dark bg-primary sticky-top">
      <div className="container-fluid">
        <Link to="/vm/vmcourses" className='navbar-brand'>
          <h2>
            <b style={{ color: "orange" }}><i className="fa fa-laptop text-warning" /> VM</b> Online Training
            <sub className="d-block">4.0</sub>
          </h2>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-lg-center align-items-lg-center">
            <NavLink to="/allcourses" className="nav-link" activeClassName="text-white active-course">
              <li className="nav-item">Home</li>
            </NavLink>
            <NavLink to="/vm/vmcourses" className="nav-link" activeClassName="text-white active-course">
              <li className="nav-item">Dashboard <span className="badge bg-warning badge-pill">New</span></li>
            </NavLink>
            <NavLink to="/courses" className="nav-link" activeClassName="text-white active-course">
              <li className="nav-item">All Courses</li>
            </NavLink>
            <NavLink to="/html5" className="nav-link" activeClassName="text-white active-course">
              <li className="nav-item">
                HTML
              </li>
            </NavLink>
            <NavLink to="/css3" className="nav-link" activeClassName="text-white active-course">
              <li className="nav-item">
                CSS
              </li>
            </NavLink>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                JavaScript
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <NavLink to="/javascript" className="dropdown-item">
                  JavaScript
                </NavLink>
                <NavLink className="dropdown-item" to="/jsandmernstack">
                  JS and MERN Stack
                </NavLink>
                <NavLink className="dropdown-item" to="/jsandreactjs">
                  JS and ReactJS
                </NavLink>
                <NavLink className="dropdown-item" to="/jsandnodejs">
                  JS and NodeJS
                </NavLink>
                <NavLink className="dropdown-item" to="/javascriptsmart">
                  JS Smart Course <span className="badge bg-primary badge-pill">New</span>
                </NavLink>
                <NavLink to="/webdeveloper" className="dropdown-item">
                  Web Development <span className="badge bg-primary badge-pill">New</span>
                </NavLink>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                React
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <NavLink to="/reactredux" className="dropdown-item">
                  <span className="nav-item">React and Redux</span>
                </NavLink>
                <NavLink className="dropdown-item" to="/reactnative">
                  React Native <span className="badge bg-warning badge-pill">New</span>
                </NavLink>
                <NavLink className="dropdown-item" to="/reactandreactnative">
                  React and React Native <span className="badge bg-warning badge-pill">New</span>
                </NavLink>
                <NavLink className="dropdown-item" to="/mernstack">
                  MERN Stack Course <span className="badge bg-warning badge-pill">New</span>
                </NavLink>
                <NavLink className="dropdown-item" to="/projects">
                  MERN Stack Project <span className="badge bg-warning badge-pill">New</span>
                </NavLink>
              </div>
            </li>

            <NavLink to="/nodejs" className="nav-link" activeClassName="text-white active-course">
              <li className="nav-item">
                NodeJS
              </li>
            </NavLink>
            {/* <NavLink to="/html5" className="nav-link" activeClassName="text-white active-course">
              <li className="nav-item">
                Blog <span className="badge bg-warning badge-pill">New</span>
              </li>
            </NavLink> */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                More
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <NavLink to="/webdeveloper" className="dropdown-item">
                  Web Development <span className="badge bg-warning badge-pill">New</span>
                </NavLink>
                <NavLink className="dropdown-item" to="/mernstack">
                  MERN Stack Course <span className="badge bg-warning badge-pill">New</span>
                </NavLink>
                <NavLink to="/allcourses" className="dropdown-item">
                  All Courses Discount <span className="badge bg-warning badge-pill">New</span>
                </NavLink>
                <NavLink to="/onlinetests" className="dropdown-item">
                  Online Tests
                </NavLink>
                {/*<NavLink to="/jobs" className="dropdown-item">
                  Jobs  <span className="badge bg-dark badge-pill">New</span>
                </NavLink>*/}
                {/*<NavLink to="/fullstack" className="dropdown-item">
                  Full Stack Developer <span className="badge bg-warning badge-pill">New</span>
                </NavLink>*/}
                {/*<NavLink className="dropdown-item" to="/reactnative">
                  React Native <span className="badge bg-warning badge-pill">Coming Soon!</span>
                </NavLink>
                <NavLink className="dropdown-item" to="/angular">
                  Angular <span className="badge bg-warning badge-pill">Coming Soon!</span>
                </NavLink>*/}
              </div>
            </li>
          </ul>

          <form className="d-flex flex-wrap justify-content-between">
            {AuthToken !== null && <div className="dropdown me-2">
              <button className="btn btn-warning btn-sm dropdown-toggle " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                Select Course
              </button>
              <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                {courses.length > 0 && filterCourseByCategory(courses, "all").map((course, index) => (
                  <li key={index} className="dropdown-item cursor-pointer" onClick={() => openPlayer(course, history)}>{course.name}</li>
                ))}

                {/*<li className="dropdown-item cursor-pointer" onClick={() => {
                history.push("/vm/vmvideoplayer/allcourses/1");
              }}>Play All Courses</li>*/}
              </ul>
            </div>}
            {AuthToken !== null && (
              <li className="list-style-none nav-item dropdown user-dropdown1" style={{ listStyleType: "none" }}>
                <button
                  className="btn btn-outline-primary btn-sm me-2 dropdown-toggle user-dropdown1"
                  id="navbarDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <i className="fa fa-user" /> {localStorage.getItem("username")}
                </button>
                <div className="dropdown-menu user-dropdown user-dropdown1 dropdown-menu-dark dropdown-menu-end" aria-labelledby="navbarDropdown">
                {isInternUser && <NavLink to={"/talent"} className="dropdown-item">
                💻 Talent Dashboard
                  </NavLink>}
                {isLiveUser && <NavLink to={liveDashboardLink} className="dropdown-item">
                  🔴Live Training Dashboard
                  </NavLink>}
                  <NavLink to="/vm/vmcourses" className="dropdown-item">
                    Dashboard
                  </NavLink>
                  {/*<Link to="/notifications" className="dropdown-item">Notifications</Link>*/}
                  <NavLink to="/changepassword" className="dropdown-item">
                    Change Password
                  </NavLink>
                  {!feedback && <NavLink to="/feedback" className="dropdown-item">
                    Feedback
                  </NavLink>}
                  <NavLink to="/deleteaccount" className="dropdown-item">
                    Delete Account
                  </NavLink>
                  {/* <a className="dropdown-item" href="/#">
                  Edit Profile
                </a> */}
                  {/* <div className="dropdown-divider" /> */}
                  <hr />
                  <button
                    type="button"
                    className="dropdown-item cursor-pointer bg-danger text-white"
                    onClick={logoutUser}>
                    <i className="fa fa-power-off" /> Logout
                  </button>
                </div>
              </li>
            )}
            {AuthToken === null && (
              <Link to="/login">
                <button className="btn btn-outline-warning btn-sm me-2 navOption">
                  {" "}
                  Login <i className="fa fa-user" title="Login" />
                </button>
              </Link>
            )}
          </form>
        </div>
      </div>
      <div className="navbar-expand-lg custom-bg2 navbar-dark bg-danger fixed-bottom text-center">
        {!isOnline ? networkMessage : networkMessage}
      </div>
    </nav>
  )
}
