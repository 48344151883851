import React, { useState } from 'react';
import './Accordion.css';
import LiveSessionArea from './LiveSessionArea';
import { sessionSchedules, sessionSchedulesEvening } from './constants';
import moment from 'moment';

const Accordion = ({ progress, isRegular, sessionTime, currentClass, joinLiveClass, scheduledQuestions, linkStatus,courseType }) => {
  const [activeIndex, setActiveIndex] = useState(currentClass);
  const liveIndex = currentClass;

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const sessions = (isRegular ? sessionSchedules : sessionSchedulesEvening);

  return (
    <div className="rounded">
      {progress>0 && <div className='bg-white p-2 mb-2 rounded'>
        <div className="progress">
          <div className="progress-bar progress-bar-striped active" role="progressbar"
            aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{ width: progress + "%" }} />
            {Math.round(progress)===100?'✅':Math.round(progress)+'%'}
        </div>
      </div>}
      {sessions.map((session, index) => {
        const scheduledLinks = scheduledQuestions.filter(question => parseInt(question.options[0].value) === index + 1);
        const isTodaySession = moment(session.schedule).format('YYYY/MM/DD') === sessionTime || index === liveIndex;
        return (
          <div key={index} className={`accordion-item ${isTodaySession ? 'bg-active' : ''}`}>
            {index === 0 && <h1 className='m-2 text-center'>Week-1</h1>}
            {index === 6 && <h1 className='m-2 text-center'>Week-2</h1>}
            {index === 12 && <h1 className='m-2 text-center'>Week-3</h1>}
            <div className={`accordion-header`} onClick={() => toggleAccordion(index)}>
              <div className={`accordion-date-time ${isTodaySession ? 'font-weight-bold text-success' : ''}`}>
                <span className="accordion-date">{index < liveIndex ? '✅' : ''}<b className='popup-animation-infinite'>{isTodaySession ? '🔴' : ''}</b>
                  {index > liveIndex ? '🗓️' : ''}{session.date}</span> | <span className="accordion-time">{session.time}</span>
              </div>
              <div className="accordion-title">{session.title} {isTodaySession ? '( Live )' : ''}</div>
              <span className="accordion-icon">
                <i className={`fa fa-angle-double-${activeIndex === index ? 'up' : 'down'}`} />
                {index % 2 === 0 ? <small>{index === 0 ? 1100 : 1000} XP</small> : <small>2000 XP</small>}
              </span>
            </div>
            {activeIndex === index && (
              <div className="accordion-content">
                <LiveSessionArea
                  sessionTime={session.schedule}
                  topics={session.topics}
                  isLive={isTodaySession}
                  isCompleted={index < liveIndex}
                  title={session.title}
                  joinLiveClass={joinLiveClass}
                  scheduledQuestions={scheduledLinks}
                  linkStatus={linkStatus}
                  courseType={courseType}
                   />
              </div>
            )}
          </div>
        )
      })}
    </div>
  );
};

export default Accordion;
