import React, { useState, useEffect } from "react";
import moment from "moment";
import Footer from "../../common/Footer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { LoadingIndicator } from "../../common/Loader";
import { api, baseURL, Validator } from "../../utilities";
import { Link } from "react-router-dom";
export default function Jobs({ expiredDate, category, title, jobImage, role }) {
  const [useremail, setUserEmail] = useState("");
  const [resume, setResume] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [error, setError] = useState("");
  const [remainingTime, setRemaining] = useState("");
  const [days, setDays] = useState("");
  const [, forceUpdate] = useState(false);
  const validator = Validator();

  const postDetailsAdmin = () => {
    setLoading(true);
    setError("");
    const data = new FormData();
    data.append("file", resume);
    data.append("upload_preset", "ma7nge92");
    data.append("cloud_name", "dbqm9svvp");
    setLoading(true);
    fetch("https://api.cloudinary.com/v1_1/dbqm9svvp/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((response) => {
        if (!response.error) {
          let user = api(baseURL + "/sendResume", "POST", { "content-type": "application/json" }, { email: useremail, resumeurl: response.url, role });
          user.then(result => {
            if (result.success) {
              setLoading(false);
              setStatus(true);
              setLoading(false);
            } else {
              setError(result.message);
              setLoading(false);
              setStatus(false);
            }
          })
        } else {
          setStatus(false);
          setLoading(false);
          setError("Invalid File Extension. Error Code: C101. Please copy this error and contact admin for solution!");
        }
      })
      .catch((err) => {
        setStatus(false);
        setLoading(false);
        setError("Network Error!");
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      let user = api(baseURL + "/checkUser", "POST", { "content-type": "application/json" }, { email: useremail, role });
      user.then(res => {
        if (res.success) {
          postDetailsAdmin();
        } else {
          setStatus(false);
          setError(res.message);
        }
      })
        .catch((err) => {
          setStatus(false);
          setError("Network Error!");
        });
    } else {
      validator.current.showMessages();
      forceUpdate(true);
    }
  }

  useEffect(
    () => {
      var countDownDate = new Date(expiredDate).getTime();
      // Update the count down every 1 second
      var x = setInterval(() => {
        // Get todays date and time
        var now = new Date().getTime();

        // Find the distance between now an the count down date
        var distance = countDownDate - now;
        if (moment(countDownDate).diff(now, "hours") >= 24) {
          let days = moment(countDownDate).diff(now, "days");
          setDays(days);
        }

        // Time calculations for days, hours, minutes and seconds
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        let hh = hours < 10 ? "0" + hours + "H " : hours + "H ";
        let mm = minutes < 10 ? "0" + minutes + "M " : minutes + "M ";
        let ss = seconds < 10 ? "0" + seconds + "S " : seconds + "S ";
        let remaining = hh + " " + mm + " " + ss;
        if (distance < 0) {
          clearInterval(x);
          remaining = "EXPIRED";
        }
        setRemaining(remaining);
      }, 1000);
    },
    [remainingTime],
  );
  return (
    <div>
      {remainingTime !== "EXPIRED" && (
        <p
          style={{ fontSize: 25 }}
          className="text-center custom-bg2 text-white align-items-center d-flex justify-content-center flex-wrap">
          APPLICATION WILL BE CLOSED IN &nbsp;
          <b style={{ fontSize: 25, color: "black" }} className="text-warning">
            {days ? days + (days > 1 ? " Days" : " Day") + ", " : ""}
            {remainingTime}
          </b>
        </p>
      )}
      {remainingTime === "EXPIRED" && (
        <p
          style={{ fontSize: 25 }}
          className="text-center bg-secondary text-white align-items-center d-flex justify-content-center flex-wrap">
          APPLICATION HAS BEEN CLOSED. STAY TUNED FOR MORE UPCOMING JOB OPPORTUNITIES
        </p>
      )}
      <div className="text-center card container p-4 mt-4 mb-4">
        <b className="text-primary h1">{title}<br /></b>
        <div className="row m-0 p-0">
          <div className="col-md-6 col-xs-12 col-sm-12">
            <img src={jobImage} alt="Job Pic" className="w-100 p-1" />
          </div>
          <div className={`h250 col-md-5 col-xs-12 col-sm-12 border p-5 rounded bg-${(!loading && status) ? 'success' : 'white'} text-white `}>
            {remainingTime !== "EXPIRED" ? <form onSubmit={handleSubmit}>
              {!status && <i className="fa fa-suitcase text-primary" style={{ fontSize: 50 }} />}
              {status && <i className="fa fa-check text-white" style={{ fontSize: 50 }} />}
              <br />
              {!status && <><p className="text-dark m-2" style={{ textAlign: "left" }}>Email Address:</p><input type="email" placeholder="Enter your email address" className="form-control m-2" value={useremail} onChange={(e) => setUserEmail(e.target.value)} /></>}
              {validator.current.message("Email ", useremail, "required|email")}
              {!status && <><p className="text-dark m-2" style={{ textAlign: "left" }}>Upload Resume:</p><input type="file" id="resume" className="form-control m-2" onChange={(e) => setResume(e.target.files[0])} name="resume" /></>}
              {!status && <span className="text-warning">.pdf extension only available to upload.</span>}
              <br />
              {validator.current.message("Resume ", resume, "required")}
              {!status && <button className="cursor-pointer btn btn-primary m-2" type="submit" disabled={loading}>{loading ? "Sending" : "Send"} Resume
                {loading && <LoadingIndicator size={"1"} color={"white"} />}</button>}

              {!status && <p className="text-danger">{error && error.length > 0 ? error : ""}</p>}
              {!loading && status && <p>Your Resume Sent Successfully! <br />We will get back to you once your resume is shortlisted.<br /><br /> All The Best <i className="fa fa-thumbs-up" /><br /><br />
                <div className="bg-white m-2 p-3 text-dark rounded">
                  Check out our courses to upgrade your skills!<br />
                  <Link to="/html5"><button className="btn btn-primary">HTML 5</button></Link> &nbsp;
                  <Link to="/javascript"><button className="btn btn-primary">JavaScript</button></Link> &nbsp;
                  <Link to="/reactredux"><button className="btn btn-primary">React</button></Link> &nbsp;
                  <Link to="/nodejs"><button className="btn btn-primary">NodeJS</button></Link>&nbsp;
                  <Link to="/mernstack"><button className="btn btn-primary">MERN Stack</button></Link>
                </div>
              </p>}
            </form> : <h2 className="text-danger">-Registrations Closed-<br /><br />Stay Tuned For More Upcoming Job Opportunities!<br /><br />
              <a href="https://youtube.com/channel/UC1MgMO9NkzCyz1YeAGp8SnQ/community" rel="noreferrer" target="_blank"><i className="fa fa-youtube text-danger" /></a> &nbsp;&nbsp;
              {/*<a href="https://www.linkedin.com/in/venkatesh-mogili-bb4073119/" target="_blank"><i className="fa fa-linkedin" /></a> &nbsp;&nbsp;*/}
              <a href="https://www.facebook.com/telugutechplus3/" rel="noreferrer" target="_blank"><i className="fa fa-facebook text-primary" /></a> &nbsp;&nbsp;
            </h2>}
          </div>
        </div>
      </div>
    </div>
  );
}
