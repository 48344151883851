import React from 'react'

export default function VMCoins() {
  return (
    <div>
      <div className='text-center mt-5 pt-5 bg-primary p-5 m-5 text-white rounded'>
        <h1>VM Coins</h1>
        <h3>1000</h3>
      </div>
      <p>1 VM Coin = Rs.10/-</p>
    </div>
  )
}
