import React, { useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import { WRONG_EMAIL, WRONG_PASSWORD } from "./constants";
import moment from "moment";
import { api } from "./api";
import { baseURL } from "./baseurl";
export const Validator = () => {
  return useRef(
    new SimpleReactValidator({
      validators: {
        email: {
          message: WRONG_EMAIL,
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/);
          },
          messageReplace: (message, params) => message.replace("", this.helpers.toSentence(params)),
          required: true,
        },
        password: {
          message: WRONG_PASSWORD,
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{4,16}$/);
          },
          messageReplace: (message, params) => message.replace("", this.helpers.toSentence(params)),
          required: true,
        },
        element: (message) => <span className="error-message">{message}</span>,
      },
    }),
  );
};
export const AuthToken = localStorage.getItem("token") !== null ? localStorage.getItem("token") : null;
export const AuthUserId = localStorage.getItem("id") !== null ? localStorage.getItem("id") : null;
export const AuthUsername = localStorage.getItem("username") !== null ? localStorage.getItem("username") : null;
export const AuthEmail = localStorage.getItem("email") !== null ? localStorage.getItem("email") : null;
export const registrationClosed = true; //true || false;

export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYYY");
  return buildDate;
};

export const logoutUser = () => {
  //if (AuthToken) {
  let user = api(
    baseURL + "/logout",
    "POST",
    { "content-type": "application/json", Authorization: AuthToken },
    { email: AuthEmail },
  );
  user
    .then((data) => {
      if (data.success || data.message === "Unauthorized") {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.log("Something went wrong");
      }
    })
    .catch((error) => {
      console.log("Network Error");
    });
  //}
};

export const handleLink = (url, target = "_blank") => {
  if (url) {
    window.open(url, target);
  } else {
    //alert("Invalid URL");
  }
}