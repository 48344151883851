import React from 'react'
import {Link} from 'react-router-dom'
import Slider from 'react-slick';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import moment from 'moment';
import "./styles.css";
import { addToStorage } from '../../utilities/localStorage';
import { PopupKeys } from '../../redux/constants/courseConstants';
import { reactExpireDate } from '../../utilities';

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 6
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

export function TimerCountDown() {
  const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
const date = moment(reactExpireDate);
const unixTimestamp = date.unix();
const remainingTime = unixTimestamp - stratTime; // calculate the remaining time until the date

const daySeconds = 24 * 60 * 60;
const days = Math.ceil(remainingTime / daySeconds); // calculate the number of days until the date
const daysDuration = days * daySeconds;

  return (
    <div className="App custom-bg2">
      <CountdownCircleTimer
        {...timerProps}
        colors="red"
        duration={daysDuration}
        initialRemainingTime={remainingTime}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("days", getTimeDays(daysDuration - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="magenta"
        duration={daySeconds}
        initialRemainingTime={remainingTime % daySeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > hourSeconds
        })}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("hours", getTimeHours(daySeconds - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="orange"
        duration={hourSeconds}
        initialRemainingTime={remainingTime % hourSeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds
        })}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="tomato"
        duration={minuteSeconds}
        initialRemainingTime={remainingTime % minuteSeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > 0
        })}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("seconds", getTimeSeconds(elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
    </div>
  );
}

const SlidesData=[
  {
    thumbnail:"/images/react/react-training-next.png",
    caption:""
  },
  {
    thumbnail:"/images/vmonline2/slide2.png",
    caption:"2.Code While Watching!"
  },
  {
    thumbnail:"/images/vmonline2/slide3.png",
    caption:"3.Quick Topic Selection!"
  },
  {
    thumbnail:"/images/vmonline2/slide4.png",
    caption:"4.Check Platform Before Purchasing as Guest User!"
  },
  {
    thumbnail:"/images/vmonline2/slide5.png",
    caption:"5.Search Any Topic In Course and Learn Faster!"
  },
]
export default function FlashNewsPopup({setShowPopup3,settings,history}) {

  const handleShowPopup3=()=>{
    addToStorage(PopupKeys.showPopup3,"true");
    setShowPopup3(false);
    history.push("/react-2024");
  }

  return (
    <div className="text-decoration-none text-white m-5" >
          <Slider {...settings}>
            {SlidesData.map((slideItem,index)=>(

            <div className={`d-flex flex-column justify-content-center align-items-center cursor-pointer ${index===0?'w-100':''}`} onClick={() => {
              handleShowPopup3();
          }}>
              <img src={slideItem.thumbnail} alt="indian flag" className="slideImage shadow bg-white p-2 rounded" />
              {slideItem.caption && <h4>{slideItem.caption}</h4>}
            </div>
            ))}
            <div className="mt-5 pt-5 d-flex flex-column justify-content-center align-items-center">
              <i className="fa fa-laptop text-warning display-1" />
              <Link to='/enroll/react-2024' className="h1 text-decoration-none text-info">Enroll Now!</Link>
            </div>
          </Slider>
          <div className="cursor-pointer" onClick={() => {
            handleShowPopup3();
          }}>
            {/*<h1 className="text-warning">&lt; VM Online Learning 2.0 /&gt;</h1>*/}
            {/* <h1 className="text-warning">&lt; Independence Day Offer /&gt;</h1> */}
            <h1 className="text-warning">&lt; 👩‍💻 React Live Hands On Training Program 👩‍💻 &gt;</h1>
            <TimerCountDown/>
          </div>
        </div>
  )
}
