import React, { useEffect, useRef, useState } from 'react'
import Accordion from './Accordion';
import QuizPopup from './QuizPopup';
import { api, AuthEmail, AuthToken, baseURL, handleLink } from '../../utilities';
import { useHistory,useParams } from 'react-router-dom';
import { removeFromStorage } from '../../utilities/localStorage';
import { CountDown } from '../../common/CountDown';
import { quizConfig, quizRefreshInterval, sessionSchedules, sessionSchedulesEvening } from './constants';
import Leaderboard from './Leaderboard';
import moment from 'moment';
import "./VMLiveTraining.css"

export default function VMLiveTraining() {
  const history = useHistory();
  const {courseType}=useParams();
  const [, setQuestions] = useState([]);
  const [scheduledQuestions, setScheduledQuestions] = useState([]);
  const [linkStatus, setLinkStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredQuiz, setFilterQuiz] = useState(null);
  const [videoId, setVideoId] = useState('');
  const [timeLeft, setTimeLeft] = useState(3);
  const timerRef = useRef(0);
  const timerInteractionRef = useRef(0);

  const isRegular = window.location.pathname.includes("react-2024-regular");

  const sessionTime = moment(new Date()).format('YYYY/MM/DD');
  const currentSchedules = (isRegular ? sessionSchedules : sessionSchedulesEvening);
  const currentClass = currentSchedules.findIndex(schedule => moment(schedule.schedule).format('YYYY/MM/DD') === sessionTime) + 1 || 19;
  const progress = ((currentClass - 1) / currentSchedules.length) * 100;
  const isAdmin = AuthToken && AuthEmail === quizConfig.adminEmail;
  const [scores, setScores] = useState([]);

  const joinLiveClass = (questionId, link, qType, email) => {
    // call submit test query.
    if(qType==="recorded_class"){
      setVideoId(link.split("https://youtu.be/")[1]);
    } else{
      handleLink(link);
    }
    if (AuthToken && scheduledQuestions && questionId) {
      api(baseURL + '/createTestTraining', 'POST', { "content-type": "application/json", Authorization: AuthToken }, {
        quizName: "React Online Training",
        quizType: "course",
        answers: { question1: 'completed' },
        testEmail: email ? email : AuthEmail,
        score: 1,
        quizCategoryId: scheduledQuestions.length > 0 ? scheduledQuestions[0].quizCategoryId : '',
        questionId,
      })
        .then(response => {
          if (response.success) {
            const currentScores = [...scores];
            const currentUserIndex = currentScores.findIndex(user => user.email === AuthEmail);
            if (qType === "live_class") {
              currentScores[currentUserIndex].score += 500;
            } else if (qType === "prerequisites") {
              currentScores[currentUserIndex].score += 100;
            } else if (qType === "recorded_class") {
              currentScores[currentUserIndex].score += 200;
            } else if (qType === "practice_tasks") {
              currentScores[currentUserIndex].score += 200;
            } else if (qType === "exercise_task") {
              currentScores[currentUserIndex].score += 2000;
            } else if (qType === "source_code") {
              currentScores[currentUserIndex].score += 100;
            } else if (qType === "presentation") {
              currentScores[currentUserIndex].score += 100;
            } else if (qType === "offline_quiz") {
              currentScores[currentUserIndex].score += 100;
            }else if (qType === "doubts") {
              currentScores[currentUserIndex].score += 100;
            }

            setScores(currentScores);
            getTotalResults();
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch(error => console.log(error))
    } else{
      if(courseType){
      history.push("/login?backlog=/vmlive/react-2024/recorded");
      } else{
      history.push("/login?backlog=/vmlive/react-2024-" + (isRegular ? 'regular' : 'evening'));
      }
    }
  }

  const getTotalResults = () => {
    if (AuthToken) {
      api(baseURL + '/getParticipantsTraining', 'POST', { "content-type": "application/json", Authorization: AuthToken }, {
        quizName: "React Online Training",
        testEmail: AuthEmail,
      })
        .then(response => {
          if (response.success) {
            setLinkStatus(response.data);
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch(error => console.log(error))
    } else {
      if(courseType){
        history.push("/login?backlog=/vmlive/react-2024/recorded");
        } else{
        history.push("/login?backlog=/vmlive/react-2024-" + (isRegular ? 'regular' : 'evening'));
        }
    }
  }

  const fetchQuestion = () => {
    if (AuthToken) {
      setLoading(true);
      api(baseURL + '/getQuizQuestion', 'POST', { "content-type": "application/json", Authorization: AuthToken }, {
        quizName: "React Online Training",
        testEmail: AuthEmail
      })
        .then(response => {
          if (response.success) {
            setQuestions(response.data);
            setFilterQuiz(response.data.length > 0 ? response.data[0] : null)
            setLoading(false);
            removeFromStorage("quizCompleted");
          } else {
            setLoading(false);
            console.log("No Test Available!");
            removeFromStorage("quizCompleted");
          }
        })
        .catch(error => {
          console.log("Network Error!");
          setLoading(false);
        })
    } else {
      if(courseType){
        history.push("/login?backlog=/vmlive/react-2024/recorded");
        } else{
        history.push("/login?backlog=/vmlive/react-2024-" + (isRegular ? 'regular' : 'evening'));
        }
    }
  }


  const fetchScheduledQuestions = () => {
    if (AuthToken) {
      api(baseURL + '/getScheduledQuestions', 'POST', { "content-type": "application/json", Authorization: AuthToken }, {
        quizName: "React Online Training"
      })
        .then(response => {
          if (response.success) {
            setScheduledQuestions(response.data);
          } else {
            console.log("No Scheduled Questions Available!");
          }
        })
        .catch(error => {
          console.log("Network Error!");
        })
    } else {
      if(courseType){
        history.push("/login?backlog=/vmlive/react-2024/recorded");
        } else{
        history.push("/login?backlog=/vmlive/react-2024-" + (isRegular ? 'regular' : 'evening'));
        }
    }
  }


  useEffect(() => {
    // call api to fetch questions and scheduled questions.
    fetchScheduledQuestions();
    getTotalResults();

    // call quiz api initially.
    if (isAdmin && localStorage.getItem("sessionClosed")) return;
    fetchQuestion();
  }, [])

  useEffect(() => {
    if (isAdmin && localStorage.getItem("sessionClosed")) return;
    // remove notified later.
    if (filteredQuiz && !localStorage.getItem("notified")) {
      if (timeLeft <= 0) return;
      timerRef.current = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
      if (timeLeft === 3) {
        // TODO: notification sound issue.
        // localStorage.setItem("notified",true);
        const audio = new Audio('/audio/mixkit-software-interface-back-2575.wav');
        audio.play();
      }
      return () => clearInterval(timerRef.current);
    }
  }, [filteredQuiz, timeLeft])

  // Note: Timer for refetching if poll.
  useEffect(() => {
    if (isAdmin && localStorage.getItem("sessionClosed")) return;
    if (filteredQuiz) {
      clearInterval(timerInteractionRef.current);
      return;
    };
    timerInteractionRef.current = setInterval(() => {
      fetchQuestion();
    }, quizRefreshInterval);

    return () => clearInterval(timerInteractionRef.current);
  }, [filteredQuiz]);

  const startNewSession = () => {
    localStorage.removeItem("sessionClosed");
    window.location.reload();
  }

  return AuthToken ? (
    <div className='container-fluid shadow bg-teal'>
      <h1 className="text-center mb-3 pt-3 text-white dashboard-heading">🧑‍💻React Online Training - {courseType?"Recorded":(isRegular ? "Regular" : "Evening")}</h1>
      {isAdmin && <div className='text-center mb-3'>
        <button onClick={() => startNewSession()} className='btn btn-primary'>Start</button>
      </div>}

      <div className='row d-reverse'>
        <div className='col-md-8'>
          <Accordion progress={progress} isRegular={isRegular} sessionTime={sessionTime} currentClass={currentClass - 1} joinLiveClass={joinLiveClass} scheduledQuestions={scheduledQuestions} linkStatus={linkStatus} courseType={courseType}/>
        </div>
        <div className='col-md-4'>
          <Leaderboard filteredQuiz={filteredQuiz} scores={scores} setScores={setScores} isAdmin={isAdmin} isRegular={isRegular || courseType} courseType={courseType} />
        </div>
      </div>
      {!courseType && filteredQuiz && <div>
        {timeLeft >= 1 && !loading ?
          <CountDown timeLeft={timeLeft} /> :
          <QuizPopup question={filteredQuiz} resetQuiz={() => {
            fetchQuestion();
            setFilterQuiz(null);
          }} getTotalResults={getTotalResults} />
        }
      </div>}

      {/* video player */}
      {videoId && <div className={`embed-responsive embed-responsive-16by9 relative infoicon video-player-overlay`}>
      <div className="holder">
        <button className='close-btn' onClick={()=>setVideoId('')}>X</button>
          <div className="frame" id="player">
            <iframe src={`https://www.youtube.com/embed/${videoId}?rel=0&start=${0}&modestbranding=1&showinfo=1&autoplay=1&autohide=2`} className="embed-responsive-item vid col-md-12 col-xs-12 col-sm-12 w-100" title="VM video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </div>
          <div className="bar" id="bottom-layer"></div>
      </div>
      </div>}
    </div>
  ) : null;
}
