import React from 'react';
export default function Quiz({ index, count, questions, current, setCurrent, submitTest, handleChange, setCount }) {
  return (
    <div>
      {count === index && <div className="p-2">
        <h4 className="font-weight-bold">{questions[count].question}</h4>
        <div className="d-flex flex-column justify-content-start align-self-start">
          {questions[count].options.map(({ value, key }, ind) => (
            <div key={ind}>
              <input type="radio" name={"question" + parseInt(index + 1)} value={value} onChange={(e) => handleChange({ target: { value: e.target.value, name: "question" + parseInt(index + 1) } })} id={"option" + index + ind + 1} />
              &nbsp;
              <label htmlFor={"option" + index + ind + 1}>
                {key}
              </label>
            </div>
          ))}
        </div>
        <br />
        <div className="text-center">
          {(count < questions.length - 1) ? (
            <button
              className="btn btn-primary cursor-pointer"
              disabled={!current}
              onClick={() => {
                setCurrent(false);
                setCount(() => count + 1);
              }
              }>
              Next <i className="fa fa-arrow-right" />
            </button>
          ) : (
            <button
              className="btn btn-primary cursor-pointer"
              disabled={!current}
              onClick={() => {
                submitTest()
              }
              }>
              Submit
            </button>
          )}
        </div>
      </div>}
    </div>
  )
}
