import moment from 'moment';
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { setEnrolled, setLectureDetails } from '../../redux/reducers/courseSlice';
import { allCoursesStored, api, AuthToken, AuthUserId, baseURL, courseCategories, logoutUser } from '../../utilities';
import { addToStorage } from '../../utilities/localStorage';
import { CourseModes, react18Version,  reactId,  subjectId, subjectName, subjectVersionLabel } from '../../redux/constants/courseConstants';

export default function useCourses() {
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [courses, setCourses] = useState([]);
  const [remainingCourses, setRemainingCourses] = useState([]);
  const [subjectVersion, setVersion] = useState(18)
  useEffect(() => {
    if (AuthUserId !== null) {
      setLoading(true);
      api(baseURL + "/getCoursesById/" + AuthUserId, "GET", { Authorization: AuthToken }, {})
        .then((data) => {
          if (data.success) {
            setCourses(data.data);
            //unenrolled courses
            let enrolledCourseIds = data.data.map(item => item._id);
            let unenrolledCourses = allCoursesStored.filter((course) => !enrolledCourseIds.includes(course._id) && !course.courseCombo.length && course.courseType !== "Free" && !["60c7106e1527710009ff2ee5","60c7106e1527710009ff2ee6"].includes(course._id));
            setRemainingCourses(unenrolledCourses)
            let subjectid = localStorage.getItem("subjectid");
            let subjectVersion = localStorage.getItem("subjectVersion");
            if (subjectid == null && data.data.length > 0) {
              addToStorage(subjectId,data.data[0]._id);
              addToStorage(subjectName,data.data[0].name);
              if (subjectVersion == null && data.data[0]._id == reactId) {
              addToStorage(subjectVersionLabel,react18Version);
                setVersion(react18Version);
              } else {
                setVersion(subjectVersion);
              }
              window.location.reload();
            }
            setLoading(false);
          } else {
            //logoutUser();
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      setIsLoggedIn(false);
      setRemainingCourses(allCoursesStored.filter((course) => !course.courseCombo.length && course.courseType !== "Free" && !["60c7106e1527710009ff2ee5","60c7106e1527710009ff2ee6"].includes(course._id)));
      //window.location.href = "/login";
    }
  }, [AuthUserId])
  return { loading, courses, subjectVersion, remainingCourses, isLoggedIn }
}


export const useCourseLinks = (location) => {
  //const { course, id } = useParams();
  const { course, id } = location.state || {};
  const [links, setLinks] = useState([]);
  const [userStatus, setUserStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [certificate, setCertificate] = useState(false);
  const [coursename, setCourseName] = useState("");
  const [courselink, setCourseLink] = useState("");
  const [courseLogo, setCourseLogo] = useState("");
  const [courseCurriculum, setCourseCurriculm] = useState("");
  const [startingVideo, setStartingVideo] = useState("");
  const [totalCourseTime, setTotalDuration] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const totalDuration = (allLinks) => {
    let totalTime = allLinks.reduce((prev, current) => {
      let hours = 0;
      let minutes = 0;
      if (current.duration.split("hour").length == 2 || current.duration.split("hours").length == 2) {
        hours += parseInt(current.duration.split("hour")[0].toString().trim());
      }
      if (current.duration.split("minute").length == 2 && !current.duration.split("minute")[0].includes("hour") && !current.duration.split("minute")[0].includes("hours")) {
        minutes += parseInt(current.duration.split("minute")[0].toString().trim());
      }
      return prev + (hours > 0 ? (hours * 60 * 60) : 0) + (minutes * 60);
    }, 0);
    var seconds = totalTime; // or "2000"
    seconds = parseInt(seconds) //because moment js dont know to handle number in string format
    let hours = Math.floor(moment.duration(seconds, 'seconds').asHours()) > 0 ? Math.floor(moment.duration(seconds, 'seconds').asHours()) + ' hours' : ' ';
    let minutes = Math.floor(moment.duration(seconds, 'seconds').minutes()) > 0 ? Math.floor(moment.duration(seconds, 'seconds').minutes()) + ' minutes' : '';
    var format = hours + " " + minutes;

    setTotalDuration(format);
  }
  const fetchCourseLinks = () => {
    if (AuthUserId != null) {
      setLoading(true);
      api(baseURL + "/getLinksByCourseId/" + course, "GET", { Authorization: AuthToken }, {})
        .then((data) => {
          if (data.success && data.formattedData && data.formattedData.length > 0) {
            setUserStatus(data.status);
            setLinks(data.formattedData);
            totalDuration(data.formattedData);
            setStartingVideo(data.formattedData[0].url);
            if (id == "/undefined") {
              history.push("/vm/vmvideoplayer", { course, id: data.formattedData[0].url.split("https://youtu.be/")[1] });
              dispatch(setLectureDetails(data.formattedData[0]))
            } else {
              dispatch(setLectureDetails(data.formattedData.find(item => item.url.split("https://youtu.be/")[1] === id)))
            }
            fetchEnroll();
            fetchEnrollCheck();
            setLoading(false);
            let courseLogo = allCoursesStored.find(item => item._id === course) ? allCoursesStored.find(item => item._id === course).courseLogo : allCoursesStored[0].courseLogo;
            setCourseLogo(courseLogo);
            if (localStorage.getItem(CourseModes.playerMode) === null) {
              addToStorage(CourseModes.playerMode,CourseModes.learning);
            }
            let courseLinkName = allCoursesStored.find(item => item._id === course).courselink;
            let courseCurriculum = allCoursesStored.find(item => item._id === course).courseCurriculum;
            setCourseName(courseLinkName);
            setCourseCurriculm(courseCurriculum);
          } else {
            //logoutUser();
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      setLoading(true);
      api(baseURL + "/getLinksByCourseIdPublic/" + course, "GET", { Authorization: AuthToken }, {})
        .then((data) => {
          if (data.success && data.formattedData && data.formattedData.length > 0) {
            setUserStatus(data.status);
            setLinks(data.formattedData);
            totalDuration(data.formattedData);
            setStartingVideo(data.formattedData[0].url);
            if (id == "/undefined") {
              history.push("/vm/vmvideoplayer", { course, id: data.formattedData[0].url.split("https://youtu.be/")[1] });
              dispatch(setLectureDetails(data.formattedData[0]))
            } else {
              dispatch(setLectureDetails(data.formattedData.find(item => item.url.split("https://youtu.be/")[1] === id)))
            }
            setLoading(false);
            let courseLogo = allCoursesStored.find(item => item._id === course) ? allCoursesStored.find(item => item._id === course).courseLogo : allCoursesStored[0].courseLogo;
            setCourseLogo(courseLogo);
            if (localStorage.getItem(CourseModes.playerMode) === null) {
              addToStorage(CourseModes.playerMode,CourseModes.learning);
            }
            let courseLinkName = allCoursesStored.find(item => item._id === course).courselink;
            let courseName = allCoursesStored.find(item => item._id === course).name;
            let courseCurriculum = allCoursesStored.find(item => item._id === course).courseCurriculum;
            setCourseLink(courseLinkName);
            setCourseName(courseName);
            setCourseCurriculm(courseCurriculum);
            dispatch(setEnrolled(false));
          } else {
            //logoutUser();
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
      //window.location.href = "/login";
    }
  }
  const fetchEnroll = () => {
    if (AuthUserId != null) {
      setLoading(true);
      api(baseURL + "/getEnrollDetailsByUserId/" + course + "/" + AuthUserId, "GET", { Authorization: AuthToken }, {})
        .then((response) => {
          if (response.success) {
            setLoading(false);
            if (response.data && response.data.certificate) {
              setCertificate(response.data.certificate);
              setCourseName(response.data.coursename);
            }
          } else {
            //logoutUser();
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      //window.location.href = "/login";
    }
  };

  const fetchEnrollCheck = () => {
    if (AuthUserId != null) {
      setLoading(true);
      const body = { userId: AuthUserId, coursesInfo: [{ _id: course }] };
      api(baseURL + "/isEnrollAlready", "POST", { "content-type": "application/json", Authorization: AuthToken }, body)
        .then((data) => {
          if (data.success) {
            //Not Enrolled
            setLoading(false);
            //setEnrolled(false);
            dispatch(setEnrolled(false));
          } else {
            //Already enrolled
            setLoading(false);
            //setEnrolled(true);
            dispatch(setEnrolled(true));
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      //window.location.href = "/login";
    }
  }

  useEffect(() => {
    fetchCourseLinks();
  }, [course]);
  return { links, userStatus, loading, certificate, coursename, courseLogo, startingVideo, courseCurriculum, courselink, totalCourseTime, id, course }
}

export const openPlayer = (course, history) => {
  // let courseLinkName = allCoursesStored.find(item => item._id === course._id).courselink;
  let courseLink = allCoursesStored.find(item => item._id === course._id)._id;
  // let videoId = "1";
  // let redirectURL = "/vm/vmvideoplayer/" + courseLink + "/undefined";
  //let redirectURL = course.isNotEnrolled ? ("/" + courseLinkName) : ("/vm/vmvideoplayer/" + courseLink + "/undefined")
  history.push("/vm/vmvideoplayer", { course: courseLink, id: "/undefined" });
}
export const filterCourseByCategory = (courses, selectedCategory) => {
  if (selectedCategory === "all") {
    return courses;
  } else {
    let combos = courseCategories.find(category => category.value === selectedCategory).combos;
    let resultCourses = [];
    if (combos.length > 0) {
      combos.forEach((item) => {
        let filteredCourse = courses.find(course => course._id === item);
        if (filteredCourse != undefined) {
          resultCourses.push(filteredCourse)
        } else {
          filteredCourse = allCoursesStored.find(course => course._id === item);
          resultCourses.push({ ...filteredCourse, isNotEnrolled: true })
        }
      })
      return resultCourses;
    } else {
      return [];
    }
  }
}

// Enrollments

export function useEnrollments() {
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (AuthUserId !== null) {
      setLoading(true);
      api(baseURL + "/getAllEnrolls/", "GET", { Authorization: AuthToken }, {})
        .then((data) => {
          if (data.success) {
            setData(data.data);
            setLoading(false);
          } else {
            //logoutUser();
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      setIsLoggedIn(false);
      //window.location.href = "/login";
    }
  }, [AuthUserId])
  return { loading, data, isLoggedIn }
}