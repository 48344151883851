import moment from "moment";

export const WRONG_PASSWORD =
  "Password must contain 1 Alphabet, 1 Special Character, 1 Number. Min 8 and Max 16 character length only allowed.";
export const WRONG_EMAIL = "must be a valid Email.";
export const discount=25;
export const trainingDiscount=75;
export const comboDiscount=30;
export const discountDuration=3600;
export const reappearAfter=4*3600;
export const discountAmount = 500;
export const discountPercentage = 5;
export const allAvailableCourses = {
  html5: {
    isNew: true,
    courselogo: "html5course",
    title: "HTML5 Basics to Advanced",
    demolink: "https://youtu.be/jrvEpbX2R4s",
    courselink: "html5",
    price: 2000,
    discount,
  },
  css3: {
    isNew: true,
    courselogo: "css3course",
    title: "CSS3 Basics to Advanced",
    demolink: "https://youtu.be/_EOHgXCysWE",
    courselink: "css3",
    price: 2200,
    discount,
  },
  javascript: {
    isNew: true,
    courselogo: "javascriptcourse",
    title: "JavaScript Complete Course",
    demolink: "https://youtu.be/yDVTHL9AuhE",
    courselink: "javascript",
    price: 2500,
    discount,
  },
  javascriptsmart: {
    isNew: true,
    courselogo: "javascriptsmartcourse",
    title: "JavaScript Smart Course",
    demolink: "https://youtu.be/OCfZmZnqNAg",
    courselink: "javascriptsmart",
    price: 2500,
    discount,
  },
  reactredux: {
    isNew: true,
    courselogo: "reactreduxcoursefull",
    title: "React Redux Complete Course",
    demolink: "https://youtu.be/FOLUnaSKlg8",
    courselink: "reactredux",
    price: 2500,
    discount,
  },
  angular: {
    isNew: true,
    courselogo: "angularcoursefull",
    title: "Angular Complete Course",
    demolink: "https://youtu.be/j0tzN7US-oU",
    courselink: "angular",
    price: 3000,
    discount,
  },
  reacttypescript: {
    isNew: true,
    courselogo: "reacttypescriptcourse",
    title: "React TypeScript Complete Course",
    demolink: "https://youtu.be/0R7Qm_CHA24",
    courselink: "reacttypescript",
    price: 2500,
    discount,
  },
  nodejs: {
    isNew: true,
    courselogo: "nodejscoursefull",
    title: "Node JS Complete Course",
    demolink: "https://youtu.be/cca_x6M51zw",
    courselink: "nodejs",
    price: 2500,
    discount,
  },
  webdeveloper: {
    isNew: true,
    courselogo: "webdeveloper",
    title: "Web Development Full Course",
    demolink: "https://youtu.be/jrvEpbX2R4s",
    courselink: "webdeveloper",
    price: 6700,
    discount:comboDiscount
  },
  mernstack: {
    isNew: true,
    courselogo: "mernstack",
    title: "MERN Stack Course",
    demolink: "https://youtu.be/t75DKzmr1cQ",
    courselink: "mernstack",
    price: 6000,
    discount:comboDiscount,
  },
  meanstack: {
    isNew: true,
    courselogo: "meanstack",
    title: "MEAN Stack Course",
    demolink: "https://youtu.be/j0tzN7US-oU",
    courselink: "meanstack",
    price: 5500,
    discount:comboDiscount,
  },
  mernstackproject: {
    isNew: true,
    courselogo: "mernstack-application",
    title: "MERN Stack Project",
    demolink: "https://youtu.be/t75DKzmr1cQ",
    courselink: "mernstackproject",
    price: 1000,
    discount,
  },
  reactnative: {
    isNew: true,
    courselogo: "reactnativecourse",
    title: "React Native Complete Course",
    demolink: "https://youtu.be/sWzNphyuRsk",
    courselink: "reactnative",
    price: 3000,
    discount
  },
  jsandmern: {
    isNew: true,
    courselogo: "jsandmern",
    title: "JS and MERN Course",
    demolink: "https://youtu.be/t75DKzmr1cQ",
    courselink: "jsandmernstack",
    price: 8500,
    discount:comboDiscount,
  },
  jsandreact: {
    isNew: true,
    courselogo: "jsandreact",
    title: "JS and React Course",
    demolink: "https://youtu.be/FOLUnaSKlg8",
    courselink: "jsandreact",
    price: 5000,
    discount:comboDiscount,
  },
  jsandnode: {
    isNew: true,
    courselogo: "jsandnode",
    title: "JS and Node Course",
    demolink: "https://youtu.be/cca_x6M51zw",
    courselink: "jsandnodejs",
    price: 5000,
    discount:comboDiscount,
  }
}

export const allCoursesStatic = [
  {
    isNew: true,
    courselogo: "reacttypescript",
    title: "React TypeScript Complete Course",
    demolink: "https://youtu.be/0R7Qm_CHA24",
    courselink: "reacttypescript",
    price: 2500,
    discount,
    image1: "/images/reacttypescriptcourse.png",
    image2: "/images/reacttypescriptcurriculum.png",
    id: "6587f82ef1dc6800081c2db3"
  },
  {
    isNew: true,
    courselogo: "reactandreactnative",
    title: "React and React Native Complete Course",
    demolink: "https://youtu.be/sWzNphyuRsk",
    courselink: "reactandreactnative",
    price: 5500,
    discount:comboDiscount,
    image1: "/images/reactnativecourse.png",
    image2: "/images/rncurriculum.png",
    id: "617f1615f5e59900084ad475"
  },
  {
    isNew: true,
    courselogo: "reactnativecourse",
    title: "React Native Complete Course",
    demolink: "https://youtu.be/sWzNphyuRsk",
    courselink: "reactnative",
    price: 3000,
    discount,
    image1: "/images/reactnativecourse.png",
    image2: "/images/rncurriculum.png",
    id: "617f1615f5e59900084ad475"
  },
  {
    isNew: true,
    courselogo: "reactreduxcoursefull",
    title: "React and Redux Complete Course",
    demolink: "https://youtu.be/FOLUnaSKlg8",
    courselink: "reactredux",
    price: 2500,
    discount,
    image1: "/images/reactreduxcoursefull.png",
    image2: "/images/reactcurriculum.png",
    id: "60c7106e1527710009ff2ee4"
  },
  {
    isNew: true,
    courselogo: "angularcoursefull",
    title: "Angular Complete Course",
    demolink: "https://youtu.be/j0tzN7US-oU",
    courselink: "angular",
    price: 3000,
    discount,
    image1: "/images/angularcoursefull.png",
    image2: "https://res.cloudinary.com/dbqm9svvp/image/upload/v1673123421/vmonlinetraining/angularthumbnails/angular-curriculum_cwpqj5.png",
    id: "63b9c079da88f700085e58b7"
  },
  {
    isNew: true,
    courselogo: "mernstack",
    title: "MEAN Stack Complete Course",
    demolink: "https://youtu.be/j0tzN7US-oU",
    courselink: "meanstack",
    price: 5500,
    discount:comboDiscount,
    image1: "/images/meanstack.png",
    image2: "https://res.cloudinary.com/dbqm9svvp/image/upload/v1673123421/vmonlinetraining/angularthumbnails/angular-curriculum_cwpqj5.png",
    id: "63b9c079da88f700085e58b71"
  },
  {
    isNew: true,
    courselogo: "nodejscoursefull",
    title: "NodeJS Complete Course",
    demolink: "https://youtu.be/cca_x6M51zw",
    courselink: "nodejs",
    price: 2500,
    discount,
    image1: "/images/nodejscoursefull.png",
    image2: "/images/nodejscurriculum.png",
    id: "60c7112096998f00090e89aa"
  },
  {
    isNew: true,
    courselogo: "mernstack",
    title: "MERN Stack Complete Course",
    demolink: "https://youtu.be/t75DKzmr1cQ",
    courselink: "mernstack",
    price: 6000,
    discount:comboDiscount,
    image1: "/images/mernstack.png",
    image2: "/images/merncurriculum.png",
    id: "60d3929f4edb82000895c111"
  },
  {
    isNew: true,
    courselogo: "javascriptcourse",
    title: "JavaScript Complete Course",
    demolink: "https://youtu.be/yDVTHL9AuhE",
    courselink: "javascript",
    price: 2500,
    discount,
    image1: "/images/javascriptcourse.png",
    image2: "/images/jscurriculum.png",
    id: "60c7110a1527710009ff2ee6"
  },
  {
    isNew: true,
    courselogo: "html5course",
    title: "HTML5 Complete Course",
    demolink: "https://youtu.be/jrvEpbX2R4s",
    courselink: "html5",
    price: 2000,
    discount,
    image1: "/images/htmlcourse.png",
    image2: "/images/htmlcurriculum.png",
    id: "60f39d92b51e6f00081bb9bf"
  },
  {
    isNew: true,
    courselogo: "css3course",
    title: "CSS3 Complete Course",
    demolink: "https://youtu.be/_EOHgXCysWE",
    courselink: "css3",
    price: 2200,
    discount,
    image1: "/images/css3course.png",
    image2: "/images/csscurriculum.png",
    id: "614771dbc8da4a0008fe845e"
  },
  {
    isNew: true,
    courselogo: "webdeveloper",
    title: "Web Development Complete Course",
    demolink: "https://youtu.be/jrvEpbX2R4s",
    courselink: "webdeveloper",
    price: 9200,
    discount:comboDiscount,
    image1: "/images/webdeveloper.png",
    image2: "/images/webdev.png",
    id: "620b4dcf7e17741818e48b23"
  },
  {
    isNew: true,
    courselogo: "javascriptsmartcourse",
    title: "JavaScript Smart Course",
    demolink: "https://youtu.be/OCfZmZnqNAg",
    courselink: "javascriptsmart",
    price: 2500,
    discount,
    image1: "/images/javascriptsmartcourse.png",
    image2: "/images/jssmart.png",
    id: "61a37c812cb98e0009dbf547"
  },
  {
    isNew: true,
    courselogo: "jsandmern",
    title: "JS and MERN Complete Course",
    demolink: "https://youtu.be/t75DKzmr1cQ",
    courselink: "jsandmernstack",
    price: 8500,
    discount:comboDiscount,
    image1: "/images/jsandmern.png",
    image2: "/images/jsandmern1.png",
    id: "60d392b44edb82000895c112"
  },
  {
    isNew: true,
    courselogo: "jsandreact",
    title: "JS and React Course",
    demolink: "https://youtu.be/FOLUnaSKlg8",
    courselink: "jsandreactjs",
    price: 5000,
    discount:comboDiscount,
    image1: "/images/jsandreact.png",
    image2: "/images/jsandreact1.png",
    id: "60d77e18b8dd900009c409b6"
  },
  {
    isNew: true,
    courselogo: "jsandnode",
    title: "JS and Node Course",
    demolink: "https://youtu.be/cca_x6M51zw",
    courselink: "jsandnodejs",
    price: 5000,
    discount:comboDiscount,
    image1: "/images/jsandnode.png",
    image2: "/images/jsandnode1.png",
    id: "60d77e2fb8dd900009c409b7"
  },
  {
    isNew: true,
    courselogo: "mernstack-application",
    title: "MERN Stack Project",
    demolink: "https://youtu.be/t75DKzmr1cQ",
    courselink: "projects",
    price: 1000,
    discount,
    image1: "/images/react/mernstack-application.png",
    image2: "/images/merncurriculum.png",
    id: "610381b6158ea7000881d69b"
  },
  {
    isNew: true,
    courseType: "Free",
    courselogo: "typescriptcourse",
    title: "TypeScript Crash Course (Free)",
    demolink: "https://www.youtube.com/watch?v=Tev5XBrb-sc&list=PLyR4MnfgcsQEa6nVMBUthwQRSnum_0vLT",
    courselink: "https://www.youtube.com/watch?v=Tev5XBrb-sc&list=PLyR4MnfgcsQEa6nVMBUthwQRSnum_0vLT",
    price: 0,
    discount: 0,
    image1: "/images/typescriptcourse.png",
    image2: "/images/typescriptcourse.png",
    id: "620b4f387e17741818e48b24"
  },
  //{
  //  isNew: true,
  //  courseType: "Free",
  //  courselogo: "reactnativecrashcourse",
  //  title: "React Native Crash Course (Free)",
  //  demolink: "https://www.youtube.com/watch?v=mSMYsY40JfE&list=PLyR4MnfgcsQHMI8QeA9Z9dVUvcqVpCVrj",
  //  courselink: "https://www.youtube.com/watch?v=Tev5XBrb-sc&list=PLyR4MnfgcsQEa6nVMBUthwQRSnum_0vLT",
  //  price: 0,
  //  discount: 0,
  //  image1: "/images/reactnativecrashcourse.png",
  //  image2: "/images/reactnativecrashcourse.png",
  //  id: "6206389edae0ea00093601d0"
  //},
]

export const allCoursesStored = [
  {
    "courseCombo": [],
    "hardwareRequirements": [
        "Memory – Minimum 2/4 GB GB RAM",
        "Processor – Intel Core i3 CPU @2.00 GHz or later",
        "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
        "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
        "Visual Studio Code (for web app development)",
        "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "duration": "1 Month",
    "_id": "66137e099a57af00086e6cc2",
    "name": "React Prerequisites Live Training",
    "certificateRole": "Web Developer",
    "courseType": "Premium",
    "courseTypeColor": "orange",
    "courseTitleColor": "orange",
    "courseBorder": "orange",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1712553317/wmfavyiwaufrpuxyyr5o.png",
    "courseDescription": "In this course, you are going to learn the fundamentals, core concepts of HTML, CSS, JavaScript to learn React faster and efficient.",
    "techCategory": "Frontend",
    "courseDemo": "https://youtu.be/0ESfpMvmQIY",
    "courseCurriculum": "https://drive.google.com/file/d/1pUb95KOblHeUA3ZnLEazENdowunAJcwT/view",
    "courseCertificate": "https://vmtraining.netlify.app/images/html5-certificate.png",
    "courseFeatures": {
        "access": "Life Time Access",
        "certification": "Certification",
        "communitySupport": "Community Support",
        "presentations": "Presentations",
        "quizzes": "Quizzes",
        "sourceCodes": "Source Codes",
        "tasks": "Tasks",
        "videoLectures": "Video Lectures - 10+ hours"
    },
    "price": 8000,
    "discount": 50,
    "createdAt": "2024-04-08T05:18:01.325Z",
    "updatedAt": "2024-04-08T05:18:01.325Z",
    "title": "React Prerequisites Live Training",
    "image1": "/images/react/react-training-next.png",
    "image2": "/images/react/react-training-next.png",
    "isNew": true,
    "isEnroll":true,
    "courselink": "react-2024",
    "isHide":true
  },
  {
    "courseCombo": [],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "66101ea9320d61000709731a",
    "name": "React Online Training",
    "demolink": "https://youtu.be/0ESfpMvmQIY",
    "__v": 0,
    "courseType": "Premium",
    "courseBorder": "#17D1EB",
    "courseDescription": "In this course, you will going to explore React Core Fundamentals, Routing, Hooks, API Integrations, Deployment, Continious Integration Deployment with practical projects etc.,",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644572848/qysnjf9qptyjuubhahom.png",
    "courseTitleColor": "rgba(17,187,241,0.67)",
    "courseTypeColor": "#8D5EF2",
    "techCategory": "Frontend",
    "courseCurriculum": "https://drive.google.com/file/d/1DQmTxnYOu38lQw5ImiLAX1izdsa9ipGi/view?usp=drive_link",
    "courseCertificate": "https://vmtraining.netlify.app/images/certificate.png",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 41+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "tasks": "Tasks",
      "certification": "Certification",
      "communitySupport": "Community Support"
    },
    "courseDemo": "https://drive.google.com/file/d/1DQmTxnYOu38lQw5ImiLAX1izdsa9ipGi/view?usp=drive_link",
    "discount":trainingDiscount,
    "price": 19999,
    "certificateRole": "React Frontend Developer",
    "updatedAt": "2022-02-15T06:42:15.501Z",
    "title": "React Online Training",
    "image1": "/images/react/react-training-next.png",
    "image2": "/images/react/react-training-next.png",
    "isNew": true,
    "isEnroll":true,
    "courselink": "react-2024",
    "isHide":false
  },
  {
    "courseCombo": [],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "60c7106e1527710009ff2ee5",
    "name": "React Summer Internship and Training",
    "demolink": "https://drive.google.com/file/d/1DQmTxnYOu38lQw5ImiLAX1izdsa9ipGi/view?usp=drive_link",
    "__v": 0,
    "courseType": "Premium",
    "courseBorder": "#17D1EB",
    "courseDescription": "In this course, you will going to explore React Core Fundamentals, Routing, Hooks, API Integrations, Deployment, Continious Integration Deployment with practical projects etc.,",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644572848/qysnjf9qptyjuubhahom.png",
    "courseTitleColor": "rgba(17,187,241,0.67)",
    "courseTypeColor": "#8D5EF2",
    "techCategory": "Frontend",
    "courseCurriculum": "https://drive.google.com/file/d/1DQmTxnYOu38lQw5ImiLAX1izdsa9ipGi/view?usp=drive_link",
    "courseCertificate": "https://vmtraining.netlify.app/images/certificate.png",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 41+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "tasks": "Tasks",
      "certification": "Certification",
      "communitySupport": "Community Support"
    },
    "courseDemo": "https://drive.google.com/file/d/1DQmTxnYOu38lQw5ImiLAX1izdsa9ipGi/view?usp=drive_link",
    "discount":discount,
    "price": 10000,
    "certificateRole": "React Frontend Developer",
    "updatedAt": "2022-02-15T06:42:15.501Z",
    "title": "React Summer Internship and Training",
    "image1": "/images/react/react-training-summer.png",
    "image2": "/images/react/react-training-summer.png",
    "isNew": true,
    "isEnroll":true,
    "courselink": "react-summer-2024",
    "isHide":true
  },

  {
    "courseCombo": [],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "60c7106e1527710009ff2ee4",
    "name": "React with Redux",
    "demolink": "https://youtu.be/FOLUnaSKlg8",
    "__v": 0,
    "courseType": "Premium",
    "courseBorder": "#17D1EB",
    "courseDescription": "In this course, you will going to explore React Core Fundamentals, Routing, Hooks, Redux, Unit Testing, API Integrations, Deployment, Continious Integration Deployment, Material Design, Bootstrap etc.,",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644572848/qysnjf9qptyjuubhahom.png",
    "courseTitleColor": "rgba(17,187,241,0.67)",
    "courseTypeColor": "#8D5EF2",
    "techCategory": "Frontend",
    "courseCurriculum": "https://drive.google.com/file/d/1KiTRO99iq3nOPwLkj0EyWtBBAjxXULI1/view",
    "courseCertificate": "https://vmtraining.netlify.app/images/certificate.png",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 41+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "tasks": "Tasks",
      "certification": "Certification",
      "communitySupport": "Community Support"
    },
    "courseDemo": "https://youtu.be/FOLUnaSKlg8",
    "discount":discount,
    "price": 2500,
    "certificateRole": "React Frontend Developer",
    "updatedAt": "2022-02-15T06:42:15.501Z",
    "title": "React with Redux",
    "image1": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644572848/qysnjf9qptyjuubhahom.png",
    "image2": "/images/reactcurriculum.png",
    "isNew": true,
    "courselink": "reactredux"
  },
  {
    "courseCombo": [],
    "hardwareRequirements": [
    "Memory – Minimum 2/4 GB GB RAM",
    "Processor – Intel Core i3 CPU @2.00 GHz or later",
    "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
    "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
    "Visual Studio Code (for web app development)",
    "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "6587f82ef1dc6800081c2db3",
    "name": "React TypeScript",
    "certificateRole": "React Frontend Developer",
    "courseType": "Premium",
    "courseTypeColor": "#005B9C",
    "courseTitleColor": "#005B9C",
    "courseBorder": "#005B9C",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1703409033/uemjpalqfsb9u0bybezl.png",
    "courseDescription": "In this course, you are going to learn introduction react typescript, fundamentals, react essentials, basic props, advanced props, hooks, component props, restricting props, generic props, Recipe and Portfolio apps etc.,",
    "techCategory": "Frontend",
    "courseDemo": "https://youtu.be/0R7Qm_CHA24",
    "demolink":"https://youtu.be/0R7Qm_CHA24",
    "courseCurriculum": "https://drive.google.com/file/d/1NXl_DMOYA9aXN_c2krfSwzC24ShD-exf/view",
    "courseCertificate": "https://vmtraining.netlify.app/images/certificate.png",
    "courseFeatures": {
    "access": "Life Time Access",
    "certification": "Certification",
    "communitySupport": "Community Support",
    "presentations": "Presentations",
    "quizzes": "Quizzes",
    "sourceCodes": "Source Codes",
    "tasks": "Tasks",
    "videoLectures": "Video Lectures - 6+ hours"
    },
    "price": 2500,
    "discount":discount,
    "title": "React TypeScript Complete Course",
    "image1": "/images/reacttypescriptcourse.png",
    "image2": "/images/reacttypescriptcurriculum.png",
    "isNew": true,
    "courselink": "reacttypescript"
    },
  {
    "courseCombo": [],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "63b9c079da88f700085e58b7",
    "name": "Angular",
    "certificateRole": "Angular Frontend Developer",
    "courseType": "Premium",
    "courseTypeColor": "#DC143C",
    "courseTitleColor": "#DC143C",
    "courseBorder": "#FF4500",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1673117501/r8dbimqyrcaod1lvfd4p.png",
    "courseDescription": "In this course, you are going to learn Angular basics to advanced concepts such as Angular Introduction, Angular Routing, Angular Forms, Material, Bootstrap, Storybook, PWA, Deployment etc., for more details click on the Curriculum.",
    "techCategory": "Frontend",
    "courseDemo": "https://youtu.be/j0tzN7US-oU",
    "courseCurriculum": "https://drive.google.com/file/d/1O209fypYmTXJQxIiwoTXxtSrmCBwq5iq/view",
    "courseCertificate": "https://vmtraining.netlify.app/images/angular-course-certificate.png",
    "courseFeatures": {
      "access": "Life Time Access",
      "certification": "Certification",
      "communitySupport": "Community Support",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "sourceCodes": "Source Codes",
      "tasks": "Tasks",
      "videoLectures": "Video Lectures - 10+ hours"
    },
    "price": 3000,
    "discount":discount,
    "title": "Angular Complete Course",
    "image1": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1673120453/vmonlinetraining/angularthumbnails/angular-course_dqeako.png",
    "image2": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1673123421/vmonlinetraining/angularthumbnails/angular-curriculum_cwpqj5.png",
    "isNew": true,
    "courselink": "angular"
  },

  {
    "courseCombo": [
      "60c7106e1527710009ff2ee4",
      "60c7112096998f00090e89aa",
      "610381b6158ea7000881d69b"
    ],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "60d3929f4edb82000895c111",
    "name": "MERN Stack",
    "demolink": "https://youtu.be/t75DKzmr1cQ",
    "__v": 0,
    "courseBorder": "purple",
    "courseDescription": "In this course, you will going to explore ReactJS, Redux, NodeJS Courses with real time projects",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573385/br6agydmrbrdgcdqxbt7.png",
    "courseTitleColor": "purple",
    "courseType": "Premium",
    "courseTypeColor": "purple",
    "techCategory": "Frontend and Backend",
    "courseCertificate": "https://vmtraining.netlify.app/images/merncertificate.png",
    "courseCurriculum": "https://drive.google.com/file/d/1KiTRO99iq3nOPwLkj0EyWtBBAjxXULI1/view",
    "courseDemo": "https://youtu.be/t75DKzmr1cQ",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 55+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "tasks": "Tasks",
      "certification": "Certification",
      "communitySupport": "Community Support"
    },
    "discount":comboDiscount,
    "price": 6000,
    "certificateRole": "MERN Stack Developer",
    "updatedAt": "2022-02-15T06:43:53.362Z",
    "title": "MERN Stack",
    "image1": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573385/br6agydmrbrdgcdqxbt7.png",
    "image2": "/images/merncurriculum.png",
    "isNew": true,
    "courselink": "mernstack"
  },
  {
    "courseCombo": [
      "63b9c079da88f700085e58b7",
      "60c7112096998f00090e89aa"
    ],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)",
      "NodeJS (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 4,
    "_id": "63b9c079da88f700085e58b71",
    "name": "MEAN Stack",
    "demolink": "https://youtu.be/j0tzN7US-oU",
    "__v": 0,
    "courseBorder": "orange",
    "courseDescription": "In this course, you will going to explore React Native Basics to Advanced topics",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1681011505/eqk28c82vlafh1umdk5h.png",
    "courseTitleColor": "orange",
    "courseType": "Premium",
    "courseTypeColor": "orange",
    "techCategory": "Frontend Development",
    "courseCertificate": "https://vmtraining.netlify.app/images/meancertificate.png",
    "courseCurriculum": "https://drive.google.com/file/d/1O209fypYmTXJQxIiwoTXxtSrmCBwq5iq/view",
    "courseDemo": "https://youtu.be/j0tzN7US-oU",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 21+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "tasks": "Tasks",
      "certification": "Certification",
      "communitySupport": "Community Support"
    },
    "discount":comboDiscount,
    "price": 5500,
    "certificateRole": "Angular Developer",
    "updatedAt": "2022-02-15T06:48:28.877Z",
    "title": "MEAN Stack Complete Course",
    "image1": "/images/meanstack.png",
    "image2": "/images/meanstack.png",
    "isNew": true,
    "courselink": "meanstack"
  },
  {
    "courseCombo": [],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "60c7110a1527710009ff2ee6",
    "name": "JavaScript",
    "demolink": "https://youtu.be/yDVTHL9AuhE",
    "__v": 0,
    "courseBorder": "orange",
    "courseDescription": "In this course, you will going to explore JavaScript Core Fundamentals, Basics, User Interaction Functionalities, Various Data Structures such as Arrays, Objects, Functions, Generators, Events, Storage, DOM, Dates, jQuery Basics, ES6 Features till 2021, Object Oriented Programming etc.,",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573300/nmphnxv0kslu0lqfv5pn.png",
    "courseTitleColor": "orange",
    "courseType": "Premium",
    "courseTypeColor": "orange",
    "techCategory": "Frontend/Backend",
    "courseCertificate": "https://vmtraining.netlify.app/images/javascript-certificate.png",
    "courseCurriculum": "https://drive.google.com/file/d/1b9F4KiQ-EZMk6tGkSBuxGp0L3WFg370p/view",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 15+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "tasks": "Tasks",
      "certification": "Certification",
      "communitySupport": "Community Support"
    },
    "courseDemo": "https://youtu.be/yDVTHL9AuhE",
    "discount":discount,
    "price": 2500,
    "certificateRole": "JavaScript Developer",
    "updatedAt": "2022-02-15T06:42:57.459Z",
    "title": "JavaScript",
    "image1": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573300/nmphnxv0kslu0lqfv5pn.png",
    "image2": "/images/jscurriculum.png",
    "isNew": true,
    "courselink": "javascript"
  },
  {
    "courseCombo": [],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "60c7112096998f00090e89aa",
    "name": "NodeJS",
    "demolink": "https://youtu.be/cca_x6M51zw",
    "__v": 0,
    "courseBorder": "green",
    "courseDescription": "In this course, you will going to explore NodeJS Fundamentals, Environment Setup, Callbacks, Request, Response, Promises, Routing, Databases such as MongoDB, MySQL with express framework, MVC Architecture, Postman, Authentication, JWT, Template Engine, File Operations, GraphQL, Payment Integration, Mails etc.,",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573362/aqjdv1ah8zaa8zflnkeq.png",
    "courseTitleColor": "green",
    "courseType": "Premium",
    "courseTypeColor": "green",
    "techCategory": "Backend",
    "courseCertificate": "https://vmtraining.netlify.app/images/nodejs-certificate.png",
    "courseCurriculum": "https://drive.google.com/file/d/1qI_rvrWUR8OldONJFITa_e6LbcIB94Cz/view",
    "courseDemo": "https://youtu.be/cca_x6M51zw",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 11+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "tasks": "Tasks",
      "certification": "Certification",
      "communitySupport": "Community Support"
    },
    "discount":discount,
    "price": 2500,
    "certificateRole": "NodeJS Backend Developer",
    "updatedAt": "2022-02-15T06:43:25.116Z",
    "title": "NodeJS",
    "image1": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573362/aqjdv1ah8zaa8zflnkeq.png",
    "image2": "/images/nodejscurriculum.png",
    "isNew": true,
    "courselink": "nodejs"
  },
  {
    "courseCombo": [],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)",
      "Android Studio (latest version)",
      "NodeJS (latest version)",
      "JDK 8 or above version"
    ],
    "isNewCourse": true,
    "videosLength": 4,
    "_id": "617f1615f5e59900084ad475",
    "name": "React Native",
    "demolink": "https://youtu.be/sWzNphyuRsk",
    "__v": 0,
    "courseBorder": "orange",
    "courseDescription": "In this course, you will going to explore React Native Basics to Advanced topics",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573970/azqwdv9nlhgpc8fqk9lq.png",
    "courseTitleColor": "orange",
    "courseType": "Premium",
    "courseTypeColor": "orange",
    "techCategory": "Frontend/Mobile App Development",
    "courseCertificate": "https://vmtraining.netlify.app/images/reactnative-certificate.png",
    "courseCurriculum": "https://drive.google.com/file/d/1Z_vtdXLdj76eXdILlK6Hrev1Lr_TxCUj/view",
    "courseDemo": "https://youtu.be/sWzNphyuRsk",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 9+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "tasks": "Tasks",
      "certification": "Certification",
      "communitySupport": "Community Support"
    },
    "discount":discount,
    "price": 3000,
    "certificateRole": "React Native Developer",
    "updatedAt": "2022-02-15T06:48:28.877Z",
    "title": "React Native",
    "image1": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573970/azqwdv9nlhgpc8fqk9lq.png",
    "image2": "/images/rncurriculum.png",
    "isNew": true,
    "courselink": "reactnative"
  },
  {
    "courseCombo": [],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "NodeJS Latest Version",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)",
      "XAMPP"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "610381b6158ea7000881d69b",
    "name": "MERN Stack Project",
    "demolink": "https://youtu.be/t75DKzmr1cQ",
    "__v": 0,
    "courseBorder": "purple",
    "courseDescription": "In this course, you will going to explore MERN Stack Appliation combination of ReactJS, NodeJS, MongoDB, MySQL with 6+ hours application",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1642099307/vmonlinetraining/mernstack-application_ozoz5k.png",
    "courseTitleColor": "purple",
    "courseType": "Premium",
    "courseTypeColor": "purple",
    "techCategory": "Frontend/Backend",
    "courseCertificate": "https://vmtraining.netlify.app/images/html5-certificate.png",
    "courseCurriculum": "https://vmtraining.netlify.app/projects",
    "courseDemo": "https://youtu.be/t75DKzmr1cQ",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 7+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "",
      "tasks": "",
      "certification": "",
      "communitySupport": "Community Support"
    },
    "discount":discount,
    "price": 1000,
    "certificateRole": "MERN Stack Developer",
    "updatedAt": "2022-02-15T06:47:57.558Z",
    "title": "MERN Stack Project",
    "image1": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1642099307/vmonlinetraining/mernstack-application_ozoz5k.png",
    "image2": "/images/merncurriculum.png",
    "isNew": true,
    "courselink": "projects"
  },
  {
    "courseCombo": [
      "60c7110a1527710009ff2ee6",
      "60c7106e1527710009ff2ee4",
      "60c7112096998f00090e89aa"
    ],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "60d392b44edb82000895c112",
    "name": "JS and MERN Stack",
    "demolink": "https://youtu.be/t75DKzmr1cQ",
    "__v": 0,
    "courseBorder": "tomato",
    "courseDescription": "In this course, you will going to explore JavaScript, ReactJS, Redux, NodeJS Courses with real time projects",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573499/luxculuax8igguyawnqx.png",
    "courseTitleColor": "tomato",
    "courseType": "Premium",
    "courseTypeColor": "tomato",
    "techCategory": "Frontend and Backend",
    "courseCertificate": "https://vmtraining.netlify.app/images/merncertificate.png",
    "courseCurriculum": "https://drive.google.com/file/d/1KiTRO99iq3nOPwLkj0EyWtBBAjxXULI1/view",
    "courseDemo": "https://youtu.be/t75DKzmr1cQ",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 66+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "tasks": "Tasks",
      "certification": "Certification",
      "communitySupport": "Community Support"
    },
    "discount":comboDiscount,
    "price": 8500,
    "certificateRole": "MERN Stack Developer",
    "updatedAt": "2022-02-15T06:44:18.333Z",
    "title": "JS and MERN Stack",
    "image1": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573499/luxculuax8igguyawnqx.png",
    "image2": "/images/jsandmern1.png",
    "isNew": true,
    "courselink": "jsandmernstack"
  },
  {
    "courseCombo": [
      "60c7110a1527710009ff2ee6",
      "60c7106e1527710009ff2ee4"
    ],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "60d77e18b8dd900009c409b6",
    "name": "JS and React",
    "demolink": "https://youtu.be/t75DKzmr1cQ",
    "__v": 0,
    "courseBorder": "tomato",
    "courseDescription": "In this course, you will going to explore JavaScript, ReactJS, Redux Courses with real time projects",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573530/omgfzg5rdzk3ufiajrim.png",
    "courseTitleColor": "tomato",
    "courseType": "Premium",
    "courseTypeColor": "tomato",
    "techCategory": "Frontend",
    "courseCertificate": "https://vmtraining.netlify.app/images/certificate.png",
    "courseCurriculum": "https://drive.google.com/file/d/1KiTRO99iq3nOPwLkj0EyWtBBAjxXULI1/view",
    "courseDemo": "https://youtu.be/FOLUnaSKlg8",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 52+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "tasks": "Tasks",
      "certification": "Certification",
      "communitySupport": "Community Support"
    },
    "discount":comboDiscount,
    "price": 5000,
    "certificateRole": "React Frontend Developer",
    "updatedAt": "2022-02-15T06:44:39.791Z",
    "title": "JS and React",
    "image1": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573530/omgfzg5rdzk3ufiajrim.png",
    "image2": "/images/jsandreact1.png",
    "isNew": true,
    "courselink": "jsandreactjs"
  },
  {
    "courseCombo": [
      "60c7110a1527710009ff2ee6",
      "60c7112096998f00090e89aa"
    ],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "60d77e2fb8dd900009c409b7",
    "name": "JS and NodeJS",
    "demolink": "https://youtu.be/t75DKzmr1cQ",
    "__v": 0,
    "courseBorder": "green",
    "courseDescription": "In this course, you will going to explore JavaScript, NodeJS Courses with real time projects",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573545/emkazqp9npdalifvtddy.png",
    "courseTitleColor": "green",
    "courseType": "Premium",
    "courseTypeColor": "green",
    "techCategory": "Frontend/Backend",
    "courseCertificate": "https://vmtraining.netlify.app/images/nodejs-certificate.png",
    "courseCurriculum": "https://drive.google.com/file/d/1qI_rvrWUR8OldONJFITa_e6LbcIB94Cz/view",
    "courseDemo": "https://youtu.be/cca_x6M51zw",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 30+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "tasks": "Tasks",
      "certification": "Certification",
      "communitySupport": "Community Support"
    },
    "discount":comboDiscount,
    "price": 5000,
    "certificateRole": "NodeJS Backend Developer",
    "updatedAt": "2022-02-15T06:44:55.910Z",
    "title": "JS and NodeJS",
    "image1": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573545/emkazqp9npdalifvtddy.png",
    "image2": "/images/jsandnode1.png",
    "isNew": true,
    "courselink": "jsandnodejs"
  },
  {
    "courseCombo": [],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "60f39d92b51e6f00081bb9bf",
    "name": "HTML",
    "demolink": "https://youtu.be/jrvEpbX2R4s",
    "__v": 0,
    "courseBorder": "tomato",
    "courseDescription": "In this course, you will going to explore HTML Basics to Advanced topics",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573567/oim2euqa11lp2gqqq6g0.png",
    "courseTitleColor": "tomato",
    "courseType": "Premium",
    "courseTypeColor": "tomato",
    "techCategory": "Frontend",
    "courseCertificate": "https://vmtraining.netlify.app/images/html5-certificate.png",
    "courseCurriculum": "https://drive.google.com/file/d/19kMJhKBKBQFqAtSiJOEwTpvJEeVZk3DW/view",
    "courseDemo": "https://youtu.be/jrvEpbX2R4s",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 6.5+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "tasks": "Tasks",
      "certification": "Certification",
      "communitySupport": "Community Support"
    },
    "discount":discount,
    "price": 2000,
    "certificateRole": "Web Developer - HTML",
    "updatedAt": "2022-02-15T06:45:13.716Z",
    "title": "HTML",
    "image1": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573567/oim2euqa11lp2gqqq6g0.png",
    "image2": "/images/htmlcurriculum.png",
    "isNew": true,
    "courselink": "html5"
  },
  {
    "courseCombo": [],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "614771dbc8da4a0008fe845e",
    "name": "CSS",
    "demolink": "https://youtu.be/_EOHgXCysWE",
    "__v": 0,
    "courseBorder": "blue",
    "courseDescription": "In this course, you will going to explore CSS Basics to Advanced topics",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573848/t87khttj4t0ccm281xpj.png",
    "courseTitleColor": "blue",
    "courseType": "Premium",
    "courseTypeColor": "blue",
    "techCategory": "Frontend",
    "courseCertificate": "https://vmtraining.netlify.app/images/html5-certificate.png",
    "courseCurriculum": "https://drive.google.com/file/d/1Rvskg49bI8G6rbpLkk9RcSexngRaXS5b/view",
    "courseDemo": "https://youtu.be/_EOHgXCysWE",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 10+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "tasks": "Tasks",
      "certification": "Certification",
      "communitySupport": "Community Support"
    },
    "discount":discount,
    "price": 2200,
    "certificateRole": "Web Developer - CSS",
    "updatedAt": "2022-02-15T06:48:12.217Z",
    "title": "CSS",
    "image1": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573848/t87khttj4t0ccm281xpj.png",
    "image2": "/images/csscurriculum.png",
    "isNew": true,
    "courselink": "css3"
  },
  {
    "courseCombo": [
      "60c7106e1527710009ff2ee4",
      "617f1615f5e59900084ad475"
    ],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)",
      "Android Studio (latest version)",
      "NodeJS (latest version)",
      "JDK 8 or above version"
    ],
    "isNewCourse": true,
    "videosLength": 4,
    "_id": "617f1615f5e59900084ad476",
    "name": "React and React Native",
    "demolink": "https://youtu.be/sWzNphyuRsk",
    "__v": 0,
    "courseBorder": "orange",
    "courseDescription": "In this course, you will going to explore React Native Basics to Advanced topics",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644573970/azqwdv9nlhgpc8fqk9lq.png",
    "courseTitleColor": "orange",
    "courseType": "Premium",
    "courseTypeColor": "orange",
    "techCategory": "Frontend/Mobile App Development",
    "courseCertificate": "https://vmtraining.netlify.app/images/reactnative-certificate.png",
    "courseCurriculum": "https://drive.google.com/file/d/1L6pouobo6JJPf_lDQB6uoX9MAR0Abv9t/view",
    "courseDemo": "https://youtu.be/sWzNphyuRsk",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 9+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "tasks": "Tasks",
      "certification": "Certification",
      "communitySupport": "Community Support"
    },
    "discount":comboDiscount,
    "price": 5500,
    "certificateRole": "React Native Developer",
    "updatedAt": "2022-02-15T06:48:28.877Z",
    "title": "React and React Native",
    "image1": "/images/reactandreactnative.png",
    "image2": "/images/reactandreactnative.png",
    "isNew": true,
    "courselink": "reactandreactnative"
  },
  {
    "courseCombo": [],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "61a37c812cb98e0009dbf547",
    "name": "JavaScript Smart Course",
    "demolink": "https://youtu.be/OCfZmZnqNAg",
    "__v": 0,
    "courseBorder": "orange",
    "courseDescription": "In this course, you will going to explore JavaScript, HTML, CSS Practical Projects by covering all the concepts of JavaScript.",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644574013/pjp2mzowkywhlm2lp0jt.png",
    "courseTitleColor": "orange",
    "courseType": "Premium",
    "courseTypeColor": "orange",
    "techCategory": "Frontend",
    "courseCertificate": "https://vmtraining.netlify.app/images/javascript-certificate.png",
    "courseCurriculum": "https://drive.google.com/file/d/1LBle2uYR5uvTo_OcFaTKTv3VBFRsPJUy/view",
    "courseDemo": "https://youtu.be/OCfZmZnqNAg",
    "courseFeatures": {
      "videoLectures": "Video Lectures - 6+ hours",
      "access": "Lifetime Access",
      "sourceCodes": "Source Codes",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "tasks": "Tasks",
      "certification": "Certification",
      "communitySupport": "Community Support"
    },
    "discount":discount,
    "price": 2500,
    "certificateRole": "JavaScript Developer",
    "updatedAt": "2022-02-15T06:48:46.473Z",
    "title": "JavaScript Smart Course",
    "image1": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644574013/pjp2mzowkywhlm2lp0jt.png",
    "image2": "/images/jssmart.png",
    "isNew": true,
    "courselink": "javascriptsmart"
  },
  {
    "courseCombo": [
      "60f39d92b51e6f00081bb9bf",
      "614771dbc8da4a0008fe845e",
      "60c7110a1527710009ff2ee6",
      "61a37c812cb98e0009dbf547"
    ],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": true,
    "videosLength": 0,
    "_id": "620b4dcf7e17741818e48b23",
    "name": "Web Development Course",
    "demolink": "https://youtu.be/_EOHgXCysWE",
    "certificateRole": "Web Developer",
    "courseType": "Premium",
    "courseTypeColor": "#ff6347",
    "courseTitleColor": "#ff6347",
    "courseBorder": "#ff6347",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644605031/oq8ngcggu910ebgny8rj.png",
    "courseDescription": "In this course, you are going to learn HTML Basics to Advanced concepts, CSS Basics to Advanced topics and JavaScript Complete Course including es6 features, finally you will learn the practical projects with the combination of all the three languages.",
    "techCategory": "Frontend",
    "courseDemo": "https://youtu.be/_EOHgXCysWE",
    "courseCurriculum": "https://drive.google.com/file/d/1x1_fuM90qpq4F4ReK-t8iEE-wg36ZJNG/view",
    "courseCertificate": "Web Developer",
    "courseFeatures": {
      "access": "Life Time Access",
      "certification": "Certification",
      "communitySupport": "Community Support",
      "presentations": "Presentations",
      "quizzes": "Quizzes",
      "sourceCodes": "Source Codes",
      "tasks": "Tasks",
      "videoLectures": "Video Lectures - 45+ hours"
    },
    "price": 9200,
    "discount":comboDiscount,
    "createdAt": "2022-02-15T06:53:03.146Z",
    "updatedAt": "2022-02-15T06:58:24.949Z",
    "__v": 0,
    "title": "Web Development Course",
    "image1": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644605031/oq8ngcggu910ebgny8rj.png",
    "image2": "/images/webdev.png",
    "isNew": true,
    "courselink": "webdeveloper"
  },
  {
    "courseCombo": [],
    "hardwareRequirements": [
      "Memory – Minimum 2/4 GB GB RAM",
      "Processor – Intel Core i3 CPU @2.00 GHz or later",
      "Storage – 20 GB GB HDD/SDD or above"
    ],
    "softwareRequirements": [
      "Operating System – Any OS (Windows 7 or later/Linux/Mac)",
      "Visual Studio Code (for web app development)",
      "Chrome/Mozilla Firefox (latest version)"
    ],
    "isNewCourse": false,
    "videosLength": 0,
    "_id": "620b4f387e17741818e48b24",
    "name": "TypeScript Crash Course",
    "certificateRole": "JavaScript Developer",
    "courseType": "Free",
    "courseTypeColor": "#000080",
    "courseTitleColor": "#000080",
    "courseBorder": "#000080",
    "courseLogo": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644866751/gowsspgvbxgclochwsii.png",
    "courseDescription": "In this course, you are going to learn TypeScript basics to advanced types and the basic overview of TypeScript in React.",
    "techCategory": "Frontend",
    "courseDemo": "https://youtu.be/Tev5XBrb-sc",
    "courseCurriculum": "https://github.com/VenkateshMogili/sample-project/raw/master/shotnotes-pdf-documents/TypeScript%20Crash%20Course.pdf",
    "courseCertificate": "TypeScript",
    "courseFeatures": {
      "access": "Life Time Access",
      "certification": "",
      "communitySupport": "",
      "presentations": "Presentations",
      "quizzes": "",
      "sourceCodes": "Source Codes",
      "tasks": "",
      "videoLectures": "Video Lectures - 1+ hours"
    },
    "price": 0,
    "discount": 0,
    "createdAt": "2022-02-15T06:59:04.483Z",
    "updatedAt": "2022-02-15T06:59:33.095Z",
    "__v": 0,
    "title": "TypeScript Crash Course",
    "image1": "https://res.cloudinary.com/dbqm9svvp/image/upload/v1644866751/gowsspgvbxgclochwsii.png",
    "image2": "/images/typescriptcourse.png",
    "isNew": true,
    "courselink": "https://www.youtube.com/watch?v=Tev5XBrb-sc&list=PLyR4MnfgcsQEa6nVMBUthwQRSnum_0vLT"
  }
]

//html - 60f39d92b51e6f00081bb9bf - done
//css - 614771dbc8da4a0008fe845e - done
//js - 60c7110a1527710009ff2ee6
//js smart - 61a37c812cb98e0009dbf547
//react - 60c7106e1527710009ff2ee4
//nodejs - 60c7112096998f00090e89aa
//react native - 617f1615f5e59900084ad475
//react typescript - 6587f82ef1dc6800081c2db3
export const courseCategories = [
  {
    title: "All Courses",
    value: "all",
    icon: "book"
  },
  {
    title: "Web Developer",
    value: "webdeveloper",
    icon: "html5",
    combos: [
      "60f39d92b51e6f00081bb9bf",
      "614771dbc8da4a0008fe845e",
      "60c7110a1527710009ff2ee6",
      "61a37c812cb98e0009dbf547",
      "60c7106e1527710009ff2ee4",
      "6587f82ef1dc6800081c2db3",
      "63b9c079da88f700085e58b7"]
  },
  {
    title: "Full Stack Developer",
    value: "mernstackdeveloper",
    icon: "rocket",
    combos: [
      "60c7110a1527710009ff2ee6",
      "60c7106e1527710009ff2ee4",
      "60c7112096998f00090e89aa",
      "6587f82ef1dc6800081c2db3",
      "63b9c079da88f700085e58b7"]
  },
  {
    title: "Frontend Developer",
    value: "frontenddeveloper",
    icon: "grav",
    combos: [
      "60f39d92b51e6f00081bb9bf",
      "614771dbc8da4a0008fe845e",
      "60c7110a1527710009ff2ee6",
      "60c7106e1527710009ff2ee4",
      "6587f82ef1dc6800081c2db3",
      "63b9c079da88f700085e58b7"]
  },
  {
    title: "Backend Developer",
    value: "backenddeveloper",
    icon: "database",
    combos: [
      "60c7110a1527710009ff2ee6",
      "60c7112096998f00090e89aa"]
  },
  {
    title: "Mobile App Developer",
    value: "mobileappdeveloper",
    icon: "android",
    combos: [
      "60c7106e1527710009ff2ee4",
      "6587f82ef1dc6800081c2db3",
      "617f1615f5e59900084ad475"]
  }
]

//make sure the date should have 0 if less than 10.
export const expireDates = ["2024-10-06","2024-10-13","2024-10-20","2024-10-27","2024-11-03","2024-11-10","2024-11-17","2024-11-24","2024-12-01","2024-12-08","2024-12-15","2024-12-22","2024-12-29"];

// const foundDate = expireDates.find(item => moment(item).isSameOrAfter(moment()));
// export const filteredDate = (foundDate ? foundDate : moment().add(7, 'days').format('YYYY-MM-DD')) + "T24:00:00";

export const filteredDate = expireDates.filter(item => new Date(item) <= moment(new Date()).add(7, 'd')).slice(-1).pop() + "T24:00:00";

export const reactExpireDate="2024-12-22T24:00:00";

export const regularBatchText = 'Regular Batch  -- 5 PM - 6:30 PM (M,W,F)';
