import React, { useState, useEffect } from "react";
import PleaseWait from "../../common/PleaseWait";
import { api } from "../../utilities/api";
import { baseURL } from "../../utilities/baseurl";
import InstallApp from "../InstallApp";
export default function Verify() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [hide, setHideAlert] = useState(true);
  const [certificateID, setCertificateID] = useState("");
  const [message, setMessage] = useState("");
  useEffect(
    () => {
      let mobile = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase());
      if (mobile) {
        setShow(true);
      } else {
        setShow(false);
      }
    },
    [show],
  );
  const verifyCertificate = (e) => {
    e.preventDefault();
    setLoading(true);
    if (certificateID.trim() === "") {
      setHideAlert(false);
      setVerified(false);
      setMessage("Invalid Certificate ID!");
      setLoading(false);
    } else if (certificateID && certificateID.length > 0) {
      api(
        baseURL + "/verifyCertificate",
        "POST",
        {
          "content-type": "application/json",
        },
        { id: certificateID },
      )
        .then((data) => {
          if (data.success) {
            setHideAlert(false);
            setVerified(true);
            setMessage("This Certificate successfully verified!");
            setLoading(false);
          } else {
            setHideAlert(false);
            setVerified(false);
            setMessage("This Certificate verification failed!");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setHideAlert(false);
          setVerified(false);
          setMessage("Something went wrong");
          setLoading(false);
        });
    } else {
      setHideAlert(false);
      setVerified(false);
      setMessage("Invalid Certificate ID!");
      setLoading(false);
    }
  };
  return (
    <div className="container-fluid text-center vh-100 custom-bg2">
      <InstallApp />
      <h1 className="mb-4 pt-4 text-white">Verify Certificate</h1>
      <div className="row justify-content-center align-items-center m-0 p-0">
        <div className="col-md-6 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
          <form onSubmit={verifyCertificate}>
            {!hide && (
              <div
                className={
                  verified ? (
                    "alert-success alert alert-success alert-dismissible fade show"
                  ) : (
                    "alert-danger alert alert-success alert-dismissible fade show"
                  )
                }
                role="alert">
                <strong>{verified ? "Verified!" : "Not Verified!"}</strong>
                {message ? message : ""}
                <button
                  type="button"
                  onClick={() => setHideAlert(true)}
                  className="close btn"
                  data-dismiss="alert"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}
            <input
              type="text"
              placeholder="Enter Certificate ID"
              className="form-control"
              value={certificateID}
              onChange={(e) => setCertificateID(e.target.value)}
            />
            <br />
            {!loading && <input type="submit" value="Verify" className="btn btn-primary" />}
            {loading && <PleaseWait content={"Please wait..."} />}
          </form>
        </div>
      </div>
    </div>
  );
}
