import React, { useState } from "react";
import PleaseWait from "../../common/PleaseWait";
import { api, baseURL, Validator } from "../../utilities";

export default function ForgetPassword() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [mail, setMail] = useState("");
  const [, forceUpdate] = useState(false);
  const validator = Validator();
  const submitLogin = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setLoading(true);
      let user = api(baseURL + "/forgetPassword", "PUT", { "content-type": "application/json" }, { email: mail });
      user
        .then((data) => {
          if (data.success) {
            setMessage("Please check your mail to reset password!");
            setLoading(false);
          } else {
            setMessage("Invalid Email");
            setLoading(false);
          }
        })
        .catch((error) => {
          setMessage("Something went wrong!");
          setLoading(false);
        });
    } else {
      validator.current.showMessages();
      forceUpdate(true);
    }
  };
  return (
    <div>
      <div className="container-fluid text-center custom-bg2 vh-100">
        <div className="row justify-content-center align-items-center m-0 p-0 pt-4">
          <div className="col-md-5 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
            <form onSubmit={submitLogin}>
              <i className="fa fa-envelope" style={{ fontSize: 200, color: "orange" }} />
              <h1 className="mb-4">Forgot Password</h1>
              <input
                type="email"
                placeholder="Enter Your Email"
                className="form-control"
                value={mail}
                // required
                onChange={(e) => setMail(e.target.value)}
                onFocus={() => setMessage("")}
              />

              {validator.current.message("Email ", mail, "required|email")}
              {<span>{message}</span>}
              <br />
              {!loading && <input type="submit" value="Submit" className="btn btn-primary w-50" />}
              {loading && <PleaseWait content={"Please wait..."} />}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
