import React from "react";
import InstallApp from "../components/InstallApp";
import Footer from "./Footer";

export default function AboutUs() {
  return (
    <div className="container-fluid custom-bg2 vh-100">
      <InstallApp />
      <h1 className="mb-4 pt-4 text-white text-center">About Us</h1>
      <p className="text-white p-5 text-left">
        <b className="text-white text-decoration-underline">About Company:</b>
        <br />
        <b>VM Online Training</b> is an online tech education platform. We provide online courses, online training for full stack technologies such as React, Node, Angular, HTML, CSS, JAVASCRIPT etc., You can learn our course in both web and mobile applications. You can "Enroll once, Learn Anywhere!". You can get clear any of your doubts instantly through our official WhatsApp group where we have 150+ experienced developers to help you to continue learning without stops, You will receive the group link through mail once you enrolled to any course. If you still have any queries, you can contact us through <a
          className="text-decoration-none text-success"
          href="https://api.whatsapp.com/send?phone=+918328664336&amp;text=Hello Venkatesh! I'm interested to enroll/purchase your courses. I have few queries."
          target="_blank">
          <i className="fa fa-whatsapp text-success" /> WhatsApp
        </a>.<br /><br />
        </p>
        <Footer/>
    </div>
  );
}
