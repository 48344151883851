import React, { lazy } from "react";
import SideMenu from './SideMenu';
import { Switch } from "react-router-dom";
import PublicRoute from "../../common/PublicRoute";
const Enrollments = lazy(() => import("./Enrollments"));
const TutorsDashboard = lazy(() => import("./TutorsDashboard"));
const Transactions = lazy(() => import("./Transactions"));
const QuizAndPolls = lazy(() => import("./QuizAndPolls"));

export default function TutorsRoutes() {
  return (
    <div className="container-fluid">
      <div className="row">
        <SideMenu />
        <div className="col-sm p-3 min-vh-100">
          <Switch>
            <PublicRoute
              path="/tutors"
              component={TutorsDashboard}
              exact
            />
            <PublicRoute
              path="/tutors/enrollments"
              component={Enrollments}
              exact
            />
            <PublicRoute
              path="/tutors/transactions"
              component={Transactions}
              exact
            />
            <PublicRoute
              path="/tutors/quiz-and-polls"
              component={QuizAndPolls}
              exact
            />
          </Switch>
        </div>
      </div>
    </div>
  )
}
