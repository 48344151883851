import React from 'react'
import { Link } from 'react-router-dom';
import "./SideMenu.css";

export default function SideMenu() {
  return (
    <div className="col-sm-auto bg-light sticky-top vm-side-menu">
      <div className="d-flex flex-sm-column flex-row flex-nowrap bg-light align-items-center sticky-top">
        <a href="/" className="d-block p-3 link-dark text-decoration-none text-center" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Icon-only">
          <i className="fa fa-book fs-1 vm-heading-icon"></i>
          <h4 className='vm-heading'><b>VM Learning</b></h4>
        </a>
        <ul className="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto text-center justify-content-between w-100 px-3 align-items-center learning-nav-items">
          <li className="nav-item">
            <Link to="/learning" className="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Home">
              <p><i className="fa fa-heart fs-1 text-danger"></i></p>
              <p className='vm-menu-option'>Learn</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/learning/leaderboards" className="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Home">
              <i className="fa fa-dashboard fs-1"></i>
              <p className='vm-menu-option'>Leaderboards</p>
            </Link>
          </li>
          <li className='nav-item'>
            <Link to="/learning/mycourses" className="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
              <i className="fa fa-laptop fs-1 text-success"></i>
              <p  className='vm-menu-option'>My Courses</p>
            </Link>
          </li>
          <li className='nav-item'>
            <Link to="/learning/tasks" className="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Orders">
              <i className="fa fa-list fs-1 text-warning"></i>
              <p  className='vm-menu-option'>Tasks</p>
            </Link>
          </li>
          <li className='nav-item'>
            <Link to="/learning/competitions" className="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Products">
              <i className="fa fa-desktop fs-1 text-info"></i>
              <p  className='vm-menu-option'>Competitions</p>
            </Link>
          </li>
          <li className='nav-item'>
            <Link to="/learning/community" className="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Customers">
              <i className="fa fa-users fs-1 text-secondary"></i>
              <p  className='vm-menu-option'>Community</p>
            </Link>
          </li>
        </ul>
        {/* Profile not mandatory now */}
        {/* <div className="dropdown vm-profile">
          <a href="/#" className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle" id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fa fa-user-circle h2"></i>
          </a>
          <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser3">
            <li><a className="dropdown-item" href="/#">New project...</a></li>
            <li><a className="dropdown-item" href="/#">Settings</a></li>
            <li><a className="dropdown-item" href="/#">Profile</a></li>
          </ul>
        </div> */}
      </div>
    </div>
  )
}
