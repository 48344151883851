import React from 'react'
import { timeFrameCal } from './timeFrameCal'

export default function TimeFrames({ lectureDetails, showMore, setShowMore, setTimeFrame }) {
  const [showFrame, setShowFrame] = React.useState(false);
  return (
    <div className='timeframes'>
      {lectureDetails !== null && lectureDetails.chapters && lectureDetails.chapters.length > 0 && <div className='bg-dark rounded text-white p-2'>
        <div className='timeframes3 d-flex justify-content-between align-items-center'>
          <h4>Chapters: </h4>
          <i className={`fa fa-${showFrame ? 'times' : 'info-circle'} ${showFrame ? 'infoicon' : ''} text-white h4 cursor-pointer`} onClick={() => setShowFrame(!showFrame)}></i>
        </div>
        {showFrame ? <div className='timeframes-height'>{lectureDetails.chapters && lectureDetails.chapters.length > 0 && lectureDetails.chapters.split("\n").length > 0 && lectureDetails.chapters.split("\n").slice(0, showMore ? lectureDetails.chapters.split("\n").length : 11).map((chapter, index) => (
          <>
            <div className='d-flex align-items-center'>
              <button className='bg-dark rounded  shadow border-0 text-primary cursor-pointer font-weight-bold' onClick={() => setTimeFrame(timeFrameCal(chapter.split(" ")[0]))}> {chapter.split(" ")[0]}</button>
              <small className='m-2 cursor-pointer' onClick={() => setTimeFrame(timeFrameCal(chapter.split(" ")[0]))}> {chapter.split(/\s(.*)/s)[1]}</small>
            </div>
            {index === 10 && !showMore && <button className='btn btn-light rounded' onClick={() => setShowMore(true)}>Show More <i className='fa fa-angle-down' /></button>}
            {index === lectureDetails.chapters.split("\n").length - 1 && showMore && <button className='btn btn-light rounded' onClick={() => setShowMore(false)}>Show Less <i className='fa fa-angle-up' /></button>}
          </>
        ))}</div> : null}
      </div>}
    </div>
  )
}
