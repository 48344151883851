import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PurchasePopup from "../../common/PurchasePopup";
import VMModal from "../../common/VMModal";

export default function PurchaseOption2({
  to = "",
  enrollto = "",
  isPurchase = false,
  isEnroll = false,
  amount = 0,
  discount = 0,
  original = 0,
  enrollamount = 0,
  enrolloriginal = 0,
}) {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  useEffect(
    () => {
      window.scrollTo(0, 0);
      let mobile = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase());
      if (mobile) {
        setShow(true);
      } else {
        setShow(false);
      }
    },
    [show],
  );
  return (
    <div className="d-flex flex-row flex-wrap mb-2">
      {isEnroll && (
        <Link to={enrollto} style={{ cursor: "pointer" }} className="text-center btn btn-primary w-100">
          <i className="fa fa-laptop" /> {show ? "ENROLL NOW" : "ENROLL FOR TRAINING NOW"} (Rs.{enrollamount.toLocaleString('en-IN')}/-){" "}
          <sub>
            <del>{enrolloriginal}</del>
          </sub>
        </Link>
      )}
      &nbsp;&nbsp;
      {isPurchase && (
        <div
          onClick={() => {
            history.push(to)
          }} style={{ cursor: "pointer" }} className="text-center btn btn-primary w-100">
          <i className="fa fa-shopping-cart" /> BUY NOW (Rs.{parseInt(original - ((original / 100) * discount)).toLocaleString('en-IN')}/-){" "}
          <sub>
            <del>{parseInt(original).toLocaleString('en-IN')}</del>
          </sub>
        </div>
      )}

      {showPayment && <VMModal proceed={false} size="lg" title="Payment and Confirm Order" component={<PurchasePopup course={to.split("/purchase/")[1]} onClose={setShowPayment} />} onClose={setShowPayment} />}
    </div>
  );
}
