import React, { useEffect, useState } from "react";
import PleaseWait from "../../common/PleaseWait";
import { api, AuthEmail, baseURL, logoutUser, Validator } from "../../utilities";

export default function DeleteAccount() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [otp, setOTP] = useState("");
  const [, forceUpdate] = useState(false);
  const validator = Validator();

  const submitLogin = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {      
      let token = localStorage.getItem("token");
      setLoading(true);
      let user = api(baseURL + "/deleteAccount", "POST", { "content-type": "application/json",Authorization: token }, { otp });
      user
        .then((data) => {
          if (data.success) {
            setMessage("Account Deleted Successfully!");
            setLoading(false);
            setTimeout(() => {
              logoutUser();
            }, 1000);
          } else {
            setMessage("Invalid OTP");
            setLoading(false);
          }
        })
        .catch((error) => {
          setMessage("Something went wrong!");
          setLoading(false);
        });
    } else {
      validator.current.showMessages();
      forceUpdate(true);
    }
  };

  useEffect(() => {
    if (AuthEmail) {
      let token = localStorage.getItem("token");
      setLoading(true);
      let user = api(baseURL + "/sendOTP", "POST", { "content-type": "application/json",Authorization: token }, { email: AuthEmail });
      user
        .then((data) => {
          if (data.success) {
            setMessage("Please check your mail for OTP!");
            setLoading(false);
          } else {
            setMessage("Invalid Account");
            setLoading(false);
          }
        })
        .catch((error) => {
          setMessage("Something went wrong!");
          setLoading(false);
        });
    } else {
      setMessage("Please Login To Delete Your Account!");
    }
  }, [])

  return (
    <div>
      <div className="container-fluid text-center custom-bg2 vh-100">
        <div className="row justify-content-center align-items-center m-0 p-0 pt-4">
          <div className="col-md-5 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
            <form onSubmit={submitLogin}>
              <i className="fa fa-user-times" style={{ fontSize: 200, color: "orange" }} />
              <h1 className="mb-4">Delete Account</h1>
              <input
                type="number"
                placeholder="Enter 6 Digit OTP"
                className="form-control"
                value={otp}
                // required
                onChange={(e) => setOTP(e.target.value)}
                onFocus={() => setMessage("")}
              />

              {validator.current.message("OTP ", otp, "required|min:6|max:6")}
              {<span>{message}</span>}
              <br />
              {!loading && <input type="submit" value="Delete Account" className="btn btn-danger w-50" />}
              {loading && <PleaseWait content={"Please wait..."} />}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
