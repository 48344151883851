import React from "react";
import { Link } from "react-router-dom";

export default function OnlineTests() {
  return (
    <div className="container-fluid text-center custom-bg2 rounded vh-100 pt-4">
      <h1 className="mb-4 text-white rounded">
        <b className="text-warning">VM</b> Training Online Tests
      </h1>
      <p
        className="text-capitalize font-weight-bold text-white"
        style={{ fontFamily: "Century Gothic", fontWeight: "bold" }}>
        You can test your knowledge before taking any course by using this online tests.<br />
        <i>Username:  <b className="text-warning">Your Email Address</b>, Password: <b className="text-warning">TestUser@123</b></i>
      </p>
      <div className="row justify-content-center align-items-center">
        <div className="col-md-3 col-xs-12 m-3 border rounded shadow bg-dark text-white">
          <img src="/images/new.gif" className="new-image" alt="new img" />
          <br />
          <img src="/images/html.png" className="course-image" alt="Course img" />
          <h5 className="pb-2 pt-2">HTML5 Online Test</h5>

          <Link to="/onlinetest/HTML Test/course">
            <button className="text-center btn btn-primary w-100 mb-2"><i className="fa fa-laptop" /> Write Test</button>
          </Link>
          <br />
          <Link to="/html5">
            <button className="text-center btn btn-primary w-100 mb-2"><i className="fa fa-book" /> View Course Details</button>
          </Link>
        </div>
        <div className="col-md-3 col-xs-12 m-3 border rounded shadow bg-dark text-white">
          <img src="/images/new.gif" className="new-image" alt="new img" />
          <br />
          <img src="/images/css3.png" className="course-image" alt="Course img" />
          <h5 className="pb-2 pt-2">CSS3 Online Test</h5>
          <Link to="/onlinetest/CSS Test/course">
            <button className="text-center btn btn-primary w-100 mb-2"><i className="fa fa-laptop" /> Write Test</button>
          </Link>
          <Link to="/css3">
            <button className="text-center btn btn-primary w-100 mb-2"><i className="fa fa-book" /> View Course Details</button>
          </Link>
        </div>
        <div className="col-md-3 col-xs-12 m-3 border rounded shadow bg-dark text-white">
          <img src="/images/new.gif" className="new-image" alt="new img" />
          <br />
          <img src="/images/jscourse.png" className="course-image" alt="Course img" />
          <h5 className="pb-2 pt-2">JavaScript Online Test</h5>
          <Link to="/onlinetest/JavaScript Test/course">
            <button className="text-center btn btn-primary w-100 mb-2"><i className="fa fa-laptop" /> Write Test</button>
          </Link>
          <Link to="/javascript">
            <button className="text-center btn btn-primary w-100 mb-2"><i className="fa fa-book" /> View Course Details</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
