import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { api } from "../utilities/api";
import { baseURL } from "../utilities/baseurl";
import packageJson from "../../package.json";
import { getBuildDate } from "../utilities/validations";

export default function MoreInfo() {
  const [views, setViews] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    api(baseURL + "/getViews", "GET", {}, {})
      .then((data) => {
        setViews(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);
  return (
    <nav className="navbar-dark bg-dark custom-bg2" style={{ borderTop: "10px solid lightgray" }}>
      <div className="mt-4 pb-4 container col-xs-12 text-center d-flex flex-wrap justify-content-lg-between justify-content-center">
          <Link to="/about" className="nav-link">
            <li className="nav nav-link btn btn-outline-info text-white">
              <i className="fa fa-users" /> About Us
            </li>
          </Link>
          <Link to="/terms-and-conditions" className="nav-link">
            <li className="nav nav-link btn btn-outline-info text-white">
              <i className="fa fa-info-circle" /> Terms & Conditions
            </li>
          </Link>
          <Link to="/refund-policy" className="nav-link">
            <li className="nav nav-link btn btn-outline-info text-white">
              <i className="fa fa-info-circle" /> Refund Policy
            </li>
          </Link>
          <Link to="/contact" className="nav-link">
            <li className="nav nav-link btn btn-outline-info text-white">
              <i className="fa fa-whatsapp" /> Contact Us
            </li>
          </Link>
          <Link to="/privacy-policy" className="nav-link">
            <li className="nav nav-link btn btn-outline-info text-white">
              <i className="fa fa-shield" /> Privacy Policy
            </li>
          </Link>
      </div>
    </nav>
  );
}
