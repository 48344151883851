import React, { useState } from "react";
import { addToStorage } from "../../utilities/localStorage";
import { testEmail } from "../../redux/constants/courseConstants";

export default function QuizIntro({ start, loading, status, startTest, error, quizName, questions }) {
  const [email,] = useState(localStorage.getItem("testEmail") != null ? localStorage.getItem("testEmail") : "")
  return (
    <div className="text-center container">
      <div className="d-flex justify-content-center align-items-center">
        <div className={`d-flex justify-content-center container align-items-center h250 col-md-8 border p-lg-5 m-lg-3 rounded text-white bg-${(!loading && status) ? 'success' : 'white'}`}>
          {!error ? !start &&
            <form>
              <i className="fa fa-pencil text-primary fs-50" />
              <br />
              <b className="text-primary h2">{quizName}<br /><br />
                Total No.Of Questions {questions && questions.length} <br />
                Each Question Carries 1 Mark.<br />
                Total Test Duration 10 minutes.</b>
              <br />
              <i className="text-danger"> Only one attempt. Don't close the tab/ don't refresh the page once you click start test.</i>
              <br />
              <button
                className="cursor-pointer btn btn-primary m-2"
                type="button"
                onClick={() => {
                  addToStorage(testEmail,email);
                  startTest();
                }}
                disabled={(loading || !email)}>
                {loading ? "Sending" : "Start"} Test
              </button>
            </form> :
            <h2 className="text-danger">{error}</h2>
          }
        </div>
      </div>
    </div>
  )
}
