import React, { useState, useEffect } from 'react';
import './LiveSessionArea.css';
import { eveningBatchUsers, recordedUsers, regularBatchUsers } from '../auth/Login';
import { AuthEmail, AuthToken, handleLink } from '../../utilities';
import { quizConfig } from './constants';

const LiveSessionArea = ({ sessionTime, topics, isLive, isCompleted, title, joinLiveClass, scheduledQuestions,linkStatus,courseType }) => {
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [email, setEmail] = useState('');
  const isAdmin = AuthToken && AuthEmail === quizConfig.adminEmail;

  // Calculate countdown time difference
  const calculateTimeLeft = () => {
    const now = new Date();
    const targetTime = new Date(sessionTime);
    const difference = targetTime - now;

    let timeLeft = {};
    if (difference > 0) {
      const daysInHours = (Math.floor(difference / (1000 * 60 * 60 * 24)) * 24);
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24) + parseInt(daysInHours),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [sessionTime]);

  const liveQuestionId = scheduledQuestions.length > 0 ? scheduledQuestions.find((q) => q.isEnabled && q.question.includes("Live Class"))?._id : false;

  const isCompletedTask=(questionId)=>{
    return linkStatus.length>0?linkStatus.findIndex((a)=>a.questionId===questionId)!==-1:false;
  }

  return (
    <div className="container-fluid">
      {/* section-1 */}
      <div className='row p-2 section-1 bg-teal rounded'>
        {isLive && <div className={`col-md-8 p-0 col-xs-12 col-sm-12 text-center bg-black rounded text-white live-section`}>
          {/* Animated Live Symbol */}
          <div className="live-symbol">
            <span className="live-dot"></span>
            <span className="live-text">LIVE</span>
          </div>

          {/* Countdown Timer */}
          {timeRemaining && timeRemaining.seconds >= 0 && (
            <div className="countdown-timer">
              {timeRemaining.hours}:{timeRemaining.minutes}:{timeRemaining.seconds}
            </div>
          )}

          {/* Join Session Button */}
          {(!courseType && !recordedUsers.includes(AuthEmail)) && <button className={`join-live-btn ${!liveQuestionId?'disabled':''}`} onClick={() => joinLiveClass(liveQuestionId, 'https://meet.google.com/vkz-nkzj-rmk', "live_class")} disabled={!liveQuestionId}>
            Join Live Session
          </button>}

        </div>}

        {/* Upcoming Topics */}
        <div className={`col-md-${isLive ? '4' : '12'} p-0 col-xs-12 col-sm-12 bg-teal rounded text-white p-2`}>
          <h4 className='text-white'>{isCompleted ? '✅' : '🧑‍💻'} {isCompleted ? 'Topics Learned' : 'Upcoming Topics'}</h4>
          <ul>
            {topics.map((topic, index) => (
              <li key={index} className='text-white'>{topic}</li>
            ))}
          </ul>
        </div>
      </div>

      <h5 className='mt-3 mb-3'><b>{title} {isLive ? "(Today's Focus)" : ""}</b></h5>
      <div className='d-flex justify-content-between align-items-start flex-wrap'>
        <div className='d-flex'>
          <img src={"https://vmtraining.netlify.app/logo192.png"} alt="instructor" className='instructor me-2' />
          <div className='me-2'>
            <small>Instructor</small>
            <p><b>Venkatesh Mogili</b></p>
          </div>
        </div>
      </div>

      {(isLive || isCompleted) && scheduledQuestions.length > 0 &&
        <div className='bg-teal rounded p-3 text-left'>
          <h5 id="resources" className='text-white'>Resources:</h5>
          {scheduledQuestions.map(({options,question,_id:questionId}, index) => (
            <React.Fragment key={index}>
              {options[1].value && question.includes("Prerequisite") && <ResourceLink link={options[1].value} title='Prerequisite' icon="file-video-o" id={questionId} questionType="prerequisites" joinLiveClass={joinLiveClass} isCompleted={isCompletedTask(questionId)} score={100}/>}

              {(!courseType && !recordedUsers.includes(AuthEmail)) && options[1].value && question.includes("Live Class") && <ResourceLink link={options[1].value} title='Live Class' icon="podcast" id={questionId} questionType="live_class" joinLiveClass={joinLiveClass} isCompleted={isCompletedTask(questionId)} score={500}/>}

              {options[1].value && question.includes("Recorded Class") && <ResourceLink link={options[1].value} title='Recorded Video' icon="youtube-play" id={questionId} questionType="recorded_class" joinLiveClass={joinLiveClass} isCompleted={isCompletedTask(questionId)} score={500}/>}

              {options[1].value && question.includes("Source Code") && <ResourceLink link={options[1].value} title='Source Code' icon="code" id={questionId} questionType="source_code" joinLiveClass={joinLiveClass} isCompleted={isCompletedTask(questionId)} score={100}/>}

              {options[1].value && question.includes("Presentation") && <ResourceLink link={options[1].value} title='Presentation' icon="file-pdf-o" id={questionId} questionType="presentation" joinLiveClass={joinLiveClass} isCompleted={isCompletedTask(questionId)} score={100}/>}

              {options[1].value && question.includes("Offline Quiz") && <ResourceLink link={options[1].value} title='Quiz' icon="question-circle" id={questionId} questionType="offline_quiz" joinLiveClass={joinLiveClass} isCompleted={isCompletedTask(questionId)} score={100}/>}

              {options[1].value && question.includes("Practice Task") && <ResourceLink link={options[1].value} title='Practice Tasks' icon="laptop" id={questionId} questionType="practice_tasks" joinLiveClass={joinLiveClass} isCompleted={isCompletedTask(questionId)} score={200}/>}

              {options[1].value && question.includes("Exercise Task") && <ResourceLink link={options[1].value} title='Exercise' icon="bolt" isCompleted={isCompletedTask(questionId)} score={2000}/>}

              {!courseType && options[1].value && question.includes("Doubts") && <ResourceLink link={options[1].value} title='Doubts' icon="commenting" id={questionId} questionType="doubts" joinLiveClass={joinLiveClass} isCompleted={isCompletedTask(questionId)} score={100}/>}
              {/* admin controls start */}
              {isAdmin && <>
              {/* {options[1].value && question.includes("Practice Task") && <ResourceLink title='Approve Task' icon="check-circle" id={questionId} questionType="practice_tasks" joinLiveClass={joinLiveClass} email={email} isAdmin={isAdmin} />} */}
              {options[1].value && question.includes("Exercise Task") && <ResourceLink title='Approve Exercise' icon="check-circle" id={questionId} questionType="exercise_task" joinLiveClass={joinLiveClass} email={email} isAdmin={isAdmin}/>}
              {(options[1].value && question.includes("Exercise Task")) && <select className="form-select mt-2" aria-label="Select Approval Status" value={email} onChange={(e) => setEmail(e.target.value)}>
                {[...regularBatchUsers, ...eveningBatchUsers].map((user, index) => (
                  <option key={index} value={user}>{user}</option>
                ))}
              </select>}
              </>}

              {/* admin controls end */}
            </React.Fragment>
          ))}
        </div>
      }
    </div>
  );
};

export default LiveSessionArea;

export const ResourceLink = ({ link, title, icon, joinLiveClass, id, questionType, email,isAdmin,isCompleted=false,score }) => {
  const openLink = (link) => {
    if (!link && !isAdmin) return;
    if (joinLiveClass) {
      joinLiveClass(id, link, questionType, email);
    } else {
      handleLink(link);
    }
  }
  return (
    <button
      className='btn btn-light download-source me-2 mt-1 mb-1'
      onClick={() => openLink(link)}
      disabled={!link && !isAdmin}
    >
      <i className={`fa fa-${isCompleted?'check-circle text-success':icon}`} /> {title} {score?'('+score+' XP)':''}
    </button>
  );
}