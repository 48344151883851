import React from 'react'
import PlaceholderLoading from 'react-placeholder-loading'

export default function CourseLoader({ height = 80, width = "100%", shape = "rect", length = 6 }) {
  return (
    <div className='d-flex flex-wrap rounded'>
      {new Array(length).fill(null).map((item, index) => (
        <div className='col-md-2 mt-2 mb-2 text-center course-item rounded' key={index}>
          <div className='card shadow m-2 rounded'>
            <PlaceholderLoading shape={shape} width={width} height={height} />
          </div>
        </div>
      ))}
    </div>
  )
}
