import React from 'react'
import { Link } from 'react-router-dom'
import { useCourseLinks } from '../../useCourses'

export default function EnrollOptions({ location }) {
  const { courselink, courseCurriculum } = useCourseLinks(location);
  return (
    <div className='d-flex bg-danger justify-content-center enrollOption'>
      <Link className='d-flex align-items-center w-50 cursor-pointer bg-danger justify-content-center text-white text-decoration-none' to={"/purchase/" + courselink}>
        <i className={`fa fa-shopping-cart text-white`} />
        <p className='m-0 p-2'>Buy Now</p>
      </Link>
      <Link className='d-flex align-items-center w-50 cursor-pointer bg-light justify-content-center text-dark text-decoration-none' to={"/" + courselink}>
        <i className={`fa fa-book text-success`} />
        <p className='m-0 p-2'>Course Features</p>
      </Link>
      <a className='d-flex align-items-center w-50 cursor-pointer bg-success justify-content-center text-white text-decoration-none' href={courseCurriculum} target="_blank">
        <i className={`fa fa-book text-white`} />
        <p className='m-0 p-2'>Syllabus</p>
      </a>
    </div>
  )
}
