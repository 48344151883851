import React from 'react'
import { Link } from 'react-router-dom';
import "./SideMenu.css";

export default function SideMenu() {
  return (
    <div className="col-sm-auto bg-light sticky-top vm-side-menu">
      <div className="d-flex flex-sm-column flex-row flex-nowrap bg-light align-items-center sticky-top">
        <a href="/talent" className="d-block p-3 link-dark text-decoration-none text-center" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Icon-only">
          <i className="fa fa-rocket fs-1 vm-heading-icon"></i>
          <h4 className='vm-heading'><b>VM Online Training <br/> <small className='text-info'>Talent Dashboard</small></b></h4>
        </a>
        <ul className="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto text-center justify-content-between w-100 px-3 align-items-center learning-nav-items">
          <li className="nav-item">
            <Link to="/talent" className="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Home">
            <i className="fa fa-dashboard fs-1"></i>
            <p className='vm-menu-option'>Dashboard</p>
            </Link>
          </li>
          <li className='nav-item'>
            <Link to="/talent/tasks" className="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Orders">
              <i className="fa fa-laptop fs-1 text-warning"></i>
              <p  className='vm-menu-option'>Tasks</p>
            </Link>
          </li>
        </ul>
        {/* Profile not mandatory now */}
        <div className="dropdown vm-profile">
        <ul className="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto text-center justify-content-between w-100 px-3 align-items-center learning-nav-items">
        <li className='nav-item'>
            <Link to="/vm/vmcourses" className="nav-link py-3 px-2">
              <i className="fa fa-book fs-1 text-info"></i>
              <p  className='vm-menu-option'>Courses</p>
            </Link>
          </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
