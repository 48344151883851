import React, { useState } from 'react'
import TimeFrames from '../VideoDetails/TimeFrames';
import { useSelector } from 'react-redux';
import "./style.css";

export default function Player({ id, size, timeframe = 0, setTimeFrame }) {
  const [showMore, setShowMore] = useState(false);
  const { lectureDetails } = useSelector(store => store.course);
  return (
    <div className={`col-md-${size} p-0 col-xs-12 col-sm-12 bg-dark`}>
      <div className={`embed-responsive embed-responsive-16by9 relative infoicon`}>
        <div className="holder">
          <div className="frame" id="player">
            <iframe src={`https://www.youtube.com/embed/${id}?rel=0&start=${timeframe}&modestbranding=1&showinfo=1&autoplay=1&autohide=2`} className="embed-responsive-item vid col-md-12 col-xs-12 col-sm-12 w-100" title="VM video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </div>
          <div className="bar" id="bottom-layer"></div>
        </div>
        <TimeFrames lectureDetails={lectureDetails} showMore={showMore} setShowMore={setShowMore} setTimeFrame={setTimeFrame} />
      </div>
      {/*<ReactWebMediaPlayer
        title="Big Buck Bunny - 2008"
        thumbnail="https://i.ytimg.com/vi/aqz-KE-bpKQ/maxresdefault.jpg"
        video="https://nusid.net/video.mp4"
        logo={{
          img: "https://vmtraining.netlify.app/images/logo.png",
          href: "https://vmtraining.netlify.app"
        }}
        allowFullFrame={true}
        autoplay={false}
        buttons={[
          { img: 'https://www.oiml.org/en/ressources/icons/download-icon.png/@@download/image/download_icon.png', style: { width: 10, height: 10 }, callback: () => alert('You like!') },
          { img: 'https://vmtraining.netlify.app/images/logo.png', style: { width: 10, height: 10 }, href: 'http://google.fr' }
        ]}
        color='#912654'
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
        volume={0.5}
      />*/}
    </div >
  )
}
