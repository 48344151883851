import React from "react";
import Counter from "./Counter";

export default function Statistics() {
  return (
    <div className="container border p-2 bg-light rounded mt-4 mb-4">
      <h3 className="text-warning"><i className="fa fa-line-chart" /> VM Training Courses and Material:</h3>
      <div className="p-0 m-0 row justify-content-center align-items-center">
        <div
          className="card col-lg-4 col-md-12 col-sm-12 col-xs-12 m-2 mt-2 mb-2 bg-primary text-white text-center pt-3"
          style={{ height: 100 }}>
          <Counter end={10} /> Courses
        </div>
        <div
          className="card col-lg-4 col-md-12 col-sm-12 col-xs-12 m-2 mt-2 mb-2 bg-primary text-white text-center pt-3"
          style={{ height: 100 }}>
          <Counter end={6000} /> Enrolls
        </div>
        {/* <div
          className="card col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-2 mb-2 bg-success text-white text-center"
          style={{height: 100}}>
          <Counter end={32} /> Videos, Quizzes, Presentations, Source Codes
        </div> */}
        <div
          className="card col-lg-3 col-md-12 col-sm-12 col-xs-12 m-2 mt-2 mb-2 bg-primary text-white text-center pt-3"
          style={{ height: 100 }}>
          <Counter end={175} /> Practical Tasks
        </div>
      </div>
    </div>
  );
}
