import moment from 'moment';
import React, { useState, useEffect } from 'react'
import { api, AuthToken, baseURL, LoadingIndicator } from '../utilities';
import NotificationPopUp from './NotificationPopUp';
export default function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setselectedNotification] = useState({})
  const [onPopupOpen, setonPopupOpen] = useState(false)
  const [OnSearch, setOnSearch] = useState("")
  const [total, setTotal] = useState(5);
  const [initial, setInitial] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    api(baseURL + "/getAllNotifications", "GET", { Authorization: AuthToken }, {})
      .then((data) => {
        setNotifications(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [])
  return (
    <div className="container">
      {loading ? <div className="text-center m-5">
        <LoadingIndicator color="warning" size={"3"} />
      </div> :
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h1 className='text-white'>Notifications</h1>
            <input type="text" placeholder="Search Notification" value={OnSearch} onChange={(e) => setOnSearch(e.target.value)} className="form-control w-50" />
          </div>
          <ol className="list-group list-group-vertical cursor-pointer">
            {notifications.length > 0 ? notifications
              .slice(initial, total)
              .filter(notify => notify.title.toLowerCase().indexOf(OnSearch.toLowerCase()) > -1
                || notify.description.toLowerCase().indexOf(OnSearch.toLowerCase()) > -1).map(notification => (

                  <li className="list-group-item li-hover align-items-center" onClick={() => {
                    setonPopupOpen(true);
                    setselectedNotification(notification);
                  }}>{notification.title}<br /> {notification.description}
                    <small className="pull-right">{moment(notification.updatedAt).format("DD-MM-YYYY hh:MM A")}</small>
                  </li>

                )) : <h2 className="text-danger text-center">No Notifications Found!</h2>}
          </ol>
          <NotificationPopUp notification={selectedNotification} onPopupOpen={onPopupOpen} onClosePopup={() => setonPopupOpen(false)} />
          {notifications.length > 4 && <nav className="m-3">
            <ul className="pagination justify-content-center">
              <li className={initial === 0 ? "page-item disabled" : "page-item cursor-pointer"}>
                <span
                  className="page-link"
                  tabIndex="-1"
                  disabled={prevIndex === 0}
                  onClick={() => {
                    if (initial > 0) {
                      setInitial(initial - 5);
                      setTotal(total - 5);
                      setPrevIndex(prevIndex - 1);
                    } else {
                      setPrevIndex(0);
                    }
                  }}>
                  Previous
                </span>
              </li>
              {Array.apply(null, Array(Math.round(notifications.length / 5))).map((x, i) => (
                <li className="page-item cursor-pointer">
                  <span
                    className={`page-link ${Math.round(total / 5) === i + 1 ? "bg-primary text-white" : ""}`}
                    onClick={(e) => {
                      setTotal(5 * parseInt(e.target.innerHTML));
                      setInitial(5 * parseInt(e.target.innerHTML) - 5);
                    }}>
                    {i + 1}
                  </span>
                </li>
              ))}
              <li className={total >= notifications.length ? "page-item disabled" : "page-item cursor-pointer"}>
                <span
                  className="page-link"
                  disabled={nextIndex === notifications.length}
                  onClick={() => {
                    if (total < notifications.length) {
                      setTotal(total + 5);
                      setInitial(initial + 5);
                      setNextIndex(nextIndex + 1);
                    } else {
                      setNextIndex(notifications.length);
                    }
                  }}>
                  Next
                </span>
              </li>
            </ul>
          </nav>}
        </div>}
    </div>
  )
}
