import React from "react";
import { LoadingIndicator } from "./Loader";

export default function PleaseWait({ content }) {
  return (
    <button type="button" className="btn btn-secondary w-100 rounded text-white" disabled>
      {content} <LoadingIndicator size={"1"} />
    </button>
  );
}
