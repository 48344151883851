import React, { useEffect, useState } from 'react'
import { api, AuthToken, baseURL, LoadingIndicator } from '../../utilities';
import { eveningBatchUsers, regularBatchUsers } from '../auth/Login';
import { useHistory } from 'react-router-dom';
import { levels, levelThresholds } from './constants';
import moment from 'moment';

function Leaderboard({ scores, setScores, isAdmin, isRegular, filteredQuiz,courseType }) {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('overall');
  const [selectedWeek, setSelectedWeek] = useState('week-2');
  const [selectedDay, setSelectedDay] = useState('Day-10');
  const history = useHistory();
  const isToday=moment(new Date()).format('YYYY-MM-DD');

  const weekRanges = {
    'week-1': { start: '2024-11-01', end: '2024-11-10' },
    'week-2': { start: '2024-11-11', end: '2024-11-17' },
    'week-3': { start: '2024-11-18', end: '2024-11-24' },
  };


  const dayRanges = {
    'Day-1': { start: '2024-11-04', end: '2024-11-05' },
    'Day-2': { start: '2024-11-05', end: '2024-11-06' },
    'Day-3': { start: '2024-11-06', end: '2024-11-07' },
    'Day-4': { start: '2024-11-07', end: '2024-11-08' },
    'Day-5': { start: '2024-11-08', end: '2024-11-09' },
    'Day-6': { start: '2024-11-09', end: '2024-11-10' },
    'Day-7': { start: '2024-11-11', end: '2024-11-12' },
    'Day-8': { start: '2024-11-12', end: '2024-11-13' },
    'Day-9': { start: '2024-11-13', end: '2024-11-14' },
    'Day-10': { start: '2024-11-14', end: '2024-11-15' },
    'Day-11': { start: '2024-11-15', end: '2024-11-16' },
    'Day-12': { start: '2024-11-16', end: '2024-11-17' },
    'Day-13': { start: '2024-11-18', end: '2024-11-19' },
    'Day-14': { start: '2024-11-19', end: '2024-11-20' },
    'Day-15': { start: '2024-11-20', end: '2024-11-21' },
    'Day-16': { start: '2024-11-21', end: '2024-11-22' },
    'Day-17': { start: '2024-11-22', end: '2024-11-23' },
    'Day-18': { start: '2024-11-23', end: '2024-11-24' },
    'Day-19': { start: '2024-11-24', end: '2024-11-25' },
  };

  const fetchScores = () => {
    if (AuthToken) {
      let start,end=null;
      if(filter==="weekly"){
        start=weekRanges[selectedWeek].start;
        end=weekRanges[selectedWeek].end;
      } else if(filter==="daily"){
        start=dayRanges[selectedDay].start;
        end=dayRanges[selectedDay].end;
      }

      const filterData = filter !=="overall" ? {
        startDate: start,
        endDate: end
      } : {
        startDate: '2024-11-04',
        endDate: '2024-11-24'
      }
      setLoading(true);
      api(baseURL + '/getScoresTraining', 'POST', { "content-type": "application/json", Authorization: AuthToken }, {
        questionsCategory: {
          quizName: 'React Online Training',
          isAdmin,
          enrolledUsers: isRegular ? regularBatchUsers : eveningBatchUsers,
          ...filterData
        },
      })
        .then(response => {
          if (response.success) {
            setLoading(false);
            setScores(response.data);
          } else {
            console.log("Something went wrong!");
            setLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
        })
    } else {
      if(courseType){
        history.push("/login?backlog=/vmlive/react-2024/recorded");
        } else{
        history.push("/login?backlog=/vmlive/react-2024-" + (isRegular ? 'regular' : 'evening'));
        }
    }
  }

  useEffect(() => {
    fetchScores();
  }, [window.location.pathname, filteredQuiz, filter, selectedWeek,selectedDay]);

  let sortedScores = [...scores].sort((a, b) => b.score - a.score);
  let rank = 1;
  let previousScore = null;
  let displayRank = 1;

  const isLiveClass = moment(new Date()).isBetween('2024-11-11 16:30:00', '2024-11-11 23:00:00');
  // manual scores
  // update by using mail id.
  const bonusScore = 1000;
  const finalScores = sortedScores.map(score => {
    if ((filter === "overall") || (filter === "weekly" && selectedWeek === "week-1")) {
      if (["v.sunithasankar@gmail.com", "nagarjuna.yadika@gmail.com", "nunna.akhil.6@gmail.com", "rohinipolina0518@gmail.com"].includes(score.email) && !score.bonusApplied) {
        score.score += bonusScore;
        score.bonusApplied = true;
      }
      if (["v.sunithasankar@gmail.com", "nagarjuna.yadika@gmail.com", "nunna.akhil.6@gmail.com", "rohinipolina0518@gmail.com", "kumarbollaboina@gmail.com", "sudanagunta.riteesh@gmail.com", "balakrishna.nudurumati@gmail.com"].includes(score.email) && !score.bonusAppliedSecond) {
        score.score += bonusScore;
        score.bonusAppliedSecond = true;
      }

      if (["rohinipolina0518@gmail.com","v.sunithasankar@gmail.com","httpchary@gmail.com"].includes(score.email) && !score.bonusAppliedThird) {
        score.score += bonusScore*3;
        score.bonusAppliedThird = true;
      }

      if(["nunna.akhil.6@gmail.com","rohinipolina0518@gmail.com","v.sunithasankar@gmail.com","balakrishna.nudurumati@gmail.com"].includes(score.email) && !score.bonusAppliedFourth) {
        score.score += bonusScore;
        score.bonusAppliedFourth = true;
      }

      if(["rohinipolina0518@gmail.com"].includes(score.email) && !score.bonusAppliedFifth) {
        score.score += bonusScore*2;
        score.bonusAppliedFifth = true;
      }
    }
    return score;
  });

  sortedScores = [...finalScores].sort((a, b) => b.score - a.score);
  return (
    <div className='main-content'>
      <div className='border mh-100 bg-leaderboard sticky-board'>
        <h1 className='text-center custom-bg text-white p-2 m-0'>Leaderboard🏆</h1>
        <div className='d-flex'>
          <div className='m-2'>
          <label htmlFor="filter-select">Filter:&nbsp;</label>
          <select
            id="filter-select"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="overall">Overall</option>
            <option value="weekly">Weekly</option>
            <option value="daily">Daily</option>
          </select>
          </div>

        {filter === 'weekly' && (
          <div className='m-2'>
            <select
              value={selectedWeek}
              onChange={(e) => setSelectedWeek(e.target.value)}
            >
              <option value="week-1">Week 1</option>
              <option value="week-2">Week 2</option>
              <option value="week-3">Week 3</option>
            </select>
          </div>
        )}


        {filter === 'daily' && (
          <div className='m-2'>
            <select
              value={selectedDay}
              onChange={(e) => setSelectedDay(e.target.value)}
            >
              {Object.keys(dayRanges).map((day,index)=>(
              <option value={day} key={index}>{day} {moment(new Date(dayRanges[day].start)).format("YYYY-MM-DD")<=isToday?'✅':''}</option>
              ))}
            </select>
          </div>
        )}
        </div>


        {loading ? <div className='bg-dark mh-100 d-flex justify-content-center align-items-center'><LoadingIndicator size={5} /></div> :
          <div className='table-responsive'>
            <table className='table table-striped table-hover'>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Name</th>
                  <th>Level</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                {sortedScores.length > 0 && sortedScores.map((score, index) => {
                  // Step 2: Check if the current score is equal to the previous score
                  if (previousScore !== null && score.score === previousScore) {
                    // If it's a tie, use the same rank as the previous one
                    displayRank = rank;
                  } else {
                    // If it's a new score, update the rank and set it to the current position
                    rank = index + 1;
                    displayRank = rank;
                  }

                  // Step 3: Update previousScore to the current score for the next iteration
                  previousScore = score.score;

                  const level = levels[levelThresholds.findIndex(threshold => score.score <= threshold)] || 1;
                  return (
                    <tr key={index}>
                      <td>{`${displayRank && score.score > 0 ? '#' + displayRank : '-'}${displayRank === 1 && score.score > 0 ? '🥇' : displayRank === 2 && score.score > 0 ? '🥈' : displayRank === 3 && score.score > 0 ? '🥉' : ''}`}</td>
                      <td>{score.name}</td>
                      <td>{level}</td>
                      <td>{parseInt(score.score).toLocaleString('en-IN')} XP{displayRank > 3 ? '🔥' : score.score > 0 ? '💎' : ''}</td>
                    </tr>
                  )
                }
                )}
              </tbody>
            </table>
          </div>}

        {/* {isLiveClass && <UserInteraction/>} */}

        {/* {commonUsers.includes(AuthEmail) &&
          <div>
            <Link to={`/vmlive/react-2024-${window.location.pathname.includes('regular') ? 'evening' : 'regular'}`} className="nav-link text-white"><i className="fa fa-angle-double-right" /> Navigate To {window.location.pathname.includes('regular') ? 'Evening Batch' : 'Regular Batch'}</Link>
          </div>
        } */}
      </div>
    </div>
  )
}

export default React.memo(Leaderboard);