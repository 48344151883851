import React from "react";
import CountUp from "react-countup";

export default function Counter({end}) {
  return (
    <h1>
      <CountUp end={end} duration={5} />+
    </h1>
  );
}
