import React from 'react'
import { courseCategories } from '../../../utilities'
import Slider from "react-slick";

export default function CourseCategories({ selectedCategory = "all", setSelectedCategory }) {
  const settings = {
    className: "center",
    infinite: false,
    centerPadding: "60px",
    slidesToShow: 6,
    slidesToScroll: 4,
    swipeToSlide: true,
    //afterChange: function (index) {
    //  console.log(
    //    `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
    //  );
    //},
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  return (
    <div className='row m-2'>
      <Slider {...settings}>
        {courseCategories.length > 0 && courseCategories.map((category, index) => (
          <div className={`col-lg-2 col-md-12 col-sm-12 col-xs-12 text-center course-category cursor-pointer bg-${selectedCategory === category.value ? 'primary' : 'transparent'} rounded`} key={index} onClick={() => setSelectedCategory(category.value)}>
            <i className={`fa fa-${category.icon} display-6`} />
            <p className='category'>{category.title}</p>
          </div>
        ))}
      </Slider>
    </div>
  )
}
