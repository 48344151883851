import React from "react";
import InstallApp from "../components/InstallApp";
import Footer from "./Footer";

export default function RefundPolicy() {
  return (
    <div className="container-fluid custom-bg2">
      <InstallApp />
      <h1 className="mb-4 pt-4 text-white text-center">Cancellation and Refund Policy</h1>
      <p className="text-white p-5 text-left">
        There is no cancellation/refund policy available once you enrolled to any course. Only discounts will be available before you purchase any of our courses, no cancellation available.
        </p>

<Footer/>
    </div>
  );
}
