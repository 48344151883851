import React from 'react'
import Footer from '../../../common/Footer'
import Jobs from '../Jobs'

export default function JobsContainer() {
  const jobs = [
    //{
    //  expiredDate: "2021-12-31T23:59:59",
    //  category: "INTERNSHIP",
    //  title: "Full Stack Developer Internship Offer",
    //  jobImage: "/images/hiring/fullstack.png",
    //  role: "vmfullstack"
    //},
    //{
    //  expiredDate: "2021-12-31T23:59:59",
    //  category: "INTERNSHIP",
    //  title: "ReactJS Developer Internship Offer",
    //  jobImage: "/images/hiring/reactjs.png",
    //  role: "vmreactjs"
    //},
    //{
    //  expiredDate: "2021-12-31T23:59:59",
    //  category: "INTERNSHIP",
    //  title: "NodeJS Developer Internship Offer",
    //  jobImage: "/images/hiring/nodejs.png",
    //  role: "vmnodejs"
    //},
    //{
    //  expiredDate: "2021-08-05T22:00:00",
    //  category: "INTERNSHIP/ JOB SUPPORT/ FREELANCING/ FULL-TIME/ PART-TIME",
    //  title: "VM Career Star",
    //  jobImage: "/images/vmcareer.png",
    //  role: "vmcareer"
    //},
    //{
    //  expiredDate: "2021-08-05T22:00:00",
    //  category: "INTERNSHIP/ JOB SUPPORT/ FREELANCING/ FULL-TIME/ PART-TIME",
    //  title: "Angular Developers (Freelancing)",
    //  jobImage: "/images/angular.png",
    //  role: "Angular"
    //},
    //{
    //  expiredDate: "2021-08-03T24:00:00",
    //  category: "INTERNSHIP/ JOB SUPPORT/ FREELANCING/ FULL-TIME/ PART-TIME",
    //  title: "React Developers (Freshers)",
    //  jobImage: "/images/resume.png",
    //  role: "React"
    //},
  ]
  return (
    <div className='custom-bg2'>
      <h1 className="text-center text-white custom-bg">
        INTERNSHIP/ JOB SUPPORT/ FREELANCING/ FULL-TIME/ PART-TIME
      </h1>
      {jobs.length === 0 && <h1 className='text-center text-white mh-100'>No Jobs Available Now</h1>}
      {jobs.map((job, index) => (
        <div key={index}>
          <Jobs {...job} />
        </div>
      ))}
      <Footer />
    </div>
  )
}
