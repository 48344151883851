import moment from 'moment';
import React, { useRef, useEffect } from 'react'

export default function NotificationPopUp({ onPopupOpen, notification, onClosePopup }) {
  const btnRef = useRef();
  useEffect(() => {
    if (onPopupOpen) {
      btnRef.current.click();
    }
  }, [onPopupOpen])
  return (
    <div>
      <input type="hidden" className="btn btn-primary" ref={btnRef} data-bs-toggle="modal" data-bs-target="#staticBackdrop" />
      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">{notification.title}</h5>
              <button type="button" className="btn-close" onClick={() => onClosePopup()} data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Hi {localStorage.getItem("username")},</p>
              <p>{notification.description}</p>
              <div className="d-flex justify-content-between flex-wrap">
                <p>Date &amp; Time: {moment(notification.updatedAt).format("DD-MM-YYYY hh:MM A")}</p>
                <p>Post By: {notification.postedby}</p>
              </div>
              {/*<div>
                <p>Attachments: </p>
              </div>*/}

            </div>
            <div className="modal-footer d-flex justify-content-center align-items-center">
              <button className="btn btn-primary" onClick={() => onClosePopup()} data-bs-dismiss="modal" >Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>)
}