import React, { useState } from 'react';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function VMModal({ size = "md", title = "Modal", component, onClose, handleSubmit, proceed = true }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    onClose(null);
  }
  const handleShow = () => setShow(true);
  useEffect(() => {
    handleShow();
  }, [component])

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={size}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {component}
        </Modal.Body>
        {proceed && <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>Proceed</Button>
        </Modal.Footer>}
      </Modal>
    </>
  );
}