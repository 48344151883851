import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthToken } from '../../utilities';
import { openPlayer } from '../VMVideoPlayer/useCourses';

export default function SingleCourse({ index = "", isNew = false, isNewCourse = false, courselink = "", image1 = "", image2 = "", title = "", demolink = "", price = "", discount = "", courseType = "Premium", courses, selectedCourse, removeFromCart, addToCart, setTo, setShowPayment,enrolledCourses,_id,comboCourses,enrolledIds,isHide }) {
  const history = useHistory();
  const isLoggedIn = AuthToken !== null;
  const isEnrolledSingle = enrolledCourses.findIndex(course => {
    return course._id === _id
  })!==-1;
  const isEnrolledToCombo = comboCourses.findIndex(course =>
    enrolledIds.some(item => course.courseCombo.includes(item))
  )!==-1;
  const isEnrolled = isEnrolledSingle || isEnrolledToCombo;
  return (
    !isHide ?<div className="col-md-3 col-xs-12 mr-1 pt-2 pb-2 text-white" key={index}>
      <div className='border bg-dark p-2 rounded shadow'>
        <div className='pb-1'>
          <div className='discount-parent'>
            {courseType !== "Free" && <p className='discount-style'>{discount}% Discount</p>}
          </div>
          <div className='discount-parent'>
            {isNewCourse ? (courseType !== "Free" ? <img src="https://ifsca.gov.in/Images/new_List.gif" className="new-image" alt="new img" /> : <p className='discount-style-2'>Free</p>) : null}
          </div>
        </div>
        <div className="flip-box">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              {courseType !== "Free" ?
                <Link to={"/" + courselink}><img src={image1} className="course-image w-100" alt="course img" /></Link> :
                <a href={demolink} target="_blank"
                  rel="noreferrer"><img src={image1} className="course-image w-100" alt="course img" /></a>}
            </div>
            <div className="flip-box-back">
              {courseType !== "Free" ? <Link to={"/" + courselink}><img src={image2} className="course-image w-100" alt="course img" /></Link> :
                <a href={courselink} target="_blank"
                  rel="noreferrer"><img src={image2} className="course-image w-100" alt="course img" /></a>}
            </div>
          </div>
        </div>
        {courseType !== "Free" && <div className="d-flex flex-wrap flex-row align-items-center justify-content-between pb-2 pt-2">
          <a
            href={demolink}
            target="_blank"
            rel="noreferrer"
            className="text-center btn btn-outline-warning btn-sm">
            <i className="fa fa-youtube-play text-danger" /> DEMO
          </a>
          <button className='btn btn-outline-warning btn-sm' onClick={() =>
              history.push("/purchase/" + selectedCourse.courselink)
          }>
            <i className="fa fa-rupee" />
            {/*{parseInt(price).toLocaleString('en-IN')}/-*/}
            {parseInt(price - ((price / 100) * discount)).toLocaleString('en-IN')}/-
          </button>
          <Link to={"/" + courselink}>
          <div  className="btn btn-outline-warning btn-sm">
            <i className="fa fa-book" /> Course Details
          </div>
          </Link>
        </div>}


        {courseType !== "Free" &&
          <button className={`text-center btn btn-primary ${isLoggedIn && isEnrolled?'custom-bg3':'custom-bg'} border-0 text-white btn-sm w-100 mb-2`} onClick={() => {
            if(isLoggedIn && isEnrolled){
             openPlayer(isEnrolledToCombo?{_id:selectedCourse.courseCombo[0]}:selectedCourse, history)
            } else{
              history.push("/purchase/" + selectedCourse.courselink);
            }
          }
          }>
            {isLoggedIn && isEnrolled ? <><i className="fa fa-play-circle" /> LEARN NOW</> :
            <>
            <i className={`fa fa-${["react-2024","frontend-2024","react-summer-2024"].includes(courselink)?"book":"shopping-cart"}`} /> {["react-2024","frontend-2024","react-summer-2024"].includes(courselink)?"ENROLL NOW":"BUY NOW"}
            </>
        }
            </button>}
        {courseType !== "Free" ? null
          //(courses.length > 0 && courses.filter((item) => item.title === title && item.courseCombo.length === 0).length > 0 ? <Link to="/cartitems" className="text-center btn btn-success btn-sm w-100 mb-2"> Go To Cart <i className="fa fa-angle-double-right" /></Link> :

          //<Link to={"/purchase/" + selectedCourse.name} className="text-center btn btn-success btn-sm w-100 mb-2"> <i className="fa fa-shopping-cart" />  Buy Now </Link>
          ////(selectedCourse.courseCombo.length > 0 ? <Link to={"/purchase/" + selectedCourse.name} className="text-center btn btn-warning w-100 mb-2"> Buy Now <i className="fa fa-angle-double-right" /></Link> : <button className="text-center btn btn-primary w-100 mb-2" onClick={() => addToCart(selectedCourse)}><i className="fa fa-cart-plus" /> Add To Cart</button>)

          //)
          :
          <a href={courselink} target="_blank"
            rel="noreferrer">
            <button className="text-center btn btn-dark btn-sm w-100 mb-5 mt-5 p-3"><i className="fa fa-youtube-play text-danger" /> Learn In YouTube <i className="fa fa-external-link" /></button>
          </a>}
      </div>
    </div>:null
  )
}
