import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import useCourses, { filterCourseByCategory, openPlayer } from '../useCourses'
import CourseLoader from './CourseLoader'
import CourseCategories from './CourseCategories'
import CourseWish from './CourseWish'
import "./style.css";
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearLectureDetails } from '../../../redux/reducers/courseSlice';
import Recommendations from './Recommendations';
import {isNewUser} from '../VMVideoPlayer/VideosList';

export default function Courses() {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const history = useHistory();
  const { courses, remainingCourses, loading } = useCourses();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearLectureDetails());
  }, [])
  return (
    <div className='container-fluid mt-2'>
      <div className='bg-dark text-white rounded p-2 mb-2'>
        {loading ? <CourseLoader height={50} length={1} /> : <CourseWish />}
      </div>
      <div className='bg-dark text-white rounded p-2 mt-2 mb-2'>
        {loading ? <CourseLoader height={80} /> : <CourseCategories selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />}
        <hr />
        <div className='d-flex flex-wrap'>
          {loading ? <CourseLoader height={175} /> : (courses.length > 0 && filterCourseByCategory(courses, selectedCategory).map((course, index) => (
            <div className={`col-md-2 mt-2 mb-2 text-center course-item`} onClick={() => openPlayer(course, history)} key={index}>
              <div className={`card shadow m-2`}>
                <img src={course.courseLogo} alt="thumbnail" className='img-thumbnail border-0' />
                <p className={`bg-${course.isNotEnrolled ? 'warning' : 'primary'} p-2 font-weight-bold m-1 rounded`}>{course.isNotEnrolled ? "Enroll" : "Learn"}</p>
              </div>
            </div>
          )))}
        </div>
      </div>
      {remainingCourses.length > 0 && !isNewUser &&
        <Recommendations
          remainingCourses={remainingCourses}
          loading={loading}
          selectedCategory={selectedCategory}
          openPlayer={openPlayer}
          history={history}
        />}
    </div>
  )
}
