import React, { useEffect } from 'react'
import { Link } from'react-router-dom'
import './VMCelebrations.css'

export default function VMCelebrations() {
  useEffect(()=>{
    const audio = new Audio('/audio/firework-1-29803.mp3');
      setTimeout(() => {
    audio.play();
      }, 1000);
  },[])
  return (
    <div className='diwali-celebration'>
      <h1 className='heading-diwali-celebration popup-animation'> 🥳<span className='heading-diwali'>Happy Diwali Everyone</span>🤗</h1>
      <p>We wish you all a very Happy Diwali and a prosperous New Year. May the Goddess bless you all in your endeavors and make you prosperous in every way.</p>
      <img src="/images/vm-character.png" alt="vm-character" className='vm-character-right popup-animation' />
      <img src="/images/vm-character.png" alt="vm-character" className='vm-character-left' />
      <Link to="/allcourses"><button className='continue-btn'>👉Continue To VM Online Courses👈</button></Link>
    </div>
  )
}
