import React from 'react';

export default function QuizFetchError({ error }) {
  return (
    <div className="d-flex justify-content-center container">
      <div className="border rounded p-4 text-center col-md-6">
        <h1 className="text-danger">Error!</h1>
        <p className="mt-3 mb-0">{error}</p>
        <p className="cursor-pointer" onClick={() => window.location.reload()}>Please try after sometime. <i className="fa fa-refresh" title="Refresh" /></p>
      </div>
    </div>
  )
}
