import React, { lazy } from "react";
import SideMenu from './SideMenu';
import { Switch } from "react-router-dom";
import PublicRoute from "../../common/PublicRoute";
const MyCourses = lazy(() => import("./MyCourses"));
const Learning = lazy(() => import("./Learning"));
const Tasks = lazy(() => import("./Tasks"));
const ViewTaskPage = lazy(() => import("./ViewTaskPage"));

export default function LearningRoutes() {
  return (
    <div className="container-fluid">
      <div className="row">
        <SideMenu />
        <div className="col-sm p-3 min-vh-100">
          <Switch>
            <PublicRoute
              path="/learning"
              component={Learning}
              exact
            />
            <PublicRoute
              path="/learning/leaderboards"
              component={MyCourses}
              exact
            />
            <PublicRoute
              path="/learning/mycourses"
              component={MyCourses}
              exact
            />
            <PublicRoute
              path="/learning/tasks/:id"
              component={ViewTaskPage}
              exact
            />
            <PublicRoute
              path="/learning/tasks"
              component={Tasks}
              exact
            />
            <PublicRoute
              path="/learning/competitions"
              component={MyCourses}
              exact
            />
            <PublicRoute
              path="/learning/community"
              component={MyCourses}
              exact
            />
          </Switch>
        </div>
      </div>
    </div>
  )
}
