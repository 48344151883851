import React from "react";
import { Switch, Route } from "react-router-dom";

export default function PublicRoute(props) {
	return (
		<div>
			<Switch>
				<Route {...props} />
			</Switch>
		</div>
	);
}
