import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";

export default function PrivateRoute(props) {
  const token = localStorage.getItem("token");
  return (
    <div>
      <Switch>
        {token !== null && <Route {...props} />}
        {token === null && <Redirect to="/login" />}
      </Switch>
    </div>
  );
}
