import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { api } from "../utilities/api";
import { baseURL } from "../utilities/baseurl";
import packageJson from "../../package.json";
import { getBuildDate } from "../utilities/validations";
import MoreInfo from "./MoreInfo";

export default function Footer() {
  const [views, setViews] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    api(baseURL + "/getViews", "GET", {}, {})
      .then((data) => {
        setViews(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);
  return (
    <>
    <MoreInfo/>
    <nav className="navbar-dark bg-dark custom-bg2" style={{ borderTop: "10px solid lightgray" }}>
      <div className="mt-4 container col-xs-12 text-center d-flex flex-wrap justify-content-lg-between justify-content-center">
        <a href="/" className="navbar-brand text-warning col-md-3 col-xs-12">
          <h2 style={{ color: "white" }}>
            <b style={{ color: "orange" }}>VM</b> Online Training
          </h2>
          <p className="text-white text-wrap">
            We provide online course training for full stack technologies such as React,Node,Angular etc.,
          </p>
          <img src="/images/logo.png" className="text-center rounded" alt="course img" />
        </a>
        <div className="">
          <h2 className="text-warning">Available Courses</h2>
          <Link to="/reactredux" className="nav-link">
            <p className="nav-item text-white navbar-brand text-wrap">React and Redux Complete Course</p>
          </Link>
          <Link to="/angular" className="nav-link">
            <p className="nav-item text-white navbar-brand text-wrap">Angular Complete Course</p>
          </Link>
          <Link to="/reactnative" className="nav-link">
            <p className="nav-item text-white navbar-brand text-wrap">React Native Complete Course</p>
          </Link>
          <Link to="/nodejs" className="nav-link">
            <p className="nav-item text-white navbar-brand text-wrap">NodeJS Complete Course</p>
          </Link>
          <Link to="/webdeveloper" className="nav-link">
            <p className="nav-item text-white navbar-brand text-wrap">Web Development Complete Course</p>
          </Link>
          <Link to="/mernstack" className="nav-link">
            <p className="nav-item text-white navbar-brand text-wrap">MERN Stack Complete Course</p>
          </Link>
          <div className="d-flex justify-content-center flex-wrap">
            <Link to="/html5">
              <img src="/images/html.png" alt="html img" style={{ width: 120 }} />
            </Link>
            <Link to="/css3">
              <img src="/images/css3.png" alt="css img" style={{ width: 120 }} />
            </Link>
            <Link to="/javascript">
              <img src="/images/jscourse.png" alt="js img" style={{ width: 120 }} />
            </Link>
            <Link to="/reactredux">
              <img src="/logo512.png" alt="react img" style={{ width: 120 }} />
            </Link>
            <Link to="/nodejs">
              <img src="/images/nodejs-course.png" alt="node img" style={{ width: 120 }} />
            </Link>
            <Link to="/angular">
              <img src="/images/angularcourse.png" alt="node img" style={{ width: 120 }} />
            </Link>
          </div>
        </div>
        <div className="">
          <h2 className="text-warning">Quick Links</h2>

          <a href="https://play.google.com/store/apps/details?id=com.vmtrainingapp" target="_blank" className="nav-link">
            <li className="nav nav-link btn btn-outline-success w-100 text-white">
              <i className="fa fa-android" /> Get Android App
            </li>
          </a>
          {/* <Link to="/verify" className="nav-link">
            <li className="nav nav-link btn btn-outline-primary w-100 text-white">
              <i className="fa fa-certificate" /> Verify Certificate
            </li>
          </Link> */}

          <a
            href="https://www.youtube.com/c/VenkateshMogili"
            target="_blank"
            className="nav-link"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}>
            <li className="nav nav-link btn btn-outline-danger text-white">
              <i className="fa fa-youtube" /> YouTube
            </li>
          </a>
          {/* <Link to="/about-team" className="nav-link">
            <li className="nav nav-link btn btn-outline-info text-white">
              <i className="fa fa-info-circle" /> About Team
            </li>
          </Link> */}
          <Link to="/whatsapp" className="nav-link">
            <li className="nav nav-link btn btn-outline-success text-white">
              <i className="fa fa-whatsapp" /> Whatsapp
            </li>
          </Link>
          <Link to="/about" className="nav-link">
            <li className="nav nav-link btn btn-outline-info text-white">
              <i className="fa fa-users" /> About Us
            </li>
          </Link>
          <Link to="/terms-and-conditions" className="nav-link">
            <li className="nav nav-link btn btn-outline-info text-white">
              <i className="fa fa-info-circle" /> Terms & Conditions
            </li>
          </Link>
          <Link to="/refund-policy" className="nav-link">
            <li className="nav nav-link btn btn-outline-info text-white">
              <i className="fa fa-info-circle" /> Refund Policy
            </li>
          </Link>
          <Link to="/contact" className="nav-link">
            <li className="nav nav-link btn btn-outline-info text-white">
              <i className="fa fa-whatsapp" /> Contact Us
            </li>
          </Link>
          <Link to="/privacy-policy" className="nav-link">
            <li className="nav nav-link btn btn-outline-info text-white">
              <i className="fa fa-shield" /> Privacy Policy
            </li>
          </Link>
        </div>
      </div>
      <div className="bg-secondary custom-bg2 mt-4 text-center text-white col-xs-12 text-center d-flex flex-wrap justify-content-lg-center justify-content-center align-items-center">
        <p className="m-3"> &copy; 2021-2024 VM Online Training. All Rights Reserved.</p>
        <p className="m-3">
          {"  "}
          Powered by{" "}
          <a href="https://netlify.com" rel="noreferrer" className="link text-white" target="_blank">
            Netlify.com
          </a>
          {"        "}
        </p>
        <p className="m-3">Version 4.0.8 (Last Updated On: {getBuildDate(packageJson.buildDate)})</p>
        <p className="m-3">Views: {loading ? <i className="fa fa-refresh" /> : views}</p>
      </div>
    </nav>
    </>
  );
}
