import React from "react";
import InstallApp from "../components/InstallApp";

export default function About() {
  return (
    <div className="container-fluid custom-bg2">
      <InstallApp />
      <h1 className="mb-4 pt-4 text-white text-center">About Us</h1>
      <p className="text-white p-5 text-left">
        <b className="text-white text-decoration-underline">About Company:</b>
        <br />
        <b>VM Online Training</b> is an online tech education platform. We provide online courses, online training for full stack technologies such as React, Node, Angular, HTML, CSS, JAVASCRIPT etc., You can learn our course in both web and mobile applications. You can "Enroll once, Learn Anywhere!". You can get clear any of your doubts instantly through our official WhatsApp group where we have 150+ experienced developers to help you to continue learning without stops, You will receive the group link through mail once you enrolled to any course. If you still have any queries, you can contact us through <a
          className="text-decoration-none text-success"
          href="https://api.whatsapp.com/send?phone=+918328664336&amp;text=Hello Venkatesh! I'm interested to enroll/purchase your courses. I have few queries."
          target="_blank">
          <i className="fa fa-whatsapp text-success" /> WhatsApp
        </a>.<br /><br />
        <b className="text-white text-decoration-underline">Terms and Conditions:</b>
        <br />
        We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this <a href="/privacy-policy" className="text-decoration-none">Privacy Policy</a>, the terms used in this <a href="/privacy-policy" className="text-decoration-none">Privacy Policy</a> have the same meanings as in our Terms and Conditions.<br /><br />
        <b className="text-white text-decoration-underline">Cancellation and Refund Policy:</b>
        <br />
        There is no cancellation/refund policy available once you enrolled to any course. Only discounts will be available before you purchase any of our courses, no cancellation available. </p>
      <div className="text-center">
        <div className="row justify-content-center align-items-center m-0 p-0">
          <h1 className="mb-4 pt-4 text-white">Core Team</h1>
          <div className="col-md-3 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
            <img src="/images/myprofile.jpg" className="w-50 tech-team" style={{ borderRadius: 100 }} alt="profile img" />
            <h2>Venkatesh Mogili</h2>
            <p>Full Stack Developer, Founder</p>
            <a className="nav-link" href="https://github.com/VenkateshMogili" target="_blank" rel="noreferrer">
              <i className="fa fa-github" /> Github Link
            </a>
          </div>
          <div className="col-md-3 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
            <img src="/images/ramanirayudu-cofounder.png" className="w-50 tech-team" style={{ borderRadius: 100 }} alt="profile img" />
            <h2>Ramani Rayudu</h2>
            <p>Full Stack Developer, Co-Founder</p>
            <a className="nav-link" href="https://github.com/RamaniRayudu" target="_blank" rel="noreferrer">
              <i className="fa fa-github" /> Github Link
            </a>
          </div>
          <div className="col-md-3 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
            <img src="/images/lakshmi.png" className="w-50 tech-team" style={{ borderRadius: 100 }} alt="profile img" />
            <h2>Lakshmi Rayudu</h2>
            <p>Web Developer</p>
            <a className="nav-link disabled" href="https://github.com/RamaniRayudu" target="_blank" rel="noreferrer">
              <i className="fa fa-github" /> Github Link
            </a>
          </div>
        </div>
        <h1 className="mb-4 text-white">Tech Team</h1>
        <div className="row justify-content-center align-items-center m-0 p-0">
          <div className="col-md-3 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
            <img src="/images/developer-men.png" className="w-50 tech-team" style={{ borderRadius: 100 }} alt="profile img" />
            <h2>Sainath</h2>
            <p>Full Stack Developer</p>
            <a className="nav-link" href="https://github.com/sainth-stack" target="_blank" rel="noreferrer">
              <i className="fa fa-github" /> Github Link
            </a>
          </div>
          <div className="col-md-3 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
            <img src="/images/developer-men.png" className="w-50 tech-team" style={{ borderRadius: 100 }} alt="profile img" />
            <h2>Vamshi</h2>
            <p>Frontend Developer</p>
            <a className="nav-link" href="https://github.com/vamshi859" target="_blank" rel="noreferrer">
              <i className="fa fa-github" /> Github Link
            </a>
          </div>
          <div className="col-md-3 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
            <img src="/images/developer-men.png" className="w-50 tech-team" style={{ borderRadius: 100 }} alt="profile img" />
            <h2>Eswar</h2>
            <p>Frontend Developer</p>
            <a className="nav-link" href="https://github.com/eswar-dev" target="_blank" rel="noreferrer">
              <i className="fa fa-github" /> Github Link
            </a>
          </div>
          <div className="col-md-3 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
            <img src="/images/developer-men.png" className="w-50 tech-team" style={{ borderRadius: 100 }} alt="profile img" />
            <h2>Chanakya</h2>
            <p>Full Stack Developer</p>
            <a className="nav-link" href="https://github.com/chanakya213" target="_blank" rel="noreferrer">
              <i className="fa fa-github" /> Github Link
            </a>
          </div>

          <div className="col-md-3 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
            <img src="/images/lakshmi.png" className="w-50 tech-team" style={{ borderRadius: 100 }} alt="profile img" />
            <h2>Rajyalakshmi S</h2>
            <p>Frontend Developer</p>
            <a className="nav-link" href="https://github.com/Raji1243" target="_blank" rel="noreferrer">
              <i className="fa fa-github" /> Github Link
            </a>
          </div>
          {/*<div className="col-md-3 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
            <img src="/images/lakshmi.png" className="w-50 tech-team" style={{ borderRadius: 100 }} alt="profile img" />
            <h2>Sobha Rani</h2>
            <p>Frontend Developer</p>
            <a className="nav-link" href="https://github.com/ranisobha" target="_blank" rel="noreferrer">
              <i className="fa fa-github" /> Github Link
            </a>
          </div>*/}
          <div className="col-md-3 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
            <img src="/images/developer-men.png" className="w-50 tech-team" style={{ borderRadius: 100 }} alt="profile img" />
            <h2>Sai Manikanta B</h2>
            <p>Full Stack Developer</p>
            <a className="nav-link" href="https://github.com/Sai-Manikanta" target="_blank" rel="noreferrer">
              <i className="fa fa-github" /> Github Link
            </a>
          </div>
        </div>
      </div>

    </div>
  );
}
