import React, { useState } from 'react';
import { useSpeechSynthesis } from "react-speech-kit";
import femaleImg from '../../assets/female.png';
import maleImg from '../../assets/male.png';

export default function ReviewsRatings() {
  const { speak,speaking,cancel,voices } = useSpeechSynthesis();
  const reviews = [
    {
      name: 'K.Rohini',
      gender: 'Female',
      rating: 5,
      review: "This Training is not worth of 5,000 but it's worth of more than 20000, what a fantastic training, never before ever after. Teaching: explanation of each topic is top-notch , Most liked part in teaching first you teaches from basic slowly using that basic concepts and improvising that basic code to advance level is very creative and easy to understand for beginners like me, explaining doubts and live coding with you gives more confidence, i have never seen a trainer who concentrate on minimal things like naming convention, which is very important in real time, you explained everything so well and these mock interviews are goldmine, i can't even say how they shape me out, and Leaderboard concept, this is one of the greatest motivation and can easily know where we are at in competition, next code review with this feature this training became the next level, where any other coaching can't provide this feature, your hard work, your determination your teachings are truly inspirable, what an amazing talent and most importantly such a great human being you are !!! Hatsoff to you. I just want to see your training institute to sky-high at top place and help many students to improve their skills. Thank You for providing both YouTube free videos and premium live training courses like react. You are just awesome teacher, who really cares about Students. Thank You 100000000 times.",
      profile: 'https://github.com/rohini0518',
    },
    {
      name: 'B.Kumar',
      gender: 'Male',
      rating: 5,
      review: "The training material was thorough and well-structured, covering both theoretical and practical aspects and mainly our passion. I'm impressed bro. God bless you!",
      profile: '',
    },
    {
      name: 'V.Sunitha',
      gender: 'Female',
      rating: 5,
      review: 'It is useful for those who wants to become real React programmer....',
      profile: '',
    },
    {
      name: 'Kranthi Kumar',
      gender: 'Male',
      rating: 5,
      review: 'Nice and I gained more knowledge about reusability for real-time applications.',
      profile: '',
    },
    {
      name: 'N.Balakrishna',
      gender: 'Male',
      rating: 5,
      review: 'Overall training is very good and practical. I have learned a lot of new things, Now we have an idea on React.',
      profile: '',
    },
    {
      name: 'Y.Nagarjuna Reddy',
      gender: 'Male',
      rating: 5,
      review: "I am really satisfied with your training. Whatever you are applying in Class, Q&A Sessions, Mock Interviews and Quizes are very good.",
      profile: 'https://github.com/nagarjunayadika',
    },
    {
      name: 'D.Jagadeesh',
      gender: 'Male',
      rating: 5,
      review: 'Overall very good teaching & explanation.',
      profile: '',
    },
  ];
  return (
    <div className="mt-4 mb-4 bg-light border p-2 rounded">
      <h3 className="text-warning"><i className="fa fa-star" /> Reviews and Ratings (5/5):</h3>
      <div className="row justify-content-center align-items-center">
        <div>
          {reviews.map((review, index) => (
            <Review key={index} review={review} speak={speak} speaking={speaking} cancel={cancel} voices={voices} />
          ))}
        </div>
      </div>
    </div>
  )
}

const Review = ({ review, speak,speaking, cancel, voices }) => {
  const [active, setActive] = useState(false);
  const maleVoices = [0,1,3];
  const femaleVoices = [2,4];
  const handlePlaySound = () => {
    setActive(!active);
    if (speaking) {
      cancel();
    } else {
      const randomMale=Math.floor(Math.random()*maleVoices.length);
      const randomFemale=Math.floor(Math.random()*femaleVoices.length);
      const selectedIndex = review.gender==="Male"?maleVoices[randomMale]:femaleVoices[randomFemale];
      const voice = voices[selectedIndex];
      speak({ text: review.review,voice });
    }
  }
  return (
    <div className="card mb-2 review-question">
      <div className="card-body">
        <h5 className="card-title d-flex justify-content-between align-items-center">
          <div className='d-flex'>
            <img src={review.gender === "Male" ? maleImg : femaleImg} alt="gender icon" className="m-2 img-circle" />
            <div className='m-2'>
              {review.profile ?
                <a href={review.profile} target='_blank' className='text-decoration-none' rel='noopener noreferrer'>{review.name}</a>
                :
                <span>{review.name}</span>
              }
              <div className="rating">
                <i className="fa fa-star text-warning" />
                <i className="fa fa-star text-warning" />
                <i className="fa fa-star text-warning" />
                <i className="fa fa-star text-warning" />
                {review.rating === 5 ? <i className="fa fa-star text-warning" /> : <i className="fa fa-star" />}
              </div>
            </div>
          </div>
          <button className="btn btn-sm btn-outline-warning ml-auto" onClick={handlePlaySound}>
            <i className='fa fa-volume-up' /> {speaking?'Reading':'Read'}
            </button>
        </h5>
        <div className="row">
          <div className="col-md-12">
            <p className="card-text m-2">{review.review}</p>
          </div>
        </div>
      </div>
    </div>
  )
}