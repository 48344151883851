import { useState, useEffect } from 'react';

const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(false);
  const [networkMessage, setNetworkMessage] = useState("");
  let offline = <p className="text-white m-0" onClick={() => window.location.reload()}>You're offline. Please check your connection. <i className="fa fa-refresh cursor-pointer" /></p>;
  let online = <p className="bg-success text-white m-0"><i className="fa fa-check-circle text-white" /> You're connection established! </p>;

  useEffect(() => {
    window.addEventListener('online', (event) => {
      setIsOnline(true);
      setNetworkMessage(online);
      setTimeout(() => {
        setNetworkMessage(null);
      }, 2000);
    });
    window.ononline = (event) => {
      setIsOnline(true);
      setNetworkMessage(online);
      setTimeout(() => {
        setNetworkMessage(null);
      }, 2000);
    };
    window.addEventListener('offline', (event) => {
      setIsOnline(false);
      setNetworkMessage(offline);
    });
    window.onoffline = (event) => {
      setIsOnline(false);
      setNetworkMessage(offline);
    }
  }, []);

  return { isOnline, networkMessage };
};

export default useNetworkStatus;