import React from 'react'

export default function LecturesList({expandList,links,filterLinks,isEnrolled,courseLogo,searchText,id,playVideo,maxLimit}) {
  return (
    <>
      {expandList && links.length > 0 && filterLinks(links).length > 0 && filterLinks(links).slice(0,maxLimit).map((video, index) => (
                <div key={index} className={`d-flex align-items-start video-item ${!isEnrolled && index > 0 ? 'locked-lecture' : ''} ${id == video.url.split("https://youtu.be/")[1] ? 'selected-video' : ''}`} onClick={() => playVideo(video)}>
                  <img
                    src={!isEnrolled && index > 0 ? '/images/lock-icon.png' : (video.thumbnail ? video.thumbnail : courseLogo)}
                    alt="thumbnail"
                    className={`img-thumbnail course-thumbnail ${!isEnrolled && index > 0 ? 'locked-lecture' : ''}`}
                  />
                  <div className='pl-2'>
                    <span>{index + 1}. {video.title}</span>
                    {searchText.length > 0 && <p>{video.description}</p>}
                    {/*<p className='pl-2'>{(video.duration > 3600 ? moment.utc(video.duration * 1000).format('h:m:s').split(":")[0] + "h " : "") + (video.duration > 60 ? moment.utc(video.duration * 1000).format('h:m:s').split(":")[1] + "m " : "") + moment.utc(video.duration * 1000).format('h:m:s').split(":")[2] + "s"}</p>*/}
                    <p>{video.duration}</p>
                  </div>
                </div>
              ))}
    </>
  )
}
