import React, { useState, useEffect } from 'react'
import { api, baseURL } from '../../../utilities';
import TestResult from './TestResult';
import Quiz from './Quiz';
import QuizFetchError from './QuizFetchError';
import { removeFromStorage } from '../../../utilities/localStorage';
import { startedTime } from '../../../redux/constants/courseConstants';

export default function Test({ questions, start, loading, remainingTime, quizName, quizType }) {
  const [answers, setAnswers] = useState({});
  const [current, setCurrent] = useState(false);
  const [count, setCount] = useState(0);
  const [result, setResult] = useState({});
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    setCurrent(true);
    const { value, name } = e.target;
    const ans = { ...answers };
    ans[name] = value;
    setAnswers(ans);
  }

  const submitTest = () => {
    removeFromStorage(startedTime);
    let testEmail = localStorage.getItem("testEmail") != null ? localStorage.getItem("testEmail") : ""
    api(baseURL + '/getResults', 'POST', { "content-type": "application/json" }, {
      questionsCategory: {
        quizName,
        quizType
      },
      answers,
      testEmail
    })
      .then(response => {
        if (response.success) {
          setResult(response.data);
          api(baseURL + '/createTest', 'POST', { "content-type": "application/json" }, {
            quizName,
            quizType,
            answers,
            testEmail,
            score: response.data.score,
            quizCategoryId: response.data.quizCategoryId
          })
            .then(response2 => {
              if (response2.success) {
                setResult(response.data);
              } else {
                setError("Something went wrong!");
              }
            })
            .catch(error => console.log(error))
        } else {
          setError("Something went wrong!");
        }
      })
      .catch(error => console.log(error))
  }
  //submit the test automatically if the time is over.
  useEffect(() => {
    if (remainingTime === "EXPIRED") {
      submitTest();
    }
  }, [remainingTime])

  return (
    <div className="d-flex justify-content-center container">
      <div>
        {error ? <QuizFetchError error={error} /> : ""}
      </div>
      {start && !error && <div className={`bg-white h250 col-md-8 border p-lg-5 m-lg-3 rounded`}>

        {remainingTime !== "EXPIRED" && localStorage.getItem("startedTime") !== null && (
          <p
            style={{ fontSize: 25 }}
            className="text-center rounded custom-bg text-white align-items-center d-flex justify-content-center flex-wrap">
            TEST ENDS IN &nbsp;
            <b style={{ fontSize: 25, color: "black" }} className="text-warning">
              {remainingTime}
            </b>
          </p>
        )}
        {(remainingTime === "EXPIRED" || localStorage.getItem("startedTime") === null) && (
          <p
            style={{ fontSize: 25 }}
            className="text-center rounded p-2 bg-secondary text-white align-items-center d-flex justify-content-center flex-wrap">
            TEST SUBMITTED SUCCESSFULLY!
          </p>
        )}
        <div className="align-items-start">
          <span className="p-2">
            {remainingTime !== "EXPIRED" && !result.hasOwnProperty('score') && questions ? count + 1 + "/" + questions.length : ""}
          </span>
          {remainingTime !== "EXPIRED" && !result.hasOwnProperty('score') ? (
            <div>
              {questions.length > 0 && questions.map(({ options }, index) => (
                <Quiz key={index} index={index} questions={questions} options={options} count={count} current={current} setCurrent={setCurrent} submitTest={submitTest} handleChange={handleChange} setCount={setCount} />
              ))}
            </div>
          ) : <TestResult score={result.score} questions={questions} quizName={quizName} />}
        </div>
      </div>}
    </div>
  )
}
