import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export default function LearningOptions({ playerMode, turnOnPlayerMode, disabled = false }) {
  return (
    <div className='d-flex bg-primary enrollOption'>
      {/*<div className='d-flex align-items-center'>
          <p className='m-0 p-2'>AUTOPLAY</p>
          <i className='fa fa-toggle-on m-2 p-1 text-white' />
        </div>*/}
      <div className='d-flex align-items-center cursor-pointer' onClick={() => {
        //if (!disabled) {
        turnOnPlayerMode()
        //} else {
        //  window.alert("Please enroll to access the full content!");
        //  history.push("/purchase/" + coursename);
        //}
      }
      }>
        <p className='m-0 p-2 d-none d-lg-flex'>{playerMode === "learning" ? "Practice Mode" : "Learning Mode"}</p>
        <i className={`fa fa-toggle-${playerMode === "learning" ? "off" : "on"} m-2 p-1 text-white d-none d-lg-flex`} />
      </div>
      <AnchorLink href='#resources' className='d-flex align-items-center cursor-pointer text-white text-decoration-none'>
        <p className='m-0 p-2'>Resources</p>
        <i className={`fa fa-book m-2 p-1 text-white`} />
      </AnchorLink>
      <AnchorLink href='#comments' className='d-flex align-items-center cursor-pointer text-white text-decoration-none'>
        <p className='m-0 p-2'>Comments</p>
        <i className={`fa fa-comments m-2 p-1 text-white`} />
      </AnchorLink>
    </div>
  )
}
